const GENERIC_OBJECT_INJECTION_SINK_BLOCKLIST = [
  'constructor',
  '__proto__',
  '__defineGetter__',
  '__defineGetter__()',
  '__defineSetter__',
  '__defineSetter__()',
  '__lookupGetter__',
  '__lookupGetter__()',
  '__lookupSetter__',
  '__lookupSetter__()',
];

const isUserInputBlocklisted = (key) => GENERIC_OBJECT_INJECTION_SINK_BLOCKLIST.indexOf(key) > -1;

export const getSafeValue = (object, key) => {
  if (isUserInputBlocklisted(key)) return null;
  return Number.isSafeInteger(key) ? object[Number(key)] : object[`${key}`];
};

export const setSafeValue = (object, key, value) => {
  if (isUserInputBlocklisted(key)) return;
  if (Number.isSafeInteger(key)) object[Number(key)] = value;
  else object[`${key}`] = value;
};
