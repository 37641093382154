import { includes } from 'lodash';
import moment from 'moment';
import { getTourEnd, getTourStart } from '../SolutionHelpers';

export const getGeoInfoFromObjects = (objList) =>
  objList && {
    type: 'FeatureCollection',
    features: Object.keys(objList)
      .map((key) => objList[key].geo.map((feature) => feature))
      .flat(),
  };

export const getOriginalLines = (objList) =>
  objList && {
    type: 'FeatureCollection',
    features: Object.values(objList).flatMap((value) => value.originalLocation),
  };

export const onEachFeature = (feature, layer, mapData, display, tourData, onTourLineClick) => {
  if (feature.geometry.type === 'LineString') {
    const tour = mapData.geo.features.find(
      (f) =>
        f.geometry.type === 'LineString' &&
        (display.selectedTour !== undefined
          ? display.selectedTour === f.properties.routeId
          : includes(display.routeIds, f.properties.routeId)),
    );
    if (tour) {
      layer.bindPopup(`
          <table>
           <tr><td>Tour</td><td>${tour.properties.routeId + 1}</td></tr>
           <tr><td>Start</td><td>${moment(getTourStart(tour.properties.vehicle.id, tourData.tours))
             .utc()
             .format('LT')}</td></tr>
           <tr><td>End</td><td>${moment(getTourEnd(tour.properties.vehicle.id, tourData.tours))
             .utc()
             .format('LT')}</td></tr>
          </table>
        `);
      layer.on({ click: () => onTourLineClick(feature) });
    }
  }
};
