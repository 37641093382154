import config from 'config';
import { head, isObject, last, split, isEmpty, isString } from 'lodash';
import { getUsePrdEnv } from './urlHelpers';

const {
  asset: { isExternal, url: assetUrl },
  environment,
} = config;

export const times = (length, fn) => Array.from({ length }, (_, i) => fn(i));

export const sprintf = (template, params) => {
  if (!isObject(params) || !template) {
    return template;
  }
  let output = template;
  Object.keys(params).forEach((p) => {
    output = output.replace(new RegExp(`%\\(${p}\\)`, 'g'), params[p]);
  });
  return output;
};

export function findAverage(arr) {
  if (!arr || arr.length === 0) return NaN;
  const sum = arr.reduce((a, b) => a + b);
  return sum / arr.length;
}

export const cutString = (str) => {
  return str ? str.replace(/(.{25})/g, '$1\n') : '';
};

export const removeAllWhitespaces = (str) => {
  return str && isString(str) ? str.replace(/\s/g, '') : str;
};

export function convertToDisplayTime(millis) {
  if (!millis || isString(millis)) return 0;
  const minutes = Math.floor(millis / 60000);
  const seconds = ((millis % 60000) / 1000).toFixed(0);
  return `${minutes} minutes and ${seconds < 10 ? '0' : ''}${seconds} seconds`;
}

export const convertToValidJson = (str) => {
  if (!str) return '';
  let convert = str;
  if (!convert.startsWith('{') || !convert.endsWith('}')) convert = `{${convert}}`;

  return JSON.parse(convert);
};

export const checkForValidTPJson = (json, isSolution, translations) => {
  if (!json) return translations.notValidJson;
  if (isEmpty(json)) return translations.emptyJson;
  if (!isSolution) {
    if (json.plan === undefined) return translations.noPlan;
    if (json.plan.jobs === undefined) return translations.noJobs;
    if (json.fleet === undefined) return translations.noFleet;
  } else {
    if (json.statistic === undefined && isSolution) return translations.noStatistic;
    if (json.tours === undefined && isSolution) return translations.noTours;
  }

  return null;
};

export const formatErrorPath = (path) => {
  return path ? `'/${path.join('/')}'` : '';
};

export const getAsset = (url, ext = 'svg') =>
  isExternal !== 'true' ? url : `${assetUrl}${head(split(last(split(url, '/')), '.'))}.${ext}`;

export const roundTo = (num, round = 2) => +`${Math.round(`${num}e+${round}`)}e-${round}`;

export const pxToRem = (value) => `${value / 16}rem`;

export const copyToClipboard = (str) => {
  const el = document.createElement('textarea'); // Create a <textarea> element
  el.value = str; // Set its value to the string that you want copied
  el.setAttribute('readonly', ''); // Make it readonly to be tamper-proof
  el.style.position = 'absolute';
  el.style.left = '-9999px'; // Move outside the screen to make it invisible
  document.body.appendChild(el); // Append the <textarea> element to the HTML document
  const selected =
    document.getSelection().rangeCount > 0 // Check if there is any content selected previously
      ? document.getSelection().getRangeAt(0) // Store selection if found
      : false; // Mark as false to know no selection existed before
  el.select(); // Select the <textarea> content
  document.execCommand('copy'); // Copy - only works as a result of a user action (e.g. click events)
  document.body.removeChild(el); // Remove the <textarea> element
  if (selected) {
    // If a selection existed before copying
    document.getSelection().removeAllRanges(); // Unselect everything on the HTML document
    document.getSelection().addRange(selected); // Restore the original selection
  }
};

export const isIPhone = () => navigator.userAgent.toLowerCase().indexOf('iphone') > -1;

export const isMobileDevice = () => isIPhone() || window.innerWidth < 851;

export const isSafari = () => {
  const isChrome = navigator.userAgent.toLowerCase().indexOf('crios/') > -1;
  const isFirefox = navigator.userAgent.toLowerCase().indexOf('fxios/') > -1;
  const isSafariUA = navigator.userAgent.toLowerCase().indexOf('safari/') > -1;
  return isSafariUA && !isChrome && !isFirefox;
};

export const canDownloadCSV = () => !isMobileDevice() || !isIPhone() || isSafari();

export const isSameLocation = (l1, l2) =>
  l1.label !== '' && l2.label !== '' && l1.label === l2.label;

export const sortAlpha = (items, property) => {
  return items.sort((a, b) => {
    const addA = a[property] ? a[property].toUpperCase() : '';
    const addB = b[property] ? b[property].toUpperCase() : '';
    if (addA < addB) return -1;
    if (addA > addB) return 1;
    return 0;
  });
};

export const isValidPhone = (phone) => {
  if (isEmpty(phone)) return false;
  return !phone.match(/[a-zA-Z]/);
};

export const isProdEnv = () => environment === 'prod' || getUsePrdEnv();

export const getAssignedJobs = ({ jobs, groups = [] }, fleet, unassignedJobs) => {
  const totalJobs = jobs.length + groups.reduce((total, group) => total + group.pudos.length, 0);
  return isEmpty(fleet) ? totalJobs : totalJobs - unassignedJobs;
};
