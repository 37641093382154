import moment from 'moment';
import config from 'config';
import { convertCostsFromTourPlanner } from './cost';

const {
  scenario: {
    vehicle,
    times: [{ value }],
  },
  shortTimeFormat,
} = config;

export function getVRPTimes(tourPlanner) {
  const defaultTimes = {
    start: moment(value.start, shortTimeFormat),
    end: moment(23, shortTimeFormat),
  };

  if (!tourPlanner) return defaultTimes;

  const end = tourPlanner.time.value.end !== 24 ? tourPlanner.time.value.end : '23:55';
  return {
    start: moment(tourPlanner.time.value.start, shortTimeFormat),
    end: moment(end, shortTimeFormat),
  };
}

export function getVRPVehicleDefinition(tourPlanner, isImperial) {
  const def = { ...vehicle };
  if (!tourPlanner) return def;
  def.costs = convertCostsFromTourPlanner({ ...tourPlanner.vehicles[0].costs, isImperial });

  def.id = tourPlanner.vehicles[0].id;
  def.profile = tourPlanner.vehicles[0].profile;
  def.amount = tourPlanner.vehicles[0].amount || tourPlanner.vehicles[0].vehicleIds.length;
  def.capacity = tourPlanner.vehicles[0].capacity;

  return def;
}
