import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { fontSize } from 'global/variables';
import withTranslation from 'hoc/withTranslation';
import { StyledIcon } from 'components/Wizard/Global/WizardStyled';

const { normal } = fontSize;

const StyledText = styled.strong`
  font-size: ${normal};
  font-weight: ${(props) => props.normal && '400'};
  line-height: 2rem;
  display: block;
  border-bottom: ${(props) => (props.borderBottom ? '0.09rem dotted black' : 'none')};
  margin-bottom: ${(props) => (props.borderBottom ? '0.65rem' : 'auto')};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  transition: opacity 500ms;
  z-index: 999;
`;

const StyledPopup = styled.div`
  margin: 4.37rem auto;
  padding: 1.25rem;
  background: #fff;
  border-radius: 0.31rem;
  width: 50%;
  position: relative;
  transition: all 5s ease-in-out;
  height: 18rem;
`;

const StyledContent = styled.div`
  max-height: 90%;
  overflow: auto;
`;

const StyledClose = styled.a`
  position: absolute;
  top: 0.2rem;
  right: 0.4rem;
  transition: all 200ms;
  font-size: 1.8rem;
  font-weight: bold;
  text-decoration: none;
  color: #333;
  cursor: pointer;
`;

const StyledHeading = styled.h2`
  border-bottom: ${(props) => (props.headingBorder ? '0.08rem dotted black' : 'none')};
  font-size: 20px;
`;

const Popup = ({
  h2,
  text,
  items,
  icon,
  additonalText,
  CustomContent,
  setShowPopup,
  headingBorder = false,
  translations: {
    tourSolution: { preposition },
  },
}) => {
  const onClose = useCallback(() => {
    setShowPopup(false);
  }, [setShowPopup]);

  return (
    <StyledOverlay onClick={onClose}>
      <StyledPopup onClick={(e) => e.stopPropagation()}>
        <StyledHeading headingBorder={headingBorder}>{h2}</StyledHeading>
        <StyledClose onClick={onClose}>&times;</StyledClose>
        <StyledContent>
          {CustomContent ? (
            <CustomContent />
          ) : (
            <>
              <StyledText borderBottom>
                {text} {additonalText && <StyledText padding>{vehicleTypeId}</StyledText>}
              </StyledText>
              {items.map((ul, idx) => (
                <StyledText key={idx} normal title={ul.name}>
                  <StyledIcon icon={icon(ul.fleetType)} sizeL popup />
                  {`${ul.count} / ${ul.amount} ${preposition} ${ul.name}`}
                </StyledText>
              ))}
            </>
          )}
        </StyledContent>
      </StyledPopup>
    </StyledOverlay>
  );
};

export default withTranslation(Popup);
