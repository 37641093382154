import moment from 'moment';
import 'moment/locale/en-au';
import 'moment/locale/de';
import 'moment/locale/es-us';
import 'moment/locale/fr';
import 'moment/locale/it';
import 'moment/locale/hi';
import 'moment/locale/pt';
import 'moment/locale/ru';
import 'moment/locale/nl';
import 'moment/locale/th';
import 'moment/locale/el';

export function setLocale(lang) {
  if (lang === 'hi') {
    moment.locale('en-us');
    return;
  }

  const locale = ['es', 'en'].includes(lang) ? `${lang}-us` : lang;
  moment.locale(locale);
}

export function getLocaleTimeFormat() {
  const str = moment().format('LT');
  return str.includes('AM') || str.includes('PM') ? 'hhmm' : 'HHmm';
}

export function formatTimeLocale(date) {
  return moment(date).format('LT');
}

export function formatTimeLabelLocale(hour) {
  const format = getLocaleTimeFormat();
  const ampm = hour < 12 || hour >= 24 ? 'AM' : 'PM';
  const label = hour > 12 ? hour - 12 : hour !== 0 ? hour : 12;
  return format === 'HHmm' ? `${hour}:00` : `${label} ${ampm}`;
}

export function getUtcDateFormatted(date, offset) {
  return moment(date).utc().utcOffset(offset).format('LT');
}

export function getUtcDateFormattedFull(date, offset) {
  return moment(date).utc().utcOffset(offset).format('LLL');
}

export function getDiffInDays(firstDate, secondDate, offset) {
  const firstDay = moment(firstDate).utc().utcOffset(offset);
  const secondDay = moment(secondDate).utc().utcOffset(offset);

  return moment(firstDay).startOf('day').diff(moment(secondDay).startOf('day'), 'days');
}
