import React, { useCallback } from 'react';

const Checkbox = ({ id, value, contrast, label, labelDisabled, onChange, disabled }) => {
  const cssClass = `label-custom-switcher big ${contrast ? 'inverted' : ''} ${
    disabled ? 'disabled-label' : 'clickable'
  }`;

  const handleSwitch = useCallback(
    (e) => {
      if (onChange) onChange(e.target.checked);
    },
    [onChange],
  );

  const handleContainerClick = useCallback(() => {
    if (onChange) onChange(!value);
  }, [onChange, value]);

  return (
    <div>
      <input id={id} type="checkbox" checked={value} onChange={handleSwitch} disabled={disabled} />{' '}
      <label className="custom-switcher" htmlFor={id}>
        T
      </label>
      <span className={cssClass} onClick={disabled ? null : handleContainerClick}>
        {value ? label : labelDisabled || label}
      </span>
    </div>
  );
};

export default Checkbox;
