import React, { useCallback, useState } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import currentLocation from '../../../global/img/target.svg';
import { getLocationAddress } from '../../../utils/GeoCoder';

const pendingAnimation = keyframes`
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0.1;
  }
`;

const StyledCurrentLocationButton = styled.button(({ pending }) => ({
  position: 'absolute',
  top: '0.35rem',
  right: '0.3rem',
  backgroundImage: `url(${currentLocation})`,
  backgroundSize: '1.5rem 1.5rem',
  backgroundPosition: 'center center',
  backgroundRepeat: 'no-repeat',
  backgroundColor: 'transparent',
  border: 'none',
  width: '2rem',
  height: '2rem',
  padding: 0,
  opacity: 0.8,
  cursor: 'pointer',
  animationName: pending ? pendingAnimation : 'none',
  animationIterationCount: 'infinite',
  animationDuration: '1.5s',
  animationTimingFunction: 'easeInOut',
  transitionProperty: 'opacity',
  transitionDuration: '0.5s',

  '&:hover': {
    opacity: 1,
  },
}));

export default function CurrentLocationButton({ onAddressFound, user, oAuth }) {
  const [currentLocationPending, setCurrentLocationPending] = useState(false);

  const onCurrentLocationClick = useCallback(() => {
    setCurrentLocationPending(true);

    getLocationAddress({ lat: user.location.latitude, lng: user.location.longitude }, oAuth, user)
      .then(onAddressFound)
      .finally(() => setCurrentLocationPending(false));
  }, [onAddressFound, oAuth, user]);

  return (
    <StyledCurrentLocationButton
      onClick={onCurrentLocationClick}
      pending={currentLocationPending}
    />
  );
}
