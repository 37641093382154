import { isEmpty, head, keys, size, cloneDeep } from 'lodash';
import { getStorageValue, STORAGE_IDS } from './localStorageHelpers';
import { getSafeValue } from './security';

export const orderFieldComposers = {
  Name: {
    fields: [
      'Name',
      'First Name',
      'Last Name',
      'Shipping Name',
      'Shipping First Name',
      'Shipping Last Name',
      'Member Full Name',
    ],
  },
  Address: {
    fields: [
      'Address',
      'Shipping Address',
      'Shipping Address 1',
      'Shipping Address Field',
      'Shipping Postal Code',
      'Shipping Zip Code',
      'Shipping Zip',
      'Shipping City',
      'Shipping State',
      'Shipping Region',
      'Shipping Country',
      'Street1',
      'Street2',
      'City',
      'State',
      'Zip/Postal Code',
    ],
    joinWith: ', ',
  },
  Longitude: { fields: ['Longitude'], takeFirst: true },
  'Start Time': {
    fields: ['Start Time', 'StartTime', 'Starting Time'],
    takeFirst: true,
  },
  Latitude: { fields: ['Latitude'], takeFirst: true },
  Weight: { fields: ['Weight'], takeFirst: true },
  Amount: { fields: ['Amount'], takeFirst: true },
  ID: { fields: ['Order #', 'Order ID', 'Order'], takeFirst: true },
  Phone: { fields: ['Shipping Phone', 'Billing Phone', 'Phone'], takeFirst: true },
  Email: { fields: ['Shipping Email', 'Billing Email', 'E-Mail'], takeFirst: true },
  Notes: {
    fields: ['Order Notes', 'Description', 'Note'],
    joinWith: '.\n',
    includeColumnName: true,
  },
};

export const addressLabelsToInclude = ['Apt', 'Apt #', 'Apartment', 'Building'];

export const getFractionToAdd = (number) => Math.ceil(number / 10);

export const alterLocationData = (order) => {
  if (!order || !order.Latitude || !order.Longitude) return order;

  const fieldsToInclude = keys(order).filter((key) => addressLabelsToInclude.includes(key));
  if (isEmpty(fieldsToInclude)) return order;

  const possibleValuesToAdd = fieldsToInclude.map((f) => parseInt(getSafeValue(order, f), 10));
  const values = possibleValuesToAdd.filter((v) => !!v && !Number.isNaN(v));
  if (isEmpty(values)) return order;

  const newOrder = cloneDeep(order);
  newOrder.LatitudeAlt = parseFloat(`${order.Latitude}${getFractionToAdd(values[0])}`);
  newOrder.LongitudeAlt = parseFloat(`${order.Longitude}${getFractionToAdd(values[0])}`);

  return newOrder;
};

const cleanValue = (value) => {
  if (!value || !value.replace) return value;
  return value
    .replace(/&amp;/g, '&')
    .replace(/&quot;/g, '"')
    .replace(/&rsquo;/g, "'")
    .replace(/&ldquo;/g, "'")
    .replace(/&rdquo;/g, "'")
    .replace(/&lt;br \/&gt;/g, '\n')
    .replace(/&lt;/g, '')
    .replace(/&gt;/g, '');
};

export function composeOrderField(order, fieldHeader, customCsvConfig) {
  if (!order || !fieldHeader || !fieldHeader.columnName) return null;

  const field = fieldHeader.columnName;
  const stored = getStorageValue(STORAGE_IDS.tourPlanner, { csvConfig: {} });
  const csvConfig = customCsvConfig || stored.csvConfig;
  const fieldTrans = fieldHeader.trans;
  const configuredFields = fieldTrans
    ? keys(csvConfig).filter((key) => getSafeValue(csvConfig, key) === fieldTrans)
    : [];
  const fieldConfig = getSafeValue(orderFieldComposers, field) || { fields: [], takeFirst: true };
  const validFields = isEmpty(configuredFields) ? fieldConfig.fields : configuredFields;

  let values = validFields.map((f) => cleanValue(getSafeValue(order, f))).filter((f) => !!f);
  if (fieldConfig.includeColumnName && size(configuredFields) > 1) {
    values = validFields
      .map((f) => {
        const value = cleanValue(getSafeValue(order, f));
        return isEmpty(value) ? value : `${f}: ${value}`;
      })
      .filter((f) => !!f);
  }

  // Special case. Add apt to address
  if (fieldHeader.columnName === 'Address' && size(configuredFields) > 1) {
    values = validFields
      .map((f) => {
        const value = cleanValue(getSafeValue(order, f));
        return !isEmpty(value) && addressLabelsToInclude.includes(f) ? `${f} ${value}` : value;
      })
      .filter((f) => !!f);
  }

  const joinWith = fieldConfig.joinWith || ' ';
  if (!isEmpty(values)) return fieldConfig.takeFirst ? head(values) : values.join(joinWith);
  const configField = getSafeValue(csvConfig || orderFieldComposers, field);

  if (isEmpty(configuredFields) && configField && configField !== fieldTrans) return '';

  return getSafeValue(order, field);
}
