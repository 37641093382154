import { combineReducers } from 'redux';
import solution from './solution';
import mapData from './mapData';
import mapContainer from './mapContainer';
import routingTour from './routingTour';
import error from './error';
import loader from './loader';
import tourPlanner from './tourPlanner';
import oAuth from './oAuth';
import orders from './orders';
import ordersNotLocated from './ordersNotLocated';
import user from './user';
import usageContext from './usageContext';
import uploadedFile from './uploadedFile';
import uploadedImage from './uploadedImage';
import areas from './areas';

const rootReducer = combineReducers({
  error,
  mapContainer,
  mapData,
  loader,
  routingTour,
  solution,
  tourPlanner,
  areas,
  oAuth,
  orders,
  ordersNotLocated,
  user,
  usageContext,
  uploadedFile,
  uploadedImage,
});

export default rootReducer;
