import React from 'react';
import withTranslation from 'hoc/withTranslation';
import { head, last } from 'lodash';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { fontSize } from 'global/variables';
import { getDiffInDays } from 'utils/timeFormatHelpers';
import { getNumberOfDepotStops } from '../../../utils/SolutionHelpers';
import { getSafeValue } from '../../../utils/security';
import { formatPriceWithCurrency } from '../../../utils/formatter';
import DetailElement from './DetailElement';
import DetailRow from './DetailRow';

const { small } = fontSize;

const CONSUMPTIONS_UNITS = {
  LITERS: 'liters',
  KILOGRAMS: 'kilograms',
};

const TourDetails = ({
  user,
  tours,
  tourPlanner,
  demandLabel,
  isDemandVisibleLocal,
  totalDetails,
  territories,
  skills,
  vehicleType,
  tourDemand,
  tourLoad,
  translations,
}) => {
  const {
    display: { selectedTour },
  } = useSelector(({ mapContainer }) => mapContainer);
  const {
    tourSolution: {
      vehicleTypeLabel,
      startEndDates,
      units: {
        distance: { kilo, mile },
      },
      shiftExtended,
      day,
    },
    wizard: {
      toursDetails: {
        stopsTrans,
        dropsTrans,
        durationTrans,
        costTrans,
        distanceTrans,
        loadTrans,
        skillsTrans,
        territoriesTrans,
        co2Trans,
        consumptionTransL,
        consumptionTransKG,
        consumptionExplTrans,
        co2Explanation,
      },
    },
  } = translations;

  const tourId = selectedTour ?? 0;
  const tour = getSafeValue(tours, tourId);
  const isImperial = user.distance === 'imperial';
  const offset = tourPlanner.offset;
  const startingDate = moment(head(tour.stops).time.departure).utc().utcOffset(offset);
  const endingDate = moment(last(tour.stops).time.departure).utc().utcOffset(offset);
  const useLiters = vehicleType?.fuel?.type !== 'cng';

  const diffDays = getDiffInDays(
    last(tour.stops).time.departure,
    head(tour.stops).time.arrival,
    offset,
  );

  const diffDaysTitle = diffDays
    ? `${shiftExtended} ${diffDays} ${day}${diffDays > 1 ? 's' : ''}`
    : `${startingDate.format('LLL')}/${endingDate.format('LLL')}`;
  const changeUiPosition = tourLoad && isDemandVisibleLocal;

  return (
    <>
      <DetailRow>
        <DetailElement
          label={distanceTrans}
          text={`${totalDetails.distance} ${isImperial ? mile : kilo}`}
        />
        <DetailElement label={durationTrans} text={totalDetails.time} />
        <DetailElement
          label={costTrans}
          text={formatPriceWithCurrency(Math.round(totalDetails.cost), user.currency, 0)}
        />
        <DetailElement
          label={stopsTrans}
          text={tour.stops.length - getNumberOfDepotStops(tour.stops)}
        />
      </DetailRow>
      <DetailRow noMarginTop="1rem">
        <DetailElement overflow label={vehicleTypeLabel} text={tour.typeId} title={tour.typeId} />
        <DetailElement
          label={startEndDates}
          text={`${startingDate.format('LT')}/${endingDate.format('LT')}`}
          title={diffDaysTitle}
          fontSize={small}
          diffDays={diffDays >= 1 ? `+${diffDays}` : ''}
        />
        {isDemandVisibleLocal && tourDemand && (
          <DetailElement
            label={isDemandVisibleLocal ? demandLabel : dropsTrans}
            text={`${tourDemand[0]} ${tourDemand.length > 1 ? '...' : ''}`}
            title={tourDemand.join(', ')}
          />
        )}
        {tourLoad && (
          <DetailElement
            label={loadTrans}
            text={`${tourLoad[0]} ${tourLoad.length > 1 ? '...' : ''}`}
            title={tourLoad.join(', ')}
          />
        )}
        {!changeUiPosition && totalDetails.consumption && (
          <DetailElement
            overflow
            label={useLiters ? consumptionTransL : consumptionTransKG}
            text={totalDetails.consumption}
            title={`${consumptionExplTrans}${
              useLiters ? CONSUMPTIONS_UNITS.LITERS : CONSUMPTIONS_UNITS.KILOGRAMS
            } `}
            textTitle={totalDetails.consumption}
          />
        )}
        {!changeUiPosition && totalDetails.co2Emission && (
          <DetailElement
            overflow
            label={co2Trans}
            text={totalDetails.co2Emission}
            textTitle={totalDetails.co2Emission}
            title={co2Explanation}
          />
        )}
      </DetailRow>
      {(skills || territories || totalDetails.consumption || totalDetails.co2Emission) && (
        <DetailRow>
          {skills && <DetailElement overflow label={skillsTrans} text={skills} title={skills} />}
          {territories && (
            <DetailElement
              overflow
              label={territoriesTrans}
              text={territories}
              title={territories}
            />
          )}
          {changeUiPosition && totalDetails.consumption && (
            <DetailElement
              overflow
              label={useLiters ? consumptionTransL : consumptionTransKG}
              text={totalDetails.consumption}
              title={`${consumptionExplTrans}${
                useLiters ? CONSUMPTIONS_UNITS.LITERS : CONSUMPTIONS_UNITS.KILOGRAMS
              } `}
              textTitle={totalDetails.consumption}
            />
          )}
          {changeUiPosition && totalDetails.co2Emission && (
            <DetailElement
              overflow
              label={co2Trans}
              text={totalDetails.co2Emission}
              textTitle={totalDetails.co2Emission}
              title={co2Explanation}
            />
          )}
        </DetailRow>
      )}
    </>
  );
};

export default withTranslation(TourDetails);
