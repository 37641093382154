import styled from '@emotion/styled';
import { colors } from 'global/variables';
import { isEmpty, noop } from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'utils/PropTypes';
import { isMobileDevice } from '../../utils/helpers';

const MIN = 0;
const getButtonWidth = (factor = 1) => {
  const width = isMobileDevice() ? 11 : 15;
  return width / factor;
};

const { primaryBorderColor, primaryColorHovered, primaryColor, secondaryColor, white } = colors;

const StyledContainer = styled.div(
  {
    minWidth: '10rem',
    height: '2rem',
  },
  ({ disabled }) => ({
    cursor: disabled ? 'default' : 'pointer',
  }),
);

const StyledSlider = styled.div`
  height: 0.1rem;
  background-color: ${primaryBorderColor};
  position: relative;
  margin: 0.75rem 0 0.25rem 0;
`;

const StyledButton = styled.div(
  {
    position: 'absolute',
    width: '0.8rem',
    height: '0.8rem',
    borderRadius: '2rem',
    top: '-0.5rem',
    backgroundColor: white,
    zIndex: 1,
    transition: 'all 0.75s cubic-bezier(0.600, 0.005, 0.320, 1.000)',
  },
  ({ disabled }) => ({
    cursor: disabled ? 'default' : 'grab',

    '&:nth-of-type(1)': {
      left: `${MIN}rem`,
      transition: !disabled && 'left 0.1s linear',
      border: `4px solid ${secondaryColor}`,
      '&:hover': {
        backgroundColor: disabled ? white : secondaryColor,
      },
    },

    '&:nth-of-type(2)': {
      right: `${MIN}rem`,
      transition: !disabled && 'right 0.1s linear',
      border: `4px solid ${primaryColor}`,
      '&:hover': {
        backgroundColor: disabled ? white : primaryColor,
      },
    },
  }),
);

const StyledLine = styled.div(
  {
    position: 'absolute',
    height: '0.25rem',
    left: `${MIN}rem`,
    right: `${MIN}rem`,
    backgroundImage: `linear-gradient(to right, ${secondaryColor}, ${primaryColorHovered})`,
  },
  ({ disabled }) => ({
    transition: !disabled && 'left 0.1s linear, right 0.1s linear',
  }),
);

let mousePosition = null;
let buttonPosition = null;
let activeButton = null;
let isDown = false;
let sliderWidth = null;
let scaleWidth = null;

const RangeSlider = ({ children, disabled, endIndex, onChange, scale, startIndex }) => {
  const [startPosition, setStartPosition] = useState(MIN);
  const [endPosition, setEndPosition] = useState(MIN);
  const slider = useRef();
  const startButton = useRef();
  const endButton = useRef();
  const line = useRef();
  const buttons = {
    start: {
      value: startPosition,
      setValue: setStartPosition,
      setPosition: (value) => {
        startButton.current.style.left = `${value}px`;
        line.current.style.left = `${value}px`;
      },
      setActive: () => {
        startButton.current.style.backgroundColor = secondaryColor;
      },
      setDeactive: () => {
        startButton.current.style.backgroundColor = white;
      },
      setTime: (value) =>
        onChange(Math.round(value / scaleWidth), Math.round(endPosition / scaleWidth)),
    },
    end: {
      value: endPosition,
      setValue: setEndPosition,
      setPosition: (value) => {
        endButton.current.style.right = `${value}px`;
        line.current.style.right = `${value}px`;
      },
      setActive: () => {
        endButton.current.style.backgroundColor = primaryColor;
      },
      setDeactive: () => {
        endButton.current.style.backgroundColor = white;
      },
      setTime: (value) =>
        onChange(Math.round(startPosition / scaleWidth), Math.round(value / scaleWidth)),
    },
  };

  useEffect(() => {
    const { start, end } = buttons;
    sliderWidth = slider.current.getBoundingClientRect().width; // TO DO: custom method
    scaleWidth = sliderWidth / scale;
    let startPos = null;
    let endPos = null;
    if (startIndex > 0 && startIndex !== endIndex && startIndex < scale) {
      startPos = scaleWidth * startIndex - getButtonWidth(2);
      start.setPosition(startPos);
      start.setValue(startPos);
    }

    if (endIndex > 0 && startIndex !== endIndex && endIndex < scale) {
      endPos = scaleWidth * endIndex - getButtonWidth(2);
      end.setPosition(endPos);
      end.setValue(endPos);
    }

    onChange(
      Math.round((startPos || startPosition) / scaleWidth),
      Math.round((endPos || endPosition) / scaleWidth),
    );
  }, [endIndex, startIndex]); // invoke only if index change

  const onMouseMove = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (isDown) {
      const clientX = evt.clientX || (evt.touches && evt.touches[0].clientX);
      const mousePositionDiff = clientX - mousePosition;

      if (Math.abs(mousePositionDiff) > scaleWidth - 1) {
        const isStart = activeButton === buttons.start;
        let step = mousePositionDiff > 0 ? -1 * scaleWidth : scaleWidth;

        if (isStart) {
          step *= -1;
        }

        // correct position in case of sliding from start or end
        if (!buttonPosition) {
          step -= getButtonWidth(2);
        }

        // prevent intersection
        if (
          (startPosition === MIN &&
            endPosition === MIN &&
            buttonPosition + step < sliderWidth - getButtonWidth()) ||
          (isStart && buttonPosition + step + endPosition < sliderWidth - getButtonWidth()) ||
          (!isStart && buttonPosition + step + startPosition < sliderWidth - getButtonWidth())
        ) {
          buttonPosition += step;

          if (buttonPosition < MIN) {
            buttonPosition = MIN;
          }
          if (buttonPosition > sliderWidth - getButtonWidth(2)) {
            buttonPosition = sliderWidth - getButtonWidth(2);
          }
          activeButton.setPosition(buttonPosition);
          mousePosition = isStart ? mousePosition + step : mousePosition - step;
        }
      }
    }
  };

  const onMouseUp = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (isDown) {
      activeButton.setDeactive();
      activeButton.setValue(buttonPosition);
      activeButton.setTime(buttonPosition, scaleWidth);
      isDown = false;
      activeButton = null;
      sliderWidth = null;
      mousePosition = null;
      document.onmouseup = null;
      document.onmousemove = null;
      document.ontouchmove = null;
      document.ontouchend = null;
    }
  };

  const onMouseDown = (button) => (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (!isDown) {
      activeButton = button;
      activeButton.setActive();
      isDown = true;
      sliderWidth = slider.current.getBoundingClientRect().width; // TO DO: custom method
      scaleWidth = sliderWidth / scale;
      const clientX = evt.clientX || evt.touches[0].clientX;
      mousePosition = clientX;
      buttonPosition = activeButton.value;
      document.onmouseup = onMouseUp;
      document.onmousemove = onMouseMove;
      document.ontouchend = onMouseUp;
      document.ontouchmove = onMouseMove;
    }
  };

  const onClick = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();

    if (!isDown && isEmpty(evt.target.id)) {
      const { x: sliderBegin, width } = slider.current.getBoundingClientRect();
      const { x: endButtonPosition } = endButton.current.getBoundingClientRect();
      const { x: startButtonPosition } = startButton.current.getBoundingClientRect();
      sliderWidth = width;
      scaleWidth = sliderWidth / scale;
      const sliderEnd = sliderWidth + sliderBegin;
      const newPos =
        Math.floor((evt.clientX - sliderBegin) / scaleWidth) * scaleWidth - getButtonWidth(2);
      const distToEnd = endButtonPosition - newPos;
      const distToStart = newPos - startButtonPosition;
      if (newPos < endButtonPosition - scaleWidth - sliderBegin && distToStart < distToEnd) {
        const { start } = buttons;
        start.setPosition(newPos);
        start.setValue(newPos);
        start.setTime(newPos, scaleWidth);
      } else {
        const newPosEnd =
          Math.ceil((sliderEnd - evt.clientX) / scaleWidth) * scaleWidth - getButtonWidth(2);
        const { end } = buttons;
        end.setPosition(newPosEnd);
        end.setValue(newPosEnd);
        end.setTime(newPosEnd, scaleWidth);
      }
    }
  };

  return (
    <StyledContainer disabled={disabled} onMouseDown={disabled ? noop : onClick}>
      {children}
      <StyledSlider ref={slider}>
        <StyledButton
          disabled={disabled}
          id="rangeSliderStartButton"
          ref={startButton}
          onMouseDown={disabled ? noop : onMouseDown(buttons.start)}
          onMouseUp={disabled ? noop : onMouseUp}
          onMouseMove={disabled ? noop : onMouseMove}
          onTouchStart={disabled ? noop : onMouseDown(buttons.start)}
        />
        <StyledButton
          disabled={disabled}
          id="rangeSliderEndButton"
          ref={endButton}
          onMouseDown={disabled ? noop : onMouseDown(buttons.end)}
          onMouseUp={disabled ? noop : onMouseUp}
          onMouseMove={disabled ? noop : onMouseMove}
          onTouchStart={disabled ? noop : onMouseDown(buttons.end)}
        />
        <StyledLine disabled={disabled} ref={line} />
      </StyledSlider>
    </StyledContainer>
  );
};

RangeSlider.propTypes = {
  children: PropTypes.children,
  disabled: PropTypes.bool,
  endIndex: PropTypes.number,
  onChange: PropTypes.func,
  scale: PropTypes.number,
  startIndex: PropTypes.number,
};

RangeSlider.defaultProps = {
  children: null,
  disabled: false,
  endIndex: 9, // scale - 1
  onChange: noop,
  scale: 10,
  startIndex: 0,
};

export default RangeSlider;
