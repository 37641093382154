import React, { useState, useEffect, useCallback } from 'react';
import withTranslation from 'hoc/withTranslation';
import styled from '@emotion/styled';
import NumericInput from 'components/Wizard/Global/NumericInput';
import { StyledDoublePanel } from 'components/Wizard/Global/WizardStyled';
import config from 'config';

const StyledTerminationCriteriaListContainer = styled.div({
  padding: '1rem 0',
});

const {
  defaults: { solverConfiguration },
  maxValues,
  minValues,
} = config;

const TerminationCriteriaList = ({
  terminationCriteria,
  onChange,
  translations: {
    solverConfigLabels: {
      terminationCriteriaLabels: { criteriaLabels },
    },
  },
}) => {
  const [terminationCriteriaValues, setTerminationCriteriaValues] = useState();
  useEffect(() => {
    const initialConfig = !terminationCriteria
      ? solverConfiguration.terminationCriteria
      : terminationCriteria;
    setTerminationCriteriaValues(initialConfig);
  }, []);

  const handleTerminationCriteriaChange = useCallback(
    (newObject) => {
      if (onChange) onChange(newObject);
    },
    [onChange],
  );

  useEffect(
    () => handleTerminationCriteriaChange(terminationCriteriaValues),
    [terminationCriteriaValues],
  );

  const onHandleSetValue = useCallback(
    (value, id) => {
      const newObject = { [id]: value };
      const newTerminationCriteria = { ...terminationCriteriaValues, ...newObject };
      setTerminationCriteriaValues(newTerminationCriteria);
      if (onChange) onChange(newTerminationCriteria);
    },
    [terminationCriteriaValues],
  );

  if (!terminationCriteriaValues) return null;
  return (
    <StyledTerminationCriteriaListContainer>
      <StyledDoublePanel>
        <div>
          <NumericInput
            theme="contrast"
            stepValue="1"
            id="maxTime"
            integer
            inputLabel={criteriaLabels.maxTimeLabel}
            initialValue={terminationCriteriaValues.maxTime}
            minValue={minValues.solverConfiguration.terminationCriteria.maxTime}
            maxValue={maxValues.solverConfiguration.terminationCriteria.maxTime}
            onChange={onHandleSetValue}
          />
        </div>
        <div>
          <NumericInput
            theme="contrast"
            stepValue="1"
            id="stagnationTime"
            integer
            inputLabel={criteriaLabels.stagnationTimeLabel}
            initialValue={terminationCriteriaValues.stagnationTime}
            minValue={minValues.solverConfiguration.terminationCriteria.stagnationTime}
            maxValue={maxValues.solverConfiguration.terminationCriteria.stagnationTime}
            onChange={onHandleSetValue}
          />
        </div>
      </StyledDoublePanel>
    </StyledTerminationCriteriaListContainer>
  );
};

export default withTranslation(TerminationCriteriaList);
