import { USER_INCREASE_USAGE, RECORD_USAGE_EVENT, USAGE_EVENTS } from 'actions';
import { assign, cloneDeep } from 'lodash';
import { getStorageValue, setStorageValue, STORAGE_IDS } from '../utils/localStorageHelpers';
import { getSafeValue, setSafeValue } from '../utils/security';

const contextFromStorage = getStorageValue(STORAGE_IDS.usageContext);
const initialTourPlanner = getStorageValue(STORAGE_IDS.tourPlanner);
const initialStep = initialTourPlanner ? 2 : 1;
const initialStats = {
  addOrdersModes: [],
  dispatchModes: [],
  maxOrdersPlanned: 0,
  maxOrdersDispatched: 0,
};

const initialContext = {
  currentStep: initialStep,
  today: assign({}, initialStats),
  global: assign({}, initialStats),
  feedback: { answers: [] },
};
const context = contextFromStorage || initialContext;
context.currentStep = initialStep;
context.today = assign({}, initialStats);

const storeContext = (state) => setStorageValue(STORAGE_IDS.usageContext, state);

const addModeToArray = (tempState, modeType, mode) => {
  if (!getSafeValue(tempState.today, modeType).includes(mode))
    getSafeValue(tempState.today, modeType).push(mode);
  if (!getSafeValue(tempState.global, modeType).includes(mode))
    getSafeValue(tempState.global, modeType).push(mode);
};

const updateMaxValue = (tempState, property, value) => {
  const maxToday = Math.max(getSafeValue(tempState.today, property), value);
  const maxGlobal = Math.max(getSafeValue(tempState.global, property), value);
  setSafeValue(tempState.today, property, maxToday);
  setSafeValue(tempState.global, property, maxGlobal);
};

export default (state = cloneDeep(context), { payload = {}, type }) => {
  switch (type) {
    case RECORD_USAGE_EVENT: {
      const newState = assign({}, state);
      if (payload.event === USAGE_EVENTS.ORDERS_ADD) {
        addModeToArray(newState, 'addOrdersModes', payload.mode);
      }
      if (payload.event === USAGE_EVENTS.WIZARD_SET_STEP) newState.currentStep = payload.step;
      if (payload.event === USAGE_EVENTS.FEEDBACK_ADD) {
        if (!newState.feedback.answers.includes(payload.functionality)) {
          newState.feedback.answers.push(payload.functionality);
        }
      }
      storeContext(newState);
      return newState;
    }
    case USER_INCREASE_USAGE: {
      const newState = assign({}, state);
      if (payload.ordersDispatched) {
        addModeToArray(newState, 'dispatchModes', payload.mode);
        updateMaxValue(newState, 'maxOrdersDispatched', payload.ordersDispatched);
      }
      storeContext(newState);
      return newState;
    }
    default: {
      return state;
    }
  }
};
