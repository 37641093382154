import React, { useCallback, useEffect, useState } from 'react';
import { StyledText } from 'components/Wizard/Global/WizardStyled';
import { useDispatch, useSelector } from 'react-redux';
import { cloneDeep } from 'lodash';
import Modal from '../../Presentation/Modal';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../../utils/amplitude';
import Button from '../../Form/Button';
import withTranslation from '../../../hoc/withTranslation';
import {
  StyledContrastPanel,
  StyledContrastPanelFooter,
  StyledContrastPanelHeader,
  StyledContrastPanelHeaderText,
} from '../../Wizard/Global/WizardStyledContrastPanel';
import Section from '../../Form/Section';
import ObjectiveFunctions from './ObjectiveFunctions';
import TerminationCriteria from './TerminationCriteria';
import { setTourParameter, setUserParam } from '../../../actions';
import { StyledAccessContainer } from '../StyledComponents';
import entryPointIcon from '../../../global/img/settings.svg';
import { PANELS } from '../../../reducers/user';

const SolverConfigurationContainer = ({ user, translations: { solverConfigLabels } }) => {
  const solution = useSelector(({ solution: stateSolution }) => stateSolution);
  const tourPlanner = useSelector(
    ({ tourPlanner: stateTourPlanner }) => stateTourPlanner.value[solution.show],
  );
  const usageContext = useSelector(({ usageContext: stateUsageContext }) => stateUsageContext);
  const [showPanel, setShowPanel] = useState(false);
  const [objectiveFunctions, setObjectiveFunctions] = useState(
    cloneDeep(tourPlanner.solverConfiguration.objectiveFunctions),
  );
  const [terminationCriteria, setTerminationCriteria] = useState(
    cloneDeep(tourPlanner.solverConfiguration.terminationCriteria),
  );
  const dispatch = useDispatch();
  const handleSetTourParameter = useCallback(
    (parameter) => dispatch(setTourParameter({ ...parameter, index: solution.show })),
    [dispatch, solution.show],
  );
  const handleSetUserParam = useCallback((data) => dispatch(setUserParam(data)), [dispatch]);
  const showAccesContainer = !solution.json && ![3, 4].includes(usageContext.currentStep);

  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        // eslint-disable-next-line no-use-before-define
        togglePanel('close');
      }
    },
    [showPanel],
  );

  const togglePanel = useCallback(
    (forceAction) => {
      const action = forceAction === undefined ? (showPanel ? 'close' : 'open') : forceAction;
      const info = { action };
      const newState = action === 'open';
      AmplitudeService.log(AMPLITUDE_EVENTS.SOLVER_CONFIG_ACTION, info);
      handleSetUserParam({ openPanel: newState ? PANELS.SOLVER_CONFIG : undefined });
      setShowPanel(newState);

      if (action === 'open') {
        document.addEventListener('keydown', escFunction, false);
        setObjectiveFunctions(cloneDeep(tourPlanner.solverConfiguration.objectiveFunctions));
        setTerminationCriteria(cloneDeep(tourPlanner.solverConfiguration.terminationCriteria));
      } else {
        document.removeEventListener('keydown', escFunction, false);
      }
    },
    [setShowPanel, showPanel, tourPlanner, handleSetUserParam],
  );

  const handleOnChangeObjectiveFunctions = useCallback((newObjectiveFunctions) => {
    setObjectiveFunctions(newObjectiveFunctions);
  });

  const handleOnChangeTerminationCriteria = useCallback((newTerminationCriteria) => {
    setTerminationCriteria(newTerminationCriteria);
  });

  const handleOnSubmit = useCallback(() => {
    handleSetTourParameter({ solverConfiguration: { objectiveFunctions, terminationCriteria } });
    togglePanel();
  }, [objectiveFunctions, terminationCriteria]);

  useEffect(() => {
    setObjectiveFunctions(tourPlanner.solverConfiguration.objectiveFunctions);
  }, [tourPlanner.solverConfiguration.objectiveFunctions]);

  useEffect(() => {
    setShowPanel(user.openPanel === PANELS.SOLVER_CONFIG);
  }, [user.openPanel, setShowPanel]);

  return (
    <>
      <Modal isDark isVisible={showPanel} isFull fromTop>
        <StyledContrastPanel fullDisplay data-test-id="solverSettingsPanel">
          <StyledContrastPanelHeader size="0">
            <StyledText bold contrast>
              {solverConfigLabels.configTitle}
            </StyledText>
            <StyledContrastPanelHeaderText paddingBottom>
              {solverConfigLabels.configDesc}
            </StyledContrastPanelHeaderText>
          </StyledContrastPanelHeader>
          {showPanel && (
            <Section>
              <TerminationCriteria
                terminationCriteria={terminationCriteria}
                onChange={handleOnChangeTerminationCriteria}
              />
              <ObjectiveFunctions
                objectiveFunctions={objectiveFunctions}
                onChange={handleOnChangeObjectiveFunctions}
                terminationCriteriaEnabled={terminationCriteria.enabled}
                isBeta={user.tpaVersion.version === 'beta'}
                solution={solution}
              />
            </Section>
          )}
          <StyledContrastPanelFooter isDouble isFullWidth>
            <Button
              text={solverConfigLabels.buttonCancelLabel}
              inverted
              contrast
              onClick={() => togglePanel()}
            />
            <div />
            <Button text={solverConfigLabels.buttonSubmitLabel} onClick={handleOnSubmit} />
          </StyledContrastPanelFooter>
        </StyledContrastPanel>
      </Modal>
      {showAccesContainer && (
        <StyledAccessContainer
          data-testid="solverSettingBtn"
          id="solver-settings-entry-point"
          onClick={() => togglePanel()}
          title={solverConfigLabels.panelConfigurationTitle}
          order={2}
          icon={entryPointIcon}
        />
      )}
    </>
  );
};

export default withTranslation(SolverConfigurationContainer);
