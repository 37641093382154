import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { getAsset } from 'utils/helpers';
import jobIcon from '../../../global/img/mini-orders-times.svg';
import { colors, fontSize } from '../../../global/variables';
import chevronDown from '../../../global/img/chevron_down.svg';
import chevronDownContrast from '../../../global/img/chevron-down-contrast.svg';
import informationNormalIcon from '../../../global/img/information_.svg';
import warningIcon from '../../../global/img/warning-icon.svg';

const {
  primaryBorderColor,
  transparentGreyColor,
  placeholderTextColor,
  secondaryBorderColor,
  secondaryTextColor,
  primaryColor,
  primaryColorHovered,
  black,
  white,
  aqua,
  primaryTextColor,
  secondaryTextColorDarkBg,
} = colors;
const { big, normal, medium, small, smaller } = fontSize;

export const StyledWizardContent = styled.div`
  padding: 1.2rem 1.2rem 0 1.2rem;
  box-sizing: border-box;
  position: ${(props) => props.sticky && 'fixed'};
  overflow: ${(props) => props.sticky && 'hidden'};
  width: ${(props) => props.sticky && '100%'};
`;

export const StyledWizardH1 = styled.h1`
  margin-bottom: ${(props) => (props.noMargin ? 0 : props.smallMargin ? '0.5rem' : '1.2rem')};
  margin-top: ${(props) => props.marginTop && '1.2rem'};
  font-size: ${normal};
  color: ${secondaryTextColor};
  font-family: 'FiraGO';
`;
export const StyledWizardH2 = styled.h2`
  margin-bottom: ${(props) => (props.noMargin ? 0 : '1.2rem')};
  margin-top: ${(props) => props.marginTop && '1.2rem'};
  font-size: ${small};
  color: ${secondaryTextColor};
  font-family: 'FiraGO';
`;

export const StyledLocationInput = styled.input`
  background: ${transparentGreyColor} url(${(props) => props.icon || ''}) no-repeat scroll right;
  background-size: 1.25rem 1.25rem;
  background-origin: border-box;
  background-position: 0.7rem;
  color: ${secondaryTextColor};
  font-size: ${normal};
  width: 100%;
  padding-top: ${(props) => (props.icon ? '0.8rem' : '0.7rem')};
  padding-right: ${(props) => (props.hasControl ? '2.5rem' : '0.75rem')};
  padding-bottom: ${(props) => (props.icon ? '0.6rem' : '0.7rem')};
  padding-left: ${(props) => (props.icon ? '2.5rem' : '0.75rem')};
  background-clip: padding-box;
  border: 1px solid ${primaryBorderColor};
  border-radius: 0.12rem;
  font-family: 'FiraGO';
  font-weight: 400;
  text-rendering: geometricPrecision;
  letter-spacing: -0.5px;
  word-wrap: normal;
  box-sizing: border-box;
  outline: none;
  margin-top: 0;
  margin-bottom: 0.8rem;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(34, 205, 200, 0.4);
  -moz-box-shadow: 0px 0px 0px 0px rgba(34, 205, 200, 0.4);
  box-shadow: 0px 0px 0px 0px rgba(34, 205, 200, 0.4);
  transition: all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005);
  &:active,
  &:focus {
    -webkit-box-shadow: 0px 0px 0px 2px rgba(34, 205, 200, 0.4);
    -moz-box-shadow: 0px 0px 0px 2px rgba(34, 205, 200, 0.4);
    box-shadow: 0px 0px 0px 2px rgba(34, 205, 200, 0.4);
  }
  &::placeholder {
    font-style: italic;
    font-weight: 300;
    color: ${placeholderTextColor};
    font-size: ${normal};
  }
`;

export const StyledTextInput = styled.input`
  background: ${(props) => (props.theme === 'contrast' ? black : `${transparentGreyColor}`)}
    url(${(props) => (props.icon ? props.icon : '')}) no-repeat scroll left;
  background-size: 1.25rem 1.25rem;
  background-position: 0.7rem;
  text-rendering: geometricPrecision;
  color: ${(props) => (props.theme === 'contrast' ? aqua : `${secondaryTextColor}`)};
  font-size: ${(props) => (props.small ? medium : normal)};
  width: 100%;
  padding: ${(props) => (props.small ? '0.5rem' : '0.7rem')} 0.75rem
    ${(props) => (props.small ? '0.5rem' : '0.7rem')}
    ${(props) => (props.icon ? '2.75rem' : '0.75rem')};
  border: 1px solid ${primaryBorderColor};
  border-radius: 0.12rem;
  font-family: ${(props) =>
    props.id === 'input-api-key' && props.defaultValue ? 'password' : 'FiraGO'};
  font-weight: ${(props) => (props.weight ? props.weight : '400')};
  word-wrap: normal;
  letter-spacing: -0.5px;
  box-sizing: border-box;
  outline: none;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(34, 205, 200, 0.4);
  -moz-box-shadow: 0px 0px 0px 0px rgba(34, 205, 200, 0.4);
  box-shadow: 0px 0px 0px 0px rgba(34, 205, 200, 0.4);
  transition: all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005);
  opacity: ${(props) => (props.disabled ? '0.3' : '1')};
  &:active,
  &:focus {
    -webkit-box-shadow: ${(props) => !props.readOnly && '0px 0px 0px 2px rgba(34, 205, 200, 0.4)'};
    -moz-box-shadow: ${(props) => !props.readOnly && '0px 0px 0px 2px rgba(34, 205, 200, 0.4)'};
    box-shadow: ${(props) => !props.readOnly && '0px 0px 0px 2px rgba(34, 205, 200, 0.4)'};
  }
  &:required:invalid {
    border-color: #ff6666;
  }
  &:required:focus:invalid {
    background: ${(props) => (props.theme === 'contrast' ? black : `${transparentGreyColor}`)}
      url(${getAsset(warningIcon)}) no-repeat scroll 95%;
  }
  &::placeholder {
    font-style: italic;
    font-weight: 300;
    color: ${(props) => (props.theme === 'contrast' ? aqua : `${placeholderTextColor}`)};
    font-size: ${normal};
  }
`;

export const StyledLine = styled.div`
  width: calc(100% + 3rem);
  margin: ${(props) => (props.noMarginBottom ? '1rem 0 0 -1.5rem' : '1rem 0 1rem -1.5rem')};
  border: 0.5px solid ${secondaryBorderColor};
`;

export const StyledCheckboxDoublePanel = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  justify-content: space-around;
  width: 100%;
  padding: 0;
  text-align: left;
  min-height: 0;
  align-items: center;

  div {
    box-sizing: border-box;
  }
  div:nth-of-type(2) {
    padding-right: 5%;
  }
  div:nth-of-type(3) {
    padding-left: 5%;
  }
`;

export const StyledDoublePanel = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.useMax ? 'auto auto' : '1fr 1fr')};
  justify-content: ${(props) => (props.useMax ? 'space-between' : 'space-around')};
  width: 100%;
  padding: 0;
  text-align: left;
  margin-top: ${(props) => (props.marginTop ? '1.5rem' : '0')};
  min-height: ${(props) => (props.hasControls ? '5rem' : '0')};

  div {
    box-sizing: border-box;
  }
  div:nth-of-type(1) {
    padding-right: ${(props) => (props.useMax ? '0' : '5%')};
  }
  div:nth-of-type(2) {
    padding-left: ${(props) => (props.useMax ? '0' : '5%')};
    text-align: ${(props) => (props.extremes ? 'right' : 'left')};
  }
`;

export const StyledTriplePanel = styled.div`
  display: grid;
  grid-template-columns: ${(props) => (props.useMax ? 'auto auto' : '1fr 1fr 1fr')};
  justify-content: ${(props) => (props.useMax ? 'space-between' : 'space-around')};
  width: 100%;
  padding: 0;
  text-align: left;
  margin-top: ${(props) => (props.marginTop ? '1.5rem' : '0')};
  min-height: ${(props) => (props.hasControls ? '5rem' : '0')};

  div {
    box-sizing: border-box;
  }
  div:nth-of-type(1) {
    padding-right: ${(props) => (props.useMax ? '0' : '5%')};
  }
  div:nth-of-type(2) {
    padding-left: ${(props) => (props.useMax ? '0' : '5%')};
    text-align: ${(props) => (props.extremes ? 'right' : 'left')};
  }
  div:nth-of-type(3) {
    padding-left: ${(props) => (props.useMax ? '0' : '5%')};
    text-align: ${(props) => (props.extremes ? 'right' : 'left')};
  }
`;

export const StyledText = styled.strong`
  margin-bottom: 0.25rem;
  position: ${(props) => (props.detailsTitle || props.jsonTitle) && 'relative'};
  color: ${(props) => (props.contrast ? white : secondaryTextColor)};
  font-size: ${(props) => props.fontSize ?? (props.big ? `${big}` : `${medium}`)};
  font-weight: ${(props) => (props.bold ? '700' : '500')};
  height: 1.75rem;
  text-transform: ${(props) => (props.uppercase ? 'uppercase' : 'none')};
  overflow: ${(props) => props.overflowControl && 'hidden'};
  text-overflow: ${(props) => props.overflowControl && 'ellipsis'};
  white-space: ${(props) => props.overflowControl && 'nowrap'};
  top: ${(props) => (props.jsonTitle ? '0.3rem' : props.detailsTitle ? '-1.2rem' : 'auto')};

  &:after {
    position: relative;
    top: -0.4rem;
    color: green;
    content: '${(props) => props.diffDays}';
    font-size: ${smaller};
    font-weight: 400;
  }
`;

export const StyledTextLight = styled.strong`
  font-weight: 300;
  margin-bottom: 0.25rem;
  color: ${(props) => (props.contrast ? white : secondaryTextColor)};
  font-size: ${medium};
  height: 1.75rem;
`;

export const StyledButtonSmall = styled.div(({ contrast, selected, predefinedBtn, disabled }) => ({
  color: contrast || selected ? white : secondaryTextColor,
  backgroundColor: selected && secondaryTextColor,
  transition: 'all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005)',
  fontSize: small,
  textAlign: 'left',
  lineHeight: '1rem',
  cursor: 'pointer',
  marginLeft: predefinedBtn && '1rem',
  marginBottom: predefinedBtn && '0.4rem',
  div: {
    float: 'right',
  },
  fontWeight: '700',
  padding: '7px 9px 6px 12px',
  display: 'inline-block',
  pointerEvents: disabled && 'none',
  opacity: disabled && '50%',
  borderRadius: '3rem',
  border: `solid 1px ${primaryColorHovered} !important`,
  boxShadow: `inset 0px 0px 0px .1px ${primaryColor} !important`,
  textTransform: 'uppercase',
  '&:hover': {
    border: `solid 1px ${primaryColor} !important`,
    boxShadow: `inset 0px 0px 0px 1px ${primaryColor} !important`,
  },
}));

export const StyledLink = styled.a(({ contrast }) => ({
  fontWeight: 700,
  textDecoration: 'none',
  color: contrast ? primaryColor : secondaryTextColor,
}));

export const StyledFullWidthContainer = styled.div`
  width: calc(100% + 3rem);
  margin: 0 0 0 -1.5rem;
  overflow: ${(props) => props.sticky && 'auto'};
  height: ${(props) => props.sticky && 'calc(100vh - 35.5rem)'};
`;

const iconSizeMini = '0.8rem';
const iconSize = '1rem';
const iconSizeBig = '1.2rem';
const iconSizeXL = '1.5rem';
const iconSizeHuge = '2rem';
export const StyledIcon = styled.div(
  {
    width: iconSize,
    height: iconSize,
    backgroundSize: `${iconSizeMini}`,
    backgroundPositionY: 0,
    backgroundPositionX: 'center',

    display: 'inline-block',
    backgroundRepeat: 'no-repeat',
    verticalAlign: 'text-bottom',
  },
  ({ margin }) => ({
    margin: margin || '0 0.1rem',
  }),
  ({ icon }) => ({
    ...icon,
  }),
  ({ iconUrl }) => ({
    backgroundImage: iconUrl && `url(${getAsset(iconUrl)})`,
  }),
  ({ mini }) => ({
    width: mini && iconSizeMini,
    height: mini && iconSizeMini,
    backgroundSize: mini && `${iconSizeMini}`,
  }),
  ({ sizeL, popup }) => ({
    position: sizeL && popup && 'relative',
    top: sizeL && popup && '0.3rem',
    marginLeft: sizeL && popup && '0',
    width: sizeL && iconSizeBig,
    height: sizeL && iconSizeBig,
    backgroundSize: sizeL && `${iconSizeBig}`,
  }),
  ({ XL }) => ({
    width: XL && iconSizeXL,
    height: XL && iconSizeXL,
    backgroundSize: XL && `${iconSizeXL}`,
  }),
  ({ huge, logo }) => ({
    width: huge && iconSizeHuge,
    height: huge && iconSizeHuge,
    backgroundSize: (huge && logo && '2rem') || (huge && '1.8rem'),
    marginTop: huge && logo && '1.5rem',
  }),
  ({ onClick }) => ({
    pointerEvents: !onClick && 'none',
  }),
);

export const JobIconStyle = css`
  background-image: url(${getAsset(jobIcon)});
  width: 1.4rem;
  height: 1.2rem;
  background-size: 1.4rem;
  position: relative;
  top: 3px;
  margin: 0;
`;

export const JobForTitleIconStyle = css`
  background-image: url(${getAsset(jobIcon)});
  width: 1.4rem;
  height: 1.2rem;
  background-size: 1.4rem;
  position: relative;
  top: 3px;
  margin: 0 5px 0 0;
`;

export const StyledInfoWrapper = styled.div`
  line-height: 1rem;
  padding: 0.5rem 0;
  text-align: left !important;
`;

export const StyledInfoEllipsis = styled.div(({ clickable, hasMarginTop }) => ({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  cursor: clickable && 'pointer',
  '&:hover': {
    color: clickable && aqua,
  },
  marginTop: hasMarginTop && '0.2rem',
}));

export const StyledReadMoreLessContainer = styled.div({
  padding: '0.5rem 0',
  textAlign: 'right',
  lineHeight: '1rem',
});

export const StyledFullPanelContainer = styled.div({
  display: 'flex',
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: '2000',
  textAlign: 'center',
  verticalAlign: 'middle',
  fontWeight: 'bold',
  lineHeight: '2rem',
  background: 'radial-gradient(circle, rgba(255,255,255,.95) 60%, rgba(255,255,255,0.75) 100%);',
  color: black,
});

export const StyledSelectInput = styled.select(
  ({ icon, contrast, sizeL, unselected, disabled, downloadSelect, marginBottom }) => ({
    width: '100%',
    fontSize: sizeL ? '1rem' : small,
    border: `1px solid ${primaryBorderColor}`,
    borderRadius: downloadSelect ? '1rem' : '0.1rem',
    fontFamily: 'FiraGO',
    fontWeight: '400',
    fontStyle: unselected ? 'italic' : 'normal',
    textRendering: 'geometricPrecision',
    letterSpacing: '-0.5px',
    outline: 'none',
    lineHeight: '1.5',
    marginBottom: marginBottom && '1rem',
    padding: icon ? '0.4rem 1.4rem 0.3rem 1.8rem' : '0.4rem 1.4rem 0.3rem 0.8rem',
    appearance: 'none',
    backgroundImage: contrast
      ? `url(${getAsset(chevronDownContrast)}), url(${getAsset(icon)})`
      : `url(${getAsset(chevronDown)}), url(${getAsset(icon)})`,
    linearGradient: '(to bottom, #ffffff 0%,#e5e5e5 100%)',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'calc(100% - 0.4rem), 0.5rem',
    backgroundSize: '0.65rem auto, 0.85rem',
    backgroundColor: contrast ? black : 'rgba(0,0,0,0.02)',
    cursor: disabled ? 'default' : 'pointer',
    transition: 'all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005)',
    color: contrast ? (unselected ? secondaryTextColorDarkBg : aqua) : black,
    '&:hover': {
      backgroundColor: contrast ? black : white,
    },
    '*': {
      border: 'none',
    },
  }),
);

export const StyledSelectBigInput = styled.select(({ icon, fullWidth, fSize, bold }) => ({
  color: `${secondaryTextColor}`,
  width: fullWidth ? '100%' : '10rem',
  fontSize: fSize || medium,
  border: `1px solid ${primaryBorderColor}`,
  borderRadius: '0.1rem',
  fontFamily: 'FiraGO',
  fontWeight: bold ? 500 : 400,
  textRendering: 'geometricPrecision',
  letterSpacing: '-0.5px',
  lineHeight: '2.4',
  padding: icon ? '0.4rem 1.4rem 0.3rem 2.6rem' : '0.4rem 1.4rem 0.3rem 0.8rem',
  appearance: 'none',
  backgroundImage: `url(${getAsset(chevronDown)}), url(${getAsset(icon)})`,
  linearGradient: '(to bottom, #ffffff 0%,#e5e5e5 100%)',
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'calc( 100% - 0.8rem) 50%, 0.7rem',
  backgroundSize: '0.65rem auto, 1.2rem 1.2rem',
  backgroundColor: 'rgba(0,0,0,0.02)',
  cursor: 'pointer',
  transition: 'all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005)',
  '&:hover': {
    backgroundColor: white,
    boxShadow: '0px 0px 0px 2px rgba(34,205,200,0.4)',
  },
}));

export const StyledSelectedInputLabel = styled.div(({ asBlock }) => ({
  fontSize: smaller,
  textTransform: 'uppercase',
  color: primaryTextColor,
  display: asBlock ? 'block' : 'inline-block',
  lineHeight: '1.4rem',
  marginRight: '0.6rem',
}));

export const StyledTextArea = styled.textarea`
  color: ${secondaryTextColor};
  font-size: ${normal};
  width: 100% !important;
  height: 7.5rem !important;
  border: 1px solid ${primaryBorderColor};
  border-radius: 0.12rem;
  font-family: 'FiraGO';
  font-weight: 400;
  text-rendering: geometricPrecision;
  letter-spacing: -0.5px;
  word-wrap: normal;
  box-sizing: border-box;
  outline: none;
  margin-bottom: 0.8rem;
  padding: 0.7rem 0.75rem 0.7rem 0.75rem;
  -webkit-box-shadow: 0px 0px 0px 0px rgba(34, 205, 200, 0.4);
  -moz-box-shadow: 0px 0px 0px 0px rgba(34, 205, 200, 0.4);
  box-shadow: 0px 0px 0px 0px rgba(34, 205, 200, 0.4);
  transition: all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005);
  &:active,
  &:focus {
    -webkit-box-shadow: 0px 0px 0px 2px rgba(34, 205, 200, 0.4);
    -moz-box-shadow: 0px 0px 0px 2px rgba(34, 205, 200, 0.4);
    box-shadow: 0px 0px 0px 2px rgba(34, 205, 200, 0.4);
  }
  &::placeholder {
    font-style: italic;
    font-weight: 300;
    color: ${placeholderTextColor};
    font-size: ${normal};
  }
`;

export const StyledInformationNormalIcon = css`
  background-image: url(${getAsset(informationNormalIcon)});
  background-position: bottom;
  transition: all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005);
  cursor: pointer;
  margin: 0.4rem 0 0 -1rem;
`;

export const StyledSpace = styled.div(
  {
    padding: '0.25rem',
  },
  ({ M }) => ({
    padding: M && '0.5rem',
  }),
  ({ L }) => ({
    padding: L && '1.3rem',
  }),
);
