import FileSaver from 'file-saver';
import { isEmpty, slice, size } from 'lodash';
import { getSafeValue, setSafeValue } from './security';

const headers = [
  'ID',
  'Name',
  'Phone',
  'Email',
  'Address',
  'Order Notes',
  'Start Time',
  'End Time',
  'Service time (min)',
  'Demand',
];

const originalRows = [
  '#1,Ida Redwoods,611234567,,141-133 Darcy Rd Wentworthville NSW 2145 Australia,Please pick up the cooler bags,08:00,13:00,10,3',
  '12345,Thierry Davies,6112345678,,66-70 Badajoz Rd North Ryde NSW 2113 Australia,,08:00,13:00,15,1',
  'order_20,Lennie Travers,6112384847,,66-68 Lakemba St Belmore NSW 2192 Australia,Heavy parcel,08:00,13:00,10,4',
  '#4,Ilayda Bloom,,,2-26 Asquith Ave Rosebery NSW 2018 Australia,,08:00,13:00,10,1',
  'AB12,,61123321,don.harvey@email,81-73 Hawkesbury Rd Westmead NSW 2145 Australia,Leave at doorstep,08:00,13:00,10,2',
  '#6,Mehak Orozco,619999999,,2-18 Delange Rd Putney NSW 2112 Australia,,13:00,18:00,12,1',
  ',Paula Johns,618886655,,207-243 Lyons Rd Russell Lea NSW 2046 Australia,Beware of the dog. Please call before entering the yard,13:00,18:00,10,1',
  ',Taslima Cantrell,61987654,t.cantrell@email,2-8 Wisdom St Annandale NSW 2038 Australia,,13:00,18:00,15,',
  'order-id,Ana Giles,,ana@email,3 Correys Ave Concord NSW 2137 Australia,,13:00,18:00,10,1',
  ',Alexander Battle,619991111,alexthegreat@email,58-74 Rutledge St Eastwood NSW 2122 Australia,,13:00,18:00,10,',
  ',Fynley Garcia,,finley@email,1-9 Palmer St Parramatta NSW 2150 Australia,,13:00,18:00,10,1',
];

const limit = size(originalRows);
const addressFieldIndex = 4;

export function saveCSVTemplate(locations) {
  let rows = originalRows;
  if (!isEmpty(locations)) {
    rows = slice(locations, 0, limit).map((loc, index) => {
      const fields = getSafeValue(originalRows, index).split(',');
      setSafeValue(fields, addressFieldIndex, `"${loc.Address}"`);
      return `${fields.join(',')}\n`;
    });
  }

  const csv = [`${headers.join(',')}\n`, ...rows];
  const blob = new Blob(csv, { type: 'text/csv;charset=utf-8;' });
  const fileName = 'here-tpa-template.csv';
  return FileSaver.saveAs(blob, fileName);
}
