import { useEffect, useState } from 'react';

export function useAutoRefresh(delay) {
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => setRefresh(Date.now()), delay);

    return () => clearInterval(interval);
  }, [delay]);

  return [refresh, setRefresh];
}
