import React, { useState } from 'react';
import styled from '@emotion/styled';
import { useSelector } from 'react-redux';
import { fontSize } from '../../../global/variables';
import withTranslation from '../../../hoc/withTranslation';
import Pagination from './Pagination';
import IdSearchDetail from './IdSearchDetail';
import { getOrdersByActivity } from '../../../utils/OrdersHelpers';

const { small, normal } = fontSize;

const StyledSummary = styled.div`
  margin-bottom: 0.5rem;
`;

export const StyledDetailContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: space-between;
  margin-right: 1rem;
  margin-top: -0.8rem;
  margin-bottom: 2rem;
`;

export const StyledTitle = styled.p`
  text-transform: uppercase;
  margin: 0 0.6rem 0 0;
  font-size: ${small};
  opacity: 0.75;
  display: inline-block;
`;

export const StyledDetailsText = styled.strong`
  font-size: ${normal};
  line-height: 2rem;
  display: block;
`;

const IdSearchDetails = ({
  activities,
  translations: {
    idSearchContainer: {
      sequenceNumber,
      tourIdLabel,
      vehicleIdLabel,
      locationLabel,
      timeLabel,
      unassignedJobTitle,
      reasonTitle,
      jobTypeLabel,
    },
  },
}) => {
  const solution = useSelector(({ solution: stateSolution }) => stateSolution);
  const orders = useSelector((state) => state.orders[solution.show]);

  const [currentItem, setCurrentItem] = useState(0);

  const activity = activities[currentItem];
  const order = getOrdersByActivity(orders, activity)?.[0];

  return (
    <div>
      <Pagination
        numberOfPages={activities.length}
        currentItem={currentItem}
        setCurrentItem={(val) => setCurrentItem(val)}
      />
      {activity?.reasons ? (
        <StyledSummary>
          <IdSearchDetail title={unassignedJobTitle} text={activity?.jobId} />
          <StyledDetailContainer>
            <StyledTitle>{reasonTitle}</StyledTitle>
            {activity?.reasons.map((reason) => (
              <StyledDetailsText>{reason.description}</StyledDetailsText>
            ))}
          </StyledDetailContainer>
        </StyledSummary>
      ) : (
        <StyledSummary>
          <IdSearchDetail title={sequenceNumber} text={activity?.sequenceNo} />
          <IdSearchDetail title={tourIdLabel} text={activity?.typeId} />
          <IdSearchDetail title={vehicleIdLabel} text={activity?.vehicleId} />
          <IdSearchDetail title={jobTypeLabel} text={activity?.type} />
          <IdSearchDetail
            title={locationLabel}
            text={`${activity?.location.lat},${activity?.location.lng}`}
          />
          <IdSearchDetail
            title={timeLabel}
            text={`${activity?.time.arrival} - ${activity?.time.departure}`}
          />
          {order?.Territories && <IdSearchDetail title="Territories" text={order.Territories} />}
          {order?.Skills && <IdSearchDetail title="Skills" text={order.Skills} />}
          {order?.Category && <IdSearchDetail title="Category" text={order.Category} />}
          {order?.Position && <IdSearchDetail title="Position" text={order.Position} />}
          {order?.Priority && <IdSearchDetail title="Priority" text={order.Priority} />}
        </StyledSummary>
      )}
    </div>
  );
};

export default withTranslation(IdSearchDetails);
