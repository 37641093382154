import React from 'react';
import styled from '@emotion/styled';
import { colors, fontSize } from 'global/variables';
import { noop } from 'lodash';
import { getAsset } from 'utils/helpers';

const { black, secondaryTextColor, white } = colors;

const StyledSimpleButton = styled.div`
  width: ${(props) => (props.icon ? '2.5rem' : 'auto')};
  height: ${(props) => (props.smaller ? '2rem' : props.icon ? '2.5rem' : '2.5rem')};
  div {
    background-position: center;
    width: ${(props) => (props.icon ? '100%' : 'auto')};
    height: ${(props) => (props.icon ? '100%' : 'auto')};
    transition: all 0.35s cubic-bezier(0.6, 0.005, 0.32, 1);
  }
  margin-left: ${(props) => (props.icon && !props.noMargin ? '0.25rem' : '0')};
  margin-right: ${(props) => (props.icon && !props.noMargin ? '0.25rem' : '0')};
  line-height: 2.5rem;
  border-radius: 1.8rem;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  background-position: -2px 0;
  padding: 0;
  text-align: center;
  text-decoration: none;
  text-rendering: geometricPrecision;
  transition: ${(props) =>
    props.inverted ? '' : 'all 0.35s cubic-bezier(0.600, 0.005, 0.320, 1.000);'};
  font-size: ${fontSize.medium};
  span {
    font-weight: 500;
    font-size: ${fontSize.medium};
    transition: all 0.35s cubic-bezier(0.6, 0.005, 0.32, 1);
  }
  strong {
    transition: all 0.35s cubic-bezier(0.6, 0.005, 0.32, 1);
    color: ${(props) =>
      props.inverted ? secondaryTextColor : props.disabled ? black : secondaryTextColor};
    font-size: ${fontSize.medium};
  }

  &:hover {
    background-position: right center;
    div {
      opacity: 1;
    }
  }
  strong {
    position: relative;
    top: 1px;
  }
  div {
    background-size: ${(props) => (props.bigger ? '1.4rem' : '1.2rem')};
    opacity: 0.9;
    background-image: ${(props) => (props.icon ? `url(${getAsset(props.icon)})` : '')};
    background-repeat: no-repeat;
    background-clip: border-box;
    height: ${(props) => (props.bigger ? '1.3rem 1.3rem' : '1.2rem 1.2rem')};
    width: ${(props) => (props.bigger ? '1.3rem 1.3rem' : '1.2rem 1.2rem')};
    line-height: 2.5rem;
    margin: 0.1rem auto;
  }
`;

const StyledText = styled.strong({}, ({ disabled }) => ({
  color: disabled ? white : black,
}));

const SimpleButton = ({
  disabled,
  id,
  onClick,
  text,
  bigger,
  icon,
  title,
  smaller = false,
  noMargin = false,
  dataTestId,
}) => (
  <StyledSimpleButton
    title={title}
    id={id}
    onClick={disabled ? noop : onClick}
    icon={icon}
    bigger={bigger}
    disabled={disabled}
    smaller={smaller}
    noMargin={noMargin}
    data-test-id={dataTestId}
  >
    {!icon && <StyledText disabled={disabled}>{text}</StyledText>}
    {icon && <div />}
  </StyledSimpleButton>
);

export default SimpleButton;
