import config from 'config';
import axios from 'axios';
import { flatten, get, isEmpty, slice, uniqBy } from 'lodash';
import { v4 } from 'uuid';
import { accessLogging } from 'actions';
import { getStorageValue, STORAGE_IDS } from './localStorageHelpers';
import { getRandomPointsAroundLocation } from './MapHelpers';
import { getSafeValue, setSafeValue } from './security';

const {
  geocoder: { url: geocoderUrl, autoSuggestUrl, discoverUrl, reverseUrl },
} = config;

const cache = {};

export const getAtLocation = (user, tourPlanner) => {
  const defaultLocation = { lat: 52.530858, lng: 13.384744 };
  let atLocation = (tourPlanner && tourPlanner.location.value) || user.location || defaultLocation;
  if (atLocation.latitude) atLocation = { lat: atLocation.latitude, lng: atLocation.longitude };
  return atLocation;
};

const getAt = (atLocation) => {
  if (atLocation && atLocation.lat && atLocation.lng) return `${atLocation.lat},${atLocation.lng}`;
  const stored = getStorageValue(STORAGE_IDS.tourPlanner);
  const storedLoc = get(stored, 'location.value');
  return storedLoc ? `${storedLoc.lat},${storedLoc.lng}` : null;
};

const getUrl = (baseUrl, query, atLocation, limit = 1, extra, politicalView) => {
  const storedUser = getStorageValue(STORAGE_IDS.userState);
  const lang = storedUser ? storedUser.language : 'en';
  const at = getAt(atLocation);
  let url = `${baseUrl}?limit=${limit}&lang=${lang}`;
  url = query ? `${url}&q=${encodeURIComponent(query)}` : url;
  url = at ? `${url}&at=${at}` : url;
  url = extra ? `${url}&${extra}` : url;
  url = politicalView ? `${url}&politicalView=${politicalView}` : url;
  return url;
};

const serializePosition = ({ lat, lng }) => {
  return `${lat.toFixed(4)} ${lng.toFixed(4)}`;
};

const getUniqueLocations = (locations) => {
  const positionMap = locations.reduce((result, item) => {
    const position = serializePosition(item.access?.[0] ?? item.position);

    if (result.has(position)) {
      return result;
    }

    result.set(position, item);

    return result;
  }, new Map());

  return Array.from(positionMap.values());
};

export const getGeocodeRequestsByAddress = (
  addresses,
  { tokenType, accessToken },
  atLocation,
  politicalView,
) => {
  return addresses.map((query) =>
    getSafeValue(cache, query)
      ? Promise.resolve(getSafeValue(cache, query))
      : axios.get(getUrl(geocoderUrl, query, atLocation, 1, null, politicalView), {
          headers: {
            Authorization: `${tokenType} ${accessToken}`,
          },
        }),
  );
};

export const addToCache = (key, object) => {
  if (!key || !object || isEmpty(object) || !object.data || isEmpty(object.data.items)) return;
  if (!getSafeValue(cache, key)) setSafeValue(cache, key, object);
};

export const getAddressesLocation = (addresses, oAuth, atLocation, politicalView) => {
  const requests = getGeocodeRequestsByAddress(addresses, oAuth, atLocation, politicalView);
  return Promise.all(requests)
    .then((res) => {
      return res.map((response, i) => {
        accessLogging(true, geocoderUrl, 'apiKey', response.status);
        const item = response.data.items[0];
        const label = getSafeValue(addresses, i);
        addToCache(label, { data: response.data, request: response.request });
        return {
          label,
          position: item && (item.access?.[0] || item.position),
        };
      });
    })
    .catch((err) => accessLogging(true, geocoderUrl, 'apiKey', err.response.status));
};

export const getAddressLocation = (address, oAuth, user) =>
  getAddressesLocation([address], oAuth, null, user.politicalView)
    .then((res) => res[0])
    .catch(() => ({ label: address }));

export function getLocationAddress(location, oAuth, user) {
  return axios
    .get(getUrl(reverseUrl, null, location, 1, null, user.politicalView), {
      headers: {
        Authorization: `${oAuth.tokenType} ${oAuth.accessToken}`,
      },
    })
    .then(({ data, status }) => {
      accessLogging(true, reverseUrl, 'accessToken', status);
      return data.items[0];
    })
    .catch((err) => {
      accessLogging(false, reverseUrl, 'accessToken', err.response?.status);
    });
}

export function getBatchLocationAddress(locations, oAuth, politicalView) {
  const requests = locations.map((location) => {
    return axios.get(getUrl(reverseUrl, null, location, 1, null, politicalView), {
      headers: {
        Authorization: `${oAuth.tokenType} ${oAuth.accessToken}`,
      },
    });
  });

  return axios
    .all(requests)
    .then((responses) => {
      responses.forEach((r) => accessLogging(true, reverseUrl, 'accessToken', r.status));
      return responses.map((res) => res.data.items[0].address);
    })
    .catch((err) => accessLogging(false, reverseUrl, 'accessToken', err.response?.status));
}

export const getItems = (
  baseUrl,
  address,
  { tokenType, accessToken },
  user,
  tourPlanner,
  limit,
  extra,
) => {
  const atLocation = getAtLocation(user, tourPlanner);
  const url = getUrl(baseUrl, address, atLocation, limit * 3, extra, user.politicalView);
  return axios
    .get(url, {
      headers: {
        Authorization: `${tokenType} ${accessToken}`,
      },
    })
    .then((res) => {
      accessLogging(true, url, 'accessToken', res.status);
      if (!res || !res.data || isEmpty(res.data.items)) return [];
      const valid = res.data.items.filter((i) => !!i.access?.[0] || !!i.position);
      const unique = uniqBy(valid, (item) => item.title);
      return slice(unique, 0, limit);
    })
    .catch((err) => {
      accessLogging(false, url, 'accessToken', err.response?.status);
    });
};

export const getAutoSuggestItems = (
  address,
  { tokenType, accessToken },
  user,
  tourPlanner,
  limit = 5,
) => {
  if (!address || !accessToken) return { then: () => [] };
  return getItems(
    autoSuggestUrl,
    address,
    { tokenType, accessToken },
    user,
    tourPlanner,
    limit,
    'show=position',
  )
    .then((res) => {
      return res.map((item) => {
        return {
          label: item.title,
          value: { ...(item.access?.[0] || item.position) },
        };
      });
    })
    .catch(() =>
      getAddressesLocation([address], { tokenType, accessToken }, null, user.politicalView),
    );
};

export const getDiscoverItems = (
  address,
  { tokenType, accessToken },
  user,
  tourPlanner,
  limit = 72,
) => {
  const atLocation = getAtLocation(user, tourPlanner);
  const positions = getRandomPointsAroundLocation(atLocation, 300, 13);
  const requests = positions.map((pos) => {
    return axios.get(
      getUrl(
        discoverUrl,
        address,
        pos,
        Math.ceil(limit / positions.length),
        null,
        user.politicalView,
      ),
      {
        headers: {
          Authorization: `${tokenType} ${accessToken}`,
        },
      },
    );
  });

  return axios
    .all(requests)
    .then((responses) => {
      responses.forEach((r) => accessLogging(true, discoverUrl, 'accessToken', r.status));
      const allItems = flatten(responses.map((res) => res.data.items));
      const valid = allItems.filter((i) => !!i.access?.[0] || !!i.position);
      const unique = uniqBy(valid, (item) => item.title);
      return slice(unique, 0, limit);
    })
    .catch((err) => accessLogging(false, discoverUrl, 'accessToken', err.response?.status));
};

export const createIDFromNumber = (number, length = 3) => {
  if (!number) return v4();

  const start = '00000000n';
  const temp = start.replace('n', number);
  return temp.substr(temp.length - length);
};

export const getOrdersFromDiscoverItems = (
  address,
  { tokenType, accessToken },
  user,
  tourPlanner,
) => {
  return getDiscoverItems(address, { tokenType, accessToken }, user, tourPlanner)
    .then((res) => {
      return getUniqueLocations(res).map((item, index) => ({
        Activity: 'delivery',
        Address: item.address.label.replace(item.title, '').replace(', ', ''),
        Name: item.title,
        Latitude: item.access[0].lat,
        Longitude: item.access[0].lng,
        Demand: [1],
        'Service time (min)': 5,
        ID: createIDFromNumber(index + 1),
        InternalID: `demo-${v4()}`,
      }));
    })
    .catch(() => []);
};
