import { isEmpty, isNaN, isString, merge } from 'lodash';
import { CSV_META, CSV_HEADERS, ORDER_ACTIVITIES } from './config';
import config from '../../config/index';
import { alterLocationData, composeOrderField } from '../IngestOrdersHelpers';
import { createIDFromNumber } from '../GeoCoder';
import { getOrderCluster, sortOrdersByComplexAddress } from './clusters';

const { defaults } = config;

const isRecordUnresolved = (geocodedLocations) => (record) =>
  CSV_META.hasNoCoordinates(record) &&
  geocodedLocations.find(({ title }) => title === CSV_META.freeTextAddress(record)) === undefined;

export function mergeRecordsWithGeocodedLocations(records, geocoderResponses) {
  const geocodedLocations = geocoderResponses
    .filter(({ status, response }) => {
      if (!response || status === 'rejected') return false;

      const { items } = response.data;
      return status === 'fulfilled' && items.length > 0;
    })
    .map(({ response }) => {
      const { position } = response.data.items[0];
      const { responseURL } = response.request;
      const title = new URLSearchParams(new URL(responseURL).search.slice(1)).get('q');
      return { title, position };
    });

  const resolvedRecords = records
    .filter((record) => !isRecordUnresolved(geocodedLocations)(record))
    .map((record) => {
      if (CSV_META.hasNoCoordinates(record)) {
        const addressQuery = CSV_META.freeTextAddress(record);
        const {
          position: { lat, lng },
        } = geocodedLocations.find(({ title }) => title === addressQuery);
        return {
          ...record,
          [CSV_HEADERS.latitude.columnName]: lat,
          [CSV_HEADERS.longitude.columnName]: lng,
        };
      }
      return record;
    });

  const unresolvedRecords = records.filter(isRecordUnresolved(geocodedLocations));

  return { resolvedRecords, unresolvedRecords };
}

export function guessDelimiter(data) {
  if (!data) return ',';
  if (data.indexOf(';') === -1) return ',';
  if (data.indexOf(',') === -1) return ';';
  return data.indexOf(',') > data.indexOf(';') ? ';' : ',';
}

export const removeRecordsWithAllEmptyFields = (result) => {
  if (result?.length === 0) return [];
  return result.filter(
    ({ rowIndex, ...rest }) =>
      !Object.values(rest).every((value) => (isString(value) ? isEmpty(value) : isNaN(value))),
  );
};

export function sanitizeOrder(order, customCSVConfig, index, avoidDefaults) {
  const orderDemand = composeOrderField(order, CSV_HEADERS.demand, customCSVConfig);
  const demand = !isNaN(parseInt(orderDemand, 10))
    ? Math.abs(parseInt(orderDemand, 10))
    : !avoidDefaults && defaults.demand;
  const orderServiceTime = composeOrderField(order, CSV_HEADERS.serviceTime, customCSVConfig);
  const serviceTime = !isNaN(parseInt(orderServiceTime, 10))
    ? Math.abs(parseInt(orderServiceTime, 10))
    : !avoidDefaults && defaults.serviceTime / 60;
  const orderLatitude = composeOrderField(order, CSV_HEADERS.latitude, customCSVConfig);
  const orderLongitude = composeOrderField(order, CSV_HEADERS.longitude, customCSVConfig);
  const latitude = !isNaN(parseFloat(orderLatitude)) ? parseFloat(orderLatitude) : '';
  const longitude = !isNaN(parseFloat(orderLongitude)) ? parseFloat(orderLongitude) : '';
  const orderPriority = composeOrderField(order, CSV_HEADERS.priority, customCSVConfig);
  const priority = !isNaN(parseInt(orderPriority, 10))
    ? Math.abs(parseInt(orderPriority, 10))
    : !avoidDefaults && undefined;
  const orderActivity = composeOrderField(order, CSV_HEADERS.activity, customCSVConfig);
  let activity = ORDER_ACTIVITIES.DELIVERY;
  if (orderActivity) {
    const activityStd = orderActivity.toLowerCase();
    activity =
      activityStd === ORDER_ACTIVITIES.PICKUP ? ORDER_ACTIVITIES.PICKUP : ORDER_ACTIVITIES.DELIVERY;
  }

  return merge(order, {
    Name: composeOrderField(order, CSV_HEADERS.name, customCSVConfig),
    Address: composeOrderField(order, CSV_HEADERS.address, customCSVConfig),
    ID: composeOrderField(order, CSV_HEADERS.id, customCSVConfig),
    Phone: composeOrderField(order, CSV_HEADERS.phone, customCSVConfig),
    Email: composeOrderField(order, CSV_HEADERS.email, customCSVConfig),
    Notes: composeOrderField(order, CSV_HEADERS.notes, customCSVConfig),
    Demand: [demand],
    StartTime: composeOrderField(order, CSV_HEADERS.startTime, customCSVConfig),
    EndTime: composeOrderField(order, CSV_HEADERS.endTime, customCSVConfig),
    ServiceTime: serviceTime,
    Latitude: latitude,
    Longitude: longitude,
    InternalID: createIDFromNumber(index, 5),
    ClusterID: getOrderCluster(order),
    Priority: priority,
    Activity: activity,
  });
}

export function sanitizeOrders(orders, customCSVConfig, startIndex) {
  const sanitized = orders.map((order, i) => {
    const newOrder = sanitizeOrder(order, customCSVConfig, startIndex + i);
    return alterLocationData(newOrder);
  });
  const sorted = sortOrdersByComplexAddress(sanitized);
  return sorted.map((order, index) => {
    order.InternalID = createIDFromNumber(startIndex + index, 5);
    return order;
  });
}
