import React, { useCallback, useEffect, useState } from 'react';
import withTranslation from 'hoc/withTranslation';
import styled from '@emotion/styled';
import { isArray } from 'lodash';
import {
  enableObjectiveFunction,
  getDefaultObjectiveFunctions,
  changeObjectivePriority,
  getObjectivesLimit,
} from '../../../utils/solverConfig/config';
import ObjectiveFunctionsPriorityList from './ObjectiveFunctionsPriorityList';
import { StyledContrastPanelColumnsContainer } from '../../Wizard/Global/WizardStyledContrastPanel';
import { getSafeValue } from '../../../utils/security';

const StyledObjectiveFunctionsListContainer = styled.div({
  padding: '1rem 0',
});

const ObjectiveFunctionsList = ({
  objectiveFunctions,
  onChange,
  height,
  isBalanceObjective,
  isBeta,
}) => {
  const [configuration, setConfiguration] = useState(
    isArray(objectiveFunctions) ? objectiveFunctions : getDefaultObjectiveFunctions(isBeta),
  );
  const [dragId, setDragId] = useState();
  const [limitReached, setLimitReached] = useState(false);

  const handleConfigurationChange = useCallback(
    (newObject) => {
      if (onChange) onChange(newObject);
    },
    [onChange],
  );

  useEffect(() => {
    handleConfigurationChange(configuration);
  }, [configuration]);

  const handleOnEnableItem = useCallback(
    (itemIndex) => {
      const newConfig = enableObjectiveFunction(configuration, itemIndex, limitReached);
      const countEnabled = newConfig.reduce((count, item) => count + (item.enabled === true), 0);
      setConfiguration(newConfig);
      setLimitReached(countEnabled >= getObjectivesLimit(isBeta));
    },
    [configuration, isBeta],
  );

  const handleOnChangeInput = useCallback(
    (itemIndex, value) => {
      const newConfig = [...configuration];
      getSafeValue(newConfig, itemIndex).threshold = value;
      setConfiguration(newConfig);
    },
    [configuration],
  );

  const handleOnActionChange = useCallback(
    (itemIndex, value) => {
      const newConfig = [...configuration];
      getSafeValue(newConfig, itemIndex).action = value;
      setConfiguration(newConfig);
    },
    [configuration],
  );

  const handleOnDrag = useCallback((e) => {
    setDragId(e.currentTarget.id);
  });

  const handleOnDrop = useCallback((e) => {
    const dropBox = configuration.find((item) => item.key === e.currentTarget.id);
    const dropBoxOrder = dropBox.order;

    configuration.map((item) => {
      if (item.key === dragId) {
        const newConfiguration = changeObjectivePriority(configuration, item.key, dropBoxOrder);
        setConfiguration(newConfiguration);
      }
      return item;
    });
  });

  return (
    <StyledObjectiveFunctionsListContainer>
      <StyledContrastPanelColumnsContainer height={height}>
        {configuration.map((item, index) =>
          item.balance === isBalanceObjective ? (
            <ObjectiveFunctionsPriorityList
              key={item.key}
              index={index}
              item={item}
              onClick={() => handleOnEnableItem(index)}
              onChange={(i, value) => handleOnChangeInput(i, value)}
              onDrag={handleOnDrag}
              onDrop={handleOnDrop}
              limitReached={limitReached}
              onActionChange={(i, value) => handleOnActionChange(i, value)}
            />
          ) : null,
        )}
      </StyledContrastPanelColumnsContainer>
    </StyledObjectiveFunctionsListContainer>
  );
};

export default withTranslation(ObjectiveFunctionsList);
