export const formatTime = (timeInMins) => () => {
  const time = new Date();
  time.setHours(0);
  time.setMinutes(timeInMins);
  time.setSeconds(0);
  return time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
};

export const formatDuration =
  (minTotal) =>
  ({ time: { hour, minute } }) => {
    const total = Math.round(minTotal);
    const minutes = total % 60;
    const hours = (total - minutes) / 60;
    if (minutes > 0 && hours > 0) {
      return `${hours}${hour} ${minutes}${minute}`;
    }
    if (minutes === 0 && hours === 0) {
      return '--';
    }
    return minutes > 0 ? `${minutes}${minute}` : `${hours}${hour}`;
  };

export const formatPrice =
  (price) =>
  ({ currency } = {}) =>
    `${price.toLocaleString()}${currency}`;

export const formatPriceWithCurrency = (price, currency, maximumFractionDigits = 2) => {
  return new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    maximumFractionDigits,
  }).format(price);
};
