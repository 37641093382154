import { getThemeProp } from 'global/variables';
import { isEqual, size } from 'lodash';
import React, { Component } from 'react';
import { GeoJSON } from 'react-leaflet';
import { getSafeValue } from '../../utils/security';
import { isPedestrianRoute } from '../../utils/map/MapFeatureFilters';
import { getBiggerBbox } from '../../utils/map/MapCalculations';

const animations = getThemeProp('animationsColor');
const animationsWeight = getThemeProp('animationsWeight');

class MapAnimatedTour extends Component {
  isUnmounted = false;
  isTourChanging = false;

  constructor(props) {
    super(props);
    this.interval = 555;
    this.segmentsAmount = 40;
    this.state = { onTour: null, step: 0 };
  }

  componentDidMount = () => {
    this.isUnmounted = false;
    this.isTourChanging = false;

    const time = setInterval(() => {
      this.animateTour(this.props.tour, this.state.step + 1);
    }, this.interval);
    this.setState({ ...this.state, animation: time });
  };

  UNSAFE_componentWillReceiveProps({ tour: nextTour = {} }) {
    if (!isEqual(this.props.tour, nextTour)) {
      this.isTourChanging = true;
      clearInterval(this.state.animation);
      const time = setInterval(() => {
        this.animateTour(nextTour, this.state.step + 1);
      }, this.interval);
      this.setState({ ...this.state, onTour: null, animation: time }, () => {
        this.isTourChanging = false;
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return !isEqual(nextState, this.state);
  }

  componentWillUnmount = () => {
    this.isUnmounted = true;
    clearInterval(this.state.animation);
  };

  getBoundingBoxAndCoordinates = (tours, id) => {
    let bbox = [];
    let coordinates = [];

    tours.features.forEach((feature) => {
      if (feature.properties.routeId === id && !isPedestrianRoute(feature)) {
        bbox = bbox.length === 0 ? feature.bbox : getBiggerBbox(bbox, feature.bbox);
        coordinates = coordinates.concat(feature.geometry.coordinates);
      }
    });

    return {
      bbox,
      coordinates,
    };
  };

  getDynamicTour = (tours, startPos = 0) => {
    if (!tours) {
      return null;
    }

    const features = [];
    const { selectedTour, routeIds } = this.props.display;
    const routes = selectedTour !== undefined ? [selectedTour] : routeIds;
    routes.forEach((id) => {
      const { bbox, coordinates } = this.getBoundingBoxAndCoordinates(tours, id);

      if (bbox && coordinates) {
        const coordinatesSize = size(coordinates);
        const laps = Math.round(coordinatesSize / this.segmentsAmount);
        const amount = Math.round(coordinatesSize / laps);
        for (let lap = 0; lap < laps; lap++) {
          for (let segment = 0; segment < size(animations); segment++) {
            const start = lap * amount + segment + startPos;
            const geo = {
              bbox,
              geometry: {
                coordinates: coordinates.slice(start, start + 2),
                type: 'LineString',
              },
              type: 'Feature',
              properties: { level: segment },
            };
            features.push(geo);
          }
        }
      }
    });

    return {
      type: 'FeatureCollection',
      features,
    };
  };

  animateTour = (tours, startPos) => {
    const step = startPos > this.segmentsAmount ? 0 : startPos;
    const onTour = this.getDynamicTour(tours, step);
    if (!this.isUnmounted && !this.isTourChanging) {
      this.setState({ ...this.state, onTour, step });
    }
  };

  featureStyle = (feature) => ({
    color: getSafeValue(animations, feature.properties.level),
    weight: animationsWeight,
  });

  render() {
    if (!this.state.onTour) {
      return <></>;
    }

    return (
      <GeoJSON
        key={`ontour-${this.state.step}`}
        data={this.state.onTour}
        style={this.featureStyle.bind(this)} // eslint-disable-line react/jsx-no-bind
      />
    );
  }
}

export default MapAnimatedTour;
