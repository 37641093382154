import axios from 'axios';
import config from 'config';
import { flatten, isEmpty, size } from 'lodash';
import { sprintf, isProdEnv } from 'utils/helpers';
import OAuthHelpers from 'utils/OAuthHelpers';
import { CSV_META } from 'utils/csv/config';
import parseCSV from 'utils/csv/parser';
import { CSVProcessingError, NoJobsFoundError, ValidationError } from 'utils/csv/errors';
import {
  mergeRecordsWithGeocodedLocations,
  removeRecordsWithAllEmptyFields,
  sanitizeOrders,
} from 'utils/csv/helper';
import { RequestStatus } from 'components/FloatingPanels/Tours/Tour';
import { clearAll } from 'utils/apiFilesUploadHelpers';
import { shouldEnableAccessLogging } from 'utils/MemoryHelpers';
import { addToCache, getGeocodeRequestsByAddress } from '../utils/GeoCoder';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../utils/amplitude';
import { APP_MODES, getAppMode } from '../utils/urlHelpers';
import { getSafeValue } from '../utils/security';
import { createStopsNoLocation, getNumberOfJobs } from '../utils/SolutionHelpers';

const appMode = getAppMode();
const {
  api: { betaUrl: apiBetaUrl, prodUrl: apiProdUrl },
  routing: { url: routingUrl },
  oExe: { url: oAuthUrl },
  terra: { url: territoryApi },
  maxValues,
  limits: { vehicleTypesLimit, jobsLimit },
  geocoder: { url: geocoderUrl },
} = config;

const maxOrders = appMode.includes(APP_MODES.DEVELOPER) ? 1000000 : maxValues.orders;

export const RETURN_LOCATION_MODE = {
  START: 'start',
  DISTINCT: 'distinct',
  END: 'end',
};

export const FLEET_PROFILES = {
  SCOOTER: 'scooter',
  BICYCLE: 'bicycle',
  PEDESTRIAN: 'pedestrian',
  CAR: 'car',
  TRUCK: 'truck',
  BUS: 'bus',
  PRIVATE_BUS: 'privateBus',
};

export const USAGE_EVENTS = {
  ORDERS_ADD: 'ORDERS_ADD',
  WIZARD_SET_STEP: 'WIZARD_SET_STEP',
  FEEDBACK_ADD: 'FEEDBACK_ADD',
};

export const AREA_CATEGORY = {
  POSTAL: 'postal',
  DISTRICT: 'district',
  CITY: 'city',
  COUNTY: 'county',
  STATE: 'state',
  COUNTRY: 'country',
};

export const TIMEOUT_VALUES = {
  LOW: 300,
  MEDIUM: 500,
  HIGH: 1000,
};

export const GET_SOLUTION = 'GET_SOLUTION';
export const CLEAR_SOLUTION = 'CLEAR_SOLUTION';
export const DELETE_SOLUTION = 'DELETE_SOLUTION';
export const SET_MAP_DATA = 'SET_MAP_DATA';
export const CLEAR_MAP_DATA = 'CLEAR_MAP_DATA';
export const SELECT_TOUR_BY_ID = 'SELECT_TOUR_BY_ID';
export const SELECT_UNASSIGNED = 'SELECT_UNASSIGNED';
export const SET_SHOW_PROBLEM = 'SET_SHOW_PROBLEM';
export const SHARE_TOUR_BY_ID = 'SHARE_TOUR_BY_ID';
export const SET_PROBLEM = 'SET_PROBLEM';
export const SET_PROBLEM_PARAMETER = 'SET_PROBLEM_PARAMETER';
export const SET_PROBLEM_JSON_PARAM = 'SET_PROBLEM_JSON_PARAM';
export const GET_ROUTING_TOUR = 'GET_ROUTING_TOUR';
export const SET_ERROR = 'SET_ERROR';
export const SET_LOADER = 'SET_LOADER';
export const SET_TOUR_PARAMETER = 'SET_TOUR_PARAMETER';
export const CLEAR_TOUR_PARAMETER = 'CLEAR_TOUR_PARAMETER';
export const GET_OAUTH = 'GET_OAUTH';
export const SET_ORDERS = 'SET_ORDERS';
export const SET_ORDERS_DEMO = 'SET_ORDERS_DEMO';
export const SET_ORDERS_NOT_LOCATED = 'SET_ORDERS_NOT_LOCATED';
export const UPDATE_ORDER = 'UPDATE_ORDER';
export const CLEAR_ORDERS = 'CLEAR_ORDERS';
export const CLEAR_ORDERS_NOT_LOCATED = 'CLEAR_ORDERS_NOT_LOCATED';
export const USER_SET_PARAM = 'USER_SET_PARAM';
export const USER_SET_COOKIES = 'USER_SET_COOKIES';
export const USER_DISPLAY_COOKIES = 'USER_DISPLAY_COOKIES';
export const USER_INCREASE_USAGE = 'USER_INCREASE_USAGE';
export const RECORD_USAGE_EVENT = 'RECORD_USAGE_EVENT';
export const SET_UPLOADED_FILE = 'SET_UPLOADED_FILE';
export const DELETE_UPLOADED_FILE = 'DELETE_UPLOADED_FILE';
export const SET_UPLOADED_IMAGE = 'SET_UPLOADED_IMAGE';
export const SET_TERRITORY_PARAMETER = 'SET_TERRITORY_PARAMETER';
export const GET_AREAS = 'GET_AREAS';
export const SET_TERRITORIES_FROM_PROBLEM_FILE = 'SET_TERRITORIES_FROM_PROBLEM_FILE';
export const CLEAR_VEHICLE_PROFILES = 'CLEAR_VEHICLE_PROFILES';
export const CLEAR_LOCATIONS = 'CLEAR_LOCATIONS';
export const SET_SHOW = 'SET_SHOW';
export const SOLUTION_SET_PARAM = 'SOLUTION_SET_PARAM';
export const SET_JSON = 'SET_JSON';
export const UNASSIGNED_REASONS = ['REACHABLE_CONSTRAINT', 'UNREACHABLE_IN_RELATION_CONSTRAINT'];

export function recordUsageEvent(param) {
  return {
    type: RECORD_USAGE_EVENT,
    payload: param,
  };
}

export function clearSolution(payload) {
  return {
    type: CLEAR_SOLUTION,
    payload,
  };
}

export function deleteSolution(index) {
  return {
    type: DELETE_SOLUTION,
    payload: index,
  };
}

export function clearMapData() {
  return {
    type: CLEAR_MAP_DATA,
    payload: null,
  };
}

export function setMapData(solution) {
  return {
    type: SET_MAP_DATA,
    payload: solution,
  };
}

export function selectTourById(value) {
  return {
    type: SELECT_TOUR_BY_ID,
    payload: value,
  };
}

export function selectUnassigned(value) {
  return {
    type: SELECT_UNASSIGNED,
    payload: value,
  };
}

export function setShowProblem(value) {
  return {
    type: SET_SHOW_PROBLEM,
    payload: value,
  };
}

export function shareTourById(value) {
  return {
    type: SHARE_TOUR_BY_ID,
    payload: value,
  };
}

export function setProblem(problem) {
  return {
    type: SET_PROBLEM,
    payload: problem,
  };
}
export function setProblemParameter(param) {
  return {
    type: SET_PROBLEM_PARAMETER,
    payload: param,
  };
}

export function setProblemJsonParam(param) {
  return {
    type: SET_PROBLEM_JSON_PARAM,
    payload: param,
  };
}

export function setSolution(payload) {
  let stopsNoLocation = payload.stopsNoLocation;
  if (!payload.error && !stopsNoLocation) {
    const solution = payload.solution?.data || payload.data;
    stopsNoLocation = createStopsNoLocation(solution.tours);
  }

  return {
    type: GET_SOLUTION,
    payload: stopsNoLocation ? { ...payload, stopsNoLocation } : payload,
  };
}

export function setShowSolution(param) {
  return {
    type: SET_SHOW,
    payload: param,
  };
}

export function setSolutionJson(param) {
  return {
    type: SET_JSON,
    payload: param,
  };
}

export function setSolutionParam(param) {
  return {
    type: SOLUTION_SET_PARAM,
    payload: param,
  };
}

export function setError(e) {
  return {
    type: SET_ERROR,
    payload: e,
  };
}

export const setLoader = (data) => ({
  type: SET_LOADER,
  payload: data,
});

export function setOrders(orders, isDemo, index) {
  const type = isDemo ? SET_ORDERS_DEMO : SET_ORDERS;
  return {
    type,
    payload: orders,
    index,
  };
}

export function clearOrders(payload) {
  return { type: CLEAR_ORDERS, payload };
}

export function updateOrder(order, index, orderIdx) {
  return {
    type: UPDATE_ORDER,
    payload: order,
    orderIdx,
    index,
  };
}

export function setOrdersNotLocated(orders) {
  return {
    type: SET_ORDERS_NOT_LOCATED,
    payload: orders,
  };
}

export function clearOrdersNotLocated() {
  return { type: CLEAR_ORDERS_NOT_LOCATED };
}

export const setTourParameter = (param) => ({
  type: SET_TOUR_PARAMETER,
  payload: param,
});

export const clearTourParameter = (param, clearIndex) => ({
  type: CLEAR_TOUR_PARAMETER,
  payload: { param, clearIndex },
});

export const onSetShowSolution = (index) => (dispatch) => {
  dispatch(setShowSolution(index));
  dispatch(clearMapData());
};

export const accessLogging = (success, url, type, code, isTest, extra) => {
  if (!shouldEnableAccessLogging() && !isTest) return;
  const accessContent = success ? 'successfully accessed' : 'error accessing';

  console.log(
    `${window.location.host} ${accessContent} ${url} (${type}${code ? `-${code}` : ''}${
      extra ? `-${extra}` : ''
    })`,
  );
};

export const getSolution = ({ href, oAuth = null, apiKey = null, index, dispatch, keepOrders }) => {
  if (apiKey) {
    return axios
      .get(`${href}?apiKey=${apiKey}`)
      .then((solution) => {
        accessLogging(true, href, 'apiKey', solution.status);
        dispatch(setSolution({ index, solution, keepOrders }));
        dispatch(setLoader({ isLoading: false, message: RequestStatus.SUCCESS }));
      })
      .catch((err) => {
        accessLogging(false, href, 'apiKey', err.response?.status);
        const errorData = err.response?.data ?? err.response;
        dispatch(setSolution({ error: true, errorData }));
      });
  }
  return axios
    .get(href, {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${oAuth.accessToken}`,
        'Content-Type': 'application/json',
      },
    })
    .then((solution) => {
      accessLogging(true, href, 'accessToken', solution.status);
      dispatch(setSolution({ index, solution, keepOrders }));
      dispatch(setLoader({ isLoading: false, message: RequestStatus.SUCCESS }));
    })
    .catch((err) => {
      accessLogging(false, href, 'accessToken', err.response?.status);
      const errorData = err.response?.data ?? err.response;
      dispatch(setSolution({ error: true, errorData }));
    });
};

export const checkForSolution = ({
  href,
  oAuth = null,
  apiKey = null,
  dispatch,
  index,
  status,
  isActive,
  keepOrders,
  isDemo,
}) => {
  if (isActive) dispatch(setLoader({ isLoading: true, message: status || RequestStatus.PENDING }));
  if (apiKey && !isDemo) {
    axios.get(`${href}?apiKey=${apiKey}`).then((res) => {
      if (isActive) dispatch(setLoader({ isLoading: true, message: res.data.status }));
      dispatch(setSolutionParam({ index, param: { status: res.data.status } }));
      if (res.data.status === RequestStatus.CANCELED) {
        dispatch(deleteSolution({ index: [index] }));
      }

      if (res.data.resource) {
        dispatch(setSolutionParam({ index, param: { solutionHref: res.data.resource.href } }));
        getSolution({ href: res.data.resource.href, apiKey, index, dispatch, keepOrders });
      }
    });
  } else {
    axios
      .get(href, {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${oAuth.accessToken}`,
          'Content-Type': 'application/json',
        },
      })
      .then((res) => {
        if (isActive) dispatch(setLoader({ isLoading: true, message: res.data.status }));
        dispatch(setSolutionParam({ index, param: { status: res.data.status } }));
        if (res.data.status === RequestStatus.CANCELED) {
          dispatch(deleteSolution({ index: [index] }));
        }

        if (res.data.resource) {
          dispatch(
            setSolutionParam({
              index,
              param: {
                solutionHref: res.data.resource.href,
              },
            }),
          );
          getSolution({
            href: res.data.resource.href,
            oAuth,
            index,
            dispatch,
            keepOrders,
          });
        }
      })
      .catch((err) => {
        const errorData = err.response?.data ?? err.response;
        dispatch(setSolution({ error: true, errorData }));
      });
  }
};

export const getStatusHrefAsync = async ({
  apiUrl,
  endpoint,
  toUpload,
  oAuth = null,
  apiKey = null,
}) => {
  return new Promise((resolve, reject) => {
    if (apiKey) {
      axios
        .post(`${apiUrl}${endpoint}?apiKey=${apiKey}`, toUpload)
        .then((res) => {
          accessLogging(true, endpoint, 'apiKey', res.status);
          resolve({ statusHref: res.data.href, statusId: res.data.statusId });
        })
        .catch((err) => {
          accessLogging(false, endpoint, 'apiKey', err.response?.status);
          reject(err);
        });
    } else {
      const requestConfig = {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${oAuth.accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      axios
        .post(`${apiUrl}${endpoint}`, toUpload, requestConfig)
        .then((res) => {
          const correlationID = res.headers['x-correlation-id'] || null;
          accessLogging(true, endpoint, 'accessToken', res.status);
          resolve({ statusHref: res.data.href, statusId: res.data.statusId, correlationID });
        })
        .catch((err) => {
          accessLogging(false, endpoint, 'accessToken', err.response?.status);
          reject(err);
        });
    }
  });
};

export const getUseAsync = (reqType, taskCount, vehicleCount) => {
  return (
    reqType === 'async' ||
    (reqType === 'automatic' && (taskCount > jobsLimit || vehicleCount > vehicleTypesLimit))
  );
};

export const submitProblem = (problem) => (dispatch) => {
  dispatch(setLoader({ isLoading: true }));

  const tpaVersion = problem.user.tpaVersion;
  const toUpload = problem.json;
  const apiUrl =
    tpaVersion.version === 'beta'
      ? apiBetaUrl
      : tpaVersion.version === 'prod'
      ? apiProdUrl
      : tpaVersion.url;
  const requestType = problem.user.requestType;
  const useAsync = getUseAsync(
    requestType,
    getNumberOfJobs(problem.json.plan.jobs),
    problem.json.fleet.types.length,
  );

  const endpoint = useAsync ? '/problems/async' : '/problems';

  if (
    !isProdEnv() ||
    !problem.apiKey ||
    problem.apiKey === '' ||
    problem.user.isLastOrdersAddedDemo
  ) {
    const requestConfig = {
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${problem.oAuth.accessToken}`,
        'Content-Type': 'application/json',
      },
    };

    if (useAsync) {
      getStatusHrefAsync({ apiUrl, endpoint, toUpload, oAuth: problem.oAuth })
        .then((res) => {
          accessLogging(true, endpoint, 'accessToken', res.status);
          dispatch(setSolutionParam({ param: res, index: problem.index }));
        })
        .catch((err) => {
          accessLogging(false, endpoint, 'accessToken', err.response?.status);
          const errorData = err.response?.data ?? err.response;
          dispatch(setSolution({ error: true, errorData, index: problem.index }));
          dispatch(setSolutionJson({ jsonPending: true }));
        });
    } else {
      const start = Date.now();
      axios
        .post(`${apiUrl}${endpoint}`, toUpload, requestConfig)
        .then((res) => {
          accessLogging(true, endpoint, 'accessToken', res.status);
          dispatch(
            setSolution({
              ...res,
              index: problem.index,
              solution: res,
              keepOrders: problem.keepOrders,
            }),
          );
          dispatch(
            setSolutionParam({ param: { runtime: Date.now() - start }, index: problem.index }),
          );
        })
        .catch((err) => {
          accessLogging(false, endpoint, 'accessToken', err.response?.status);
          const errorData = err.response?.data ?? err.response;
          dispatch(
            setSolution({
              error: true,
              errorData,
              index: problem.index,
              isDemoOrders: problem.user.isLastOrdersAddedDemo,
            }),
          );
          dispatch(setSolutionJson({ jsonPending: true }));
          dispatch(setLoader({ isLoading: false }));
        });
    }
  } else {
    const apiKey = problem.apiKey;
    if (useAsync) {
      getStatusHrefAsync({ apiUrl, endpoint, toUpload, apiKey })
        .then((res) => {
          accessLogging(true, endpoint, 'apiKey', res.status);
          dispatch(setSolutionParam({ param: res, index: problem.index }));
        })
        .catch((err) => {
          accessLogging(false, endpoint, 'apiKey', err.response?.status);
          const errorData = err.response?.data ?? err.response;
          dispatch(setSolution({ error: true, errorData }));
        });
    } else {
      const start = Date.now();
      axios
        .post(`${apiUrl}${endpoint}?apiKey=${apiKey}`, toUpload)
        .then((res) => {
          accessLogging(true, endpoint, 'apiKey', res.status);
          dispatch(setSolution({ ...res, index: problem.index, keepOrders: problem.keepOrders }));
          dispatch(
            setSolutionParam({
              param: { runtime: Date.now() - start },
              index: problem.index,
            }),
          );
        })
        .catch((err) => {
          accessLogging(false, endpoint, 'apiKey', err.response?.status);
          const errorData = err.response?.data ?? err.response;
          dispatch(setSolution({ error: true, errorData }));
          dispatch(setLoader({ isLoading: false }));
        });
    }
  }
};

export async function performRoutingRequest(data) {
  const authHeaders = data.oAuth && {
    headers: {
      Authorization: `Bearer ${data.oAuth.accessToken}`,
    },
  };

  const requests = flatten(
    data.routingRequest.requests.map(({ request, stopsRequest }) => {
      return request.flatMap((req) => {
        const params = Object.keys(req).map((key) => `${key}=${getSafeValue(req, key)}`);
        const url = `${routingUrl}?${params.join('&')}`;
        const response = axios.get(url, authHeaders);

        return { response, stopsRequest };
      });
    }),
  );

  const responses = await axios.all(requests.map((req) => req.response));

  const filteredResponses = flatten(responses).filter(
    (f) => !f.data.notices?.some((n) => n.code === 'noRouteFound'),
  );

  const responsesWithStopsRequest = filteredResponses.map((response, index) => ({
    response: response.data,
    stopsRequest: requests[index].stopsRequest,
  }));
  const hasStopPolylines = data.routingRequest.stopPolylines.polylines.length !== 0;

  return {
    routingResponse: responsesWithStopsRequest,
    stopPolylines: hasStopPolylines
      ? { ...data.routingRequest.stopPolylines, stopsRequest: true }
      : undefined,
    tourId: data.tourId,
    solutionId: data.solutionId,
  };
}

export const parseAndProcessCSVFile =
  (file, errorTranslations, orders, mode, customConfig, index) => (dispatch, getState) => {
    dispatch(setError());
    dispatch(setLoader({ isLoading: true }));

    return new Promise((resolve, reject) => {
      parseCSV(file)
        .then((res) => {
          if (res.length === 0) throw new NoJobsFoundError();

          AmplitudeService.log(AMPLITUDE_EVENTS.ORDERS_ADD, {
            addOrdersMode: mode,
            addOrdersAmount: size(res),
            addOrdersHeaders: Object.keys(res[0]).join(','),
          });

          const filteredCSVArray = removeRecordsWithAllEmptyFields(res);
          if (size(filteredCSVArray) > maxOrders)
            throw new ValidationError('maxJobsFound', { maxOrderAmount: maxOrders });

          const recordsWithNoCoordinates = filteredCSVArray.filter(CSV_META.hasNoCoordinates);
          if (recordsWithNoCoordinates.length === 0) {
            return Promise.resolve([res]);
          }
          const { oAuth, user } = getState();
          const uniqueAddresses = [
            ...new Set(recordsWithNoCoordinates.map(CSV_META.freeTextAddress)),
          ];
          const geoRequests = getGeocodeRequestsByAddress(
            uniqueAddresses,
            oAuth,
            null,
            user.politicalView,
          );
          dispatch(setLoader({ isLoading: true }));

          return Promise.all([
            Promise.resolve(res),
            Promise.all(
              geoRequests.map((promise, i) =>
                promise.then(
                  (response) => {
                    accessLogging(true, geocoderUrl, 'apiKey', response.status);
                    addToCache(getSafeValue(uniqueAddresses, i), {
                      data: response.data,
                      request: response.request,
                    });
                    return { response, status: 'fulfilled' };
                  },
                  (error) => {
                    accessLogging(false, geocoderUrl, 'apiKey', error.response.status);
                    return { error, status: 'rejected' };
                  },
                ),
              ),
            ),
          ]);
        })
        .catch((error) => Promise.all([Promise.reject(error)]))
        .then((data) => {
          let readyData = null;
          if (data.length === 1) {
            readyData = data[0];
          } else {
            const { resolvedRecords, unresolvedRecords } = mergeRecordsWithGeocodedLocations(
              ...data,
            );
            const unresolvedWithAddress = unresolvedRecords.filter(CSV_META.freeTextAddress);

            if (resolvedRecords.length === 0 || !isEmpty(unresolvedWithAddress)) {
              AmplitudeService.log(AMPLITUDE_EVENTS.ORDERS_ADD_NOT_LOCATED, {
                action: 'open',
                addOrdersMode: mode,
                addOrdersAmountNotLocated: size(unresolvedRecords),
              });
              if (mode === 'upload' || mode === 'drop') {
                dispatch(setOrdersNotLocated(unresolvedRecords));
              } else if (mode === 'demo') {
                dispatch(setError(errorTranslations.ordersFromUrlNotFoundDemo));
              }
            }
            readyData = resolvedRecords;
          }

          const toSet = sanitizeOrders(readyData, customConfig || null, orders.length + 1);
          if (orders.length + toSet.length > maxOrders) {
            dispatch(
              setError(sprintf(errorTranslations.maxJobsFound, { maxOrderAmount: maxOrders })),
            );
            return;
          }

          dispatch(setTourParameter({ fileType: 'csv' }));
          dispatch(recordUsageEvent({ event: USAGE_EVENTS.ORDERS_ADD, mode }));
          dispatch(setOrders(toSet, false, index));
          dispatch(setLoader({ isLoading: false }));
          resolve();
        })
        .catch((error) => {
          dispatch(setLoader({ isLoading: false }));
          dispatch(clearMapData());
          dispatch(clearSolution());
          if (error instanceof CSVProcessingError) {
            dispatch(setError(error.getPayload(errorTranslations)));
            reject();
          }
          dispatch(setError(errorTranslations.invalidFileFormat));
          reject();
        });
    });
  };

export const setOrdersFromUrl = (url, errorTranslations, orders, mode) => (dispatch) => {
  const safeUrl = mode !== 'demo' ? `https://cors-anywhere.herokuapp.com/${url}` : url;
  axios
    .get(safeUrl, { headers: { Accept: 'text/csv' } })
    .then((res) => {
      accessLogging(true, safeUrl, 'accessToke', res.status);
      dispatch(parseAndProcessCSVFile(res.data, errorTranslations, orders, mode));
    })
    .catch((e) => {
      accessLogging(false, safeUrl, 'accessToke', e.response?.status);
      const err =
        mode === 'demo'
          ? errorTranslations.ordersFromUrlNotFoundDemo
          : errorTranslations.ordersFromUrlNotFound;
      dispatch(setError(err));
    });
};

export const cancelSolution =
  (tpaVersion, apiKey, oAuth, request, index, solution) => (dispatch) => {
    if (solution.json) {
      dispatch(setSolutionJson({ json: false }));
      clearAll(dispatch, true, false, index);
    }
    dispatch(setSolutionParam({ param: { status: RequestStatus.CANCELING } }));
    dispatch(setLoader({ isLoading: true, message: 'isCanceling' }));

    const endpoint = `/problems/${request.statusId}/cancel`;
    const apiUrl =
      tpaVersion.version === 'beta'
        ? apiBetaUrl
        : tpaVersion.version === 'prod'
        ? apiProdUrl
        : tpaVersion.url;

    if (apiKey) {
      axios
        .put(`${apiUrl}${endpoint}?apiKey=${apiKey}`)
        .then((res) => {
          accessLogging(true, endpoint, 'apiKey', res.status);
        })
        .catch((err) => {
          accessLogging(false, endpoint, 'apiKey', err.response?.status);
          dispatch(setSolution({ error: true, errorData: err.response?.data }));
          dispatch(setLoader({ isLoading: false, message: err }));
        })
        .finally(() => {
          clearAll(dispatch, true, false, solution.show);
          dispatch(setLoader({ isLoading: false, message: 'canceled' }));
        });
    } else {
      const requestConfig = {
        headers: {
          Accept: 'application/json',
          Authorization: `Bearer ${oAuth.accessToken}`,
          'Content-Type': 'application/json',
        },
      };
      axios
        .put(`${apiUrl}${endpoint}`, null, requestConfig)
        .then((res) => {
          accessLogging(true, endpoint, 'accessToken', res.status);
          clearAll(dispatch, true, false, solution.show);
          dispatch(setLoader({ isLoading: false, message: 'canceled' }));
        })
        .catch((err) => {
          accessLogging(false, endpoint, 'accessToken', err.response?.status);
          dispatch(setSolution({ error: true, errorData: err.response?.data }));
          dispatch(setLoader({ isLoading: false, message: err }));
        });
    }
  };

export const getRoutingTourInfo = (routingRequest) => async (dispatch, getState) => {
  if (!routingRequest.routingRequest) return null;
  const state = getState().routingTour;
  const tourId = routingRequest.tourId;
  const solutionId = routingRequest.solutionId;
  const hasToken = routingRequest.oAuth?.accessToken;
  const routeExists =
    state &&
    Object.keys(state).some((key) => {
      const routingTour = getSafeValue(state, key);
      return routingTour.tourId === tourId && routingTour.solutionId === solutionId;
    });
  if (routeExists || !hasToken) return null;

  return dispatch({
    type: GET_ROUTING_TOUR,
    payload: performRoutingRequest(routingRequest),
  });
};

export async function getTerritoryPlanningAreas(areaRequest) {
  const areaURL = `${territoryApi}?bbox=${areaRequest.bbox}&categories=${areaRequest.areaCategory}`;
  const payload = await axios.get(areaURL, {
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${areaRequest.oAuth.accessToken}`,
      'Content-Type': 'application/json',
    },
  });

  payload
    .then((res) => accessLogging(true, areaURL, 'accessToken', res.status))
    .catch((err) => accessLogging(false, areaURL, 'accessToken', err.response?.status));

  const items = payload.data.items;
  const requests = items.map((i) => {
    const detailsUrl = `${territoryApi}/${i}`;
    const req = axios.get(detailsUrl, {
      headers: {
        Authorization: `${areaRequest.oAuth.tokenType} ${areaRequest.oAuth.accessToken}`,
      },
    });
    return req;
  });
  let areaDetails;
  await axios.all(requests).then((responses) => {
    responses.forEach((r) => accessLogging(true, detailsUrl, 'accessToken', r.status));
    areaDetails = responses.map((r) => r.data);
  });

  const areaData = { areaDetails };
  return {
    type: GET_AREAS,
    payload: areaData,
  };
}

export function getOAuth() {
  const oAuthHeader = OAuthHelpers.createOauthHeader(
    'POST',
    config.oExe.url,
    config.oExe.cla,
    config.oExe.claSe,
  );
  const oAuthBody = { grantType: 'client_credentials', tokenFormat: 'hN' };
  const oAuth = axios.post(oAuthUrl, oAuthBody, {
    headers: {
      Accept: 'application/json',
      Authorization: oAuthHeader,
    },
  });

  oAuth
    .then((res) => accessLogging(true, oAuthUrl, 'accessToken', res.status))
    .catch((err) => accessLogging(false, oAuthUrl, 'accessToken', err.response?.status));

  return {
    type: GET_OAUTH,
    payload: oAuth,
  };
}

export function setUserParam(param) {
  return {
    type: USER_SET_PARAM,
    payload: param,
  };
}

export function setUserCookies(param) {
  return {
    type: USER_SET_COOKIES,
    payload: param,
  };
}

export function setUserCookiesDisplay(param) {
  return {
    type: USER_DISPLAY_COOKIES,
    payload: param,
  };
}

export function increaseUserUsage(param) {
  return {
    type: USER_INCREASE_USAGE,
    payload: param,
  };
}

export function setUploadedFile(info) {
  return {
    type: SET_UPLOADED_FILE,
    payload: info,
  };
}

export function deleteUploadedFile() {
  return {
    type: DELETE_UPLOADED_FILE,
  };
}

export function setUploadedImage(info) {
  return {
    type: SET_UPLOADED_IMAGE,
    payload: info,
  };
}

export function setTerritoryParameter(param) {
  return {
    type: SET_TERRITORY_PARAMETER,
    payload: param,
  };
}

export function setTerritoriesFromProblemFile(param) {
  return {
    type: SET_TERRITORIES_FROM_PROBLEM_FILE,
    payload: param,
  };
}

export function clearVehicleProfiles() {
  return { type: CLEAR_VEHICLE_PROFILES };
}

export function clearLocations() {
  return { type: CLEAR_LOCATIONS };
}
