import styled from '@emotion/styled';
import { colors, fontSize } from 'global/variables';
import React from 'react';
import { getAsset } from 'utils/helpers';

const { primaryTextColor, negativeTextColor, secondaryTextColorDarkBg } = colors;
const { small } = fontSize;

const StyledText = styled.p(
  ({ direction }) => ({
    textAlign: 'left',
    marginTop: '0',
    marginBottom: direction === 'vertical' ? '0.25rem' : 0,
    marginRight: direction === 'horizontal' ? '0.55rem' : 0,
    fontSize: small,
    textTransform: 'uppercase',
  }),
  ({ icon }) => ({
    '&:before': icon
      ? {
          display: 'inline-block',
          content: '" "',
          backgroundImage: `url(${getAsset(icon)})`,
          backgroundSize: '1.75rem 1.75rem',
          height: '1.75rem',
          width: '1.75rem',
          verticalAlign: 'middle',
          backgroundRepeat: 'no-repeat',
        }
      : {},
  }),
  ({ error, theme }) => ({
    color: error
      ? negativeTextColor
      : theme === 'contrast'
      ? secondaryTextColorDarkBg
      : primaryTextColor,
    fontWeight: error ? '700' : '400',
  }),
);

const Label = ({ children, icon, id, error, direction = 'vertical', theme = 'default' }) => (
  <StyledText id={id} icon={icon} error={error} direction={direction} theme={theme}>
    {children}
  </StyledText>
);

export default Label;
