import { GeoJSON } from 'react-leaflet';
import React, { useCallback } from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { mapDataKey } from '../../../utils/map/MapCalculations';
import {
  featureFilterCluster,
  filterDepot,
  isActivityToDisplay,
  isRouteToDisplay,
} from '../../../utils/map/MapFeatureFilters';
import { getStyle } from '../../../utils/map/MapUtils';
import {
  CLUSTER_BREAKPOINT_MAP_ZOOM,
  DEFAULT_CLUSTER_RADIUS,
  MIN_CLUSTER_RADIUS,
} from './MapMarkers';

const StopMarkers = ({
  mapData,
  user,
  display,
  routingRoutes,
  highlightStop,
  makeMapMarkers,
  createClusterIcon,
  isSolution,
  routingData,
}) => {
  const showStopClusters = user.mapSettings.showStopClusters;

  const filterMarkersClusterFeatures = useCallback(
    (feature) => {
      return featureFilterCluster(
        feature,
        display,
        routingData,
        user.mapSettings.showStraightRouteLines,
      );
    },
    [display, routingData, user.mapSettings.showStraightRouteLines],
  );
  const clusteredData = useCallback(() => {
    if (!display.showProblem && !user.mapSettings.showStops)
      return mapData.geo.features.filter((f) => f.geometry.type === 'LineString');

    const start = filterDepot(mapData, false);
    return start.filter((f) => !isActivityToDisplay(f) && isRouteToDisplay(f, display));
  }, [isSolution, mapData, display, user.mapSettings.showStops]);

  return (
    <MarkerClusterGroup
      key={`${mapDataKey(mapData, user, display, routingRoutes, showStopClusters)}-stop-cluster`}
      iconCreateFunction={createClusterIcon}
      showCoverageOnHover={false}
      maxClusterRadius={(level) =>
        !showStopClusters
          ? 0
          : level > CLUSTER_BREAKPOINT_MAP_ZOOM
          ? MIN_CLUSTER_RADIUS
          : DEFAULT_CLUSTER_RADIUS
      }
    >
      <GeoJSON
        key={mapDataKey(mapData, user, display, routingRoutes, highlightStop)}
        data={clusteredData()}
        pointToLayer={makeMapMarkers}
        style={getStyle}
        filter={filterMarkersClusterFeatures}
      />
    </MarkerClusterGroup>
  );
};

export default StopMarkers;
