import React from 'react';
import styled from '@emotion/styled';
import { colors, fontSize } from 'global/variables';
import { noop } from 'lodash';
import { getAsset } from 'utils/helpers';
import checkIcon from '../../global/img/chevron-down.svg';

const {
  black,
  primaryColor,
  secondaryColor,
  white,
  primaryColorHovered,
  disabledBackground,
  aqua,
} = colors;

const bgImage = `linear-gradient(
    to right,
    ${secondaryColor} 0%,
    ${primaryColorHovered} 50%,
    ${primaryColorHovered} 100%
  )`;

const StyledButton = styled.div`
  width: ${(props) => (props.icon ? '2.5rem' : 'auto')};
  height: 2.5rem;
  div {
    background-position: center;
    width: ${(props) => (props.icon ? '100%' : 'auto')};
    height: ${(props) => (props.icon ? '100%' : 'auto')};
  }
  margin-left: ${(props) =>
    props.noMargin ? '0' : props.icon ? '.5rem' : props.panel && '0.1rem'};
  margin-right: ${(props) => props.panel && '0.3rem'};
  line-height: 2.5rem;
  border-radius: 1.8rem;
  background-image: ${(props) => (props.inverted || props.disabled ? '' : bgImage)};
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${(props) =>
    props.disabled ? disabledBackground : props.inverted && props.icon ? white : ''};
  background-size: 200% auto;
  background-position: -2px 0;
  padding: 0;
  text-align: center;
  // eslint-disable-next-line no-nested-ternary
  border: ${(props) =>
    props.disabled
      ? 'solid 1px transparent !important'
      : props.checked
      ? `solid 1px ${disabledBackground} !important`
      : props.inverted
      ? `solid 1px ${primaryColorHovered} !important`
      : 'solid 1px transparent !important'};
  box-shadow: ${(props) =>
    props.disabled
      ? ''
      : props.inverted
      ? `inset 0px 0px 0px .3px ${primaryColor} !important`
      : ''};
  text-decoration: none;
  text-rendering: geometricPrecision;
  transition: ${(props) =>
    props.inverted ? '' : 'all 0.35s cubic-bezier(0.600, 0.005, 0.320, 1.000);'};
  font-size: ${fontSize.medium};
  :after {
    content: ' ';
    width: ${(props) => (props.checked ? `0.8rem` : '0')};
    height: ${(props) => (props.checked ? `0.8rem` : '0')};
    background-color: ${aqua};
    position: absolute;
    top: 0.85rem;
    right: -0.55rem;
    border-radius: 0.8rem;
    border: ${(props) => (props.checked ? `1px solid ${aqua}` : 'none')};
    background-size: 0.5rem 0.5rem;
    background-image: ${`url(${getAsset(checkIcon)})`};
    background-repeat: no-repeat;
    background-clip: border-box;
    background-position: center;
  }

  :before {
    content: ' ';
    background-size: 0.5rem 0.5rem;
    padding-left: ${(props) => props.beforeIcon && '1.2rem'};
    background-image: ${(props) => props.beforeIcon && `url(${getAsset(checkIcon)})`};
    background-repeat: no-repeat;
    background-clip: border-box;
    background-position: center;
    background-size: ${(props) => props.beforeIcon && '1rem'};
  }

  span {
    font-weight: 500;
    font-size: ${fontSize.medium};
    transition: all 0.35s cubic-bezier(0.6, 0.005, 0.32, 1);
  }

  &:hover {
    box-shadow: ${(props) =>
      props.disabled
        ? ''
        : props.inverted && !props.checked
        ? `inset 0px 0px 0px 1px ${primaryColor} !important`
        : ''};
    border: ${(props) =>
      props.disabled
        ? 'solid 1px transparent !important'
        : props.checked
        ? `solid 1px ${disabledBackground} !important`
        : props.inverted
        ? `solid 1px ${primaryColor} !important`
        : 'solid 1px transparent !important'};
    background-position: right center;
  }
  div {
    background-size: 1.2rem 1.2rem;
    background-image: ${(props) => (props.icon ? `url(${getAsset(props.icon)})` : '')};
    background-repeat: no-repeat;
    background-clip: border-box;
    height: 1.2rem;
    width: 1.2rem;
    line-height: 2.5rem;
    margin: 0.658rem 0.658rem;
  }
`;

const StyledText = styled.strong({}, ({ contrast, disabled }) => ({
  color: contrast
    ? disabled
      ? 'rgba(255,255,255,0.5)'
      : white
    : disabled
    ? 'rgba(0,0,0,0.5)'
    : black,
  transition: 'all 0.35s cubic-bezier(0.600, 0.005, 0.320, 1.000)',
  fontSize: fontSize.medium,
  position: 'relative',
  top: '1px',
  '&:hover': {
    color: contrast ? white : black,
  },
}));

const Button = ({
  disabled,
  id,
  onClick,
  text,
  inverted,
  icon,
  title,
  checked,
  contrast,
  onMouseEnter,
  onMouseLeave,
  noMargin,
  panel,
  dataTestId,
  beforeIcon,
  onTextClick,
}) => (
  <StyledButton
    title={title}
    id={id}
    onClick={disabled ? noop : onClick}
    inverted={inverted}
    icon={icon}
    disabled={disabled}
    checked={checked}
    panel={panel}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    noMargin={noMargin}
    data-test-id={dataTestId}
    beforeIcon={beforeIcon}
  >
    {!icon && (
      <StyledText
        disabled={disabled}
        contrast={contrast}
        beforeIcon={beforeIcon}
        onClick={disabled ? noop : onTextClick}
      >
        {text}
      </StyledText>
    )}
    {icon && <div />}
  </StyledButton>
);

export default Button;
