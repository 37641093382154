import { getSafeValue } from './security';
import { getVehicleProfile } from './FleetHelpers';
import config from '../config';

const deepLinkMode = {
  bicycle: 'b',
  car: 'd',
  pedestrian: 'w',
  truck: 'tr',
};

const deepLinkAvoid = {
  tollRoad: 'tollroad',
  motorway: 'motorway',
  ferry: 'boatFerry',
  tunnel: 'tunnel',
  dirtRoad: 'dirtRoad',
};

export const getMode = (fleetType) => {
  const mode = getSafeValue(deepLinkMode, fleetType);
  return mode ? `?m=${mode}` : '?m=d';
};

export const getAvoid = (avoidFeatures) => {
  const avoids = avoidFeatures
    .map((feature) => getSafeValue(deepLinkAvoid, feature))
    .filter((avoid) => avoid !== undefined);
  return avoids.length > 0 ? `&a=${avoids.join(',')}` : '';
};

export const getCoordinates = (stops) => {
  const highStopCount = stops.length > 50;
  return stops
    .map((stop) => {
      const lat = stop.location.lat;
      const lng = stop.location.lng;
      return `${!highStopCount ? lat : lat.toFixed(4)},${!highStopCount ? lng : lng.toFixed(4)}`;
    })
    .join('/');
};

export const createNavLink = (tour, tourPlanner) => {
  if (tour.stops.length > 60) return null;

  const baseUrl = config.share.url;
  const vehicleProfile = getVehicleProfile(
    tour.typeId,
    tourPlanner.vehicles,
    tourPlanner.vehicleProfiles,
  );
  const coordinates = getCoordinates(tour.stops);
  const mode = getMode(vehicleProfile?.fleetType);
  const avoid =
    vehicleProfile && vehicleProfile.avoid?.features ? getAvoid(vehicleProfile.avoid.features) : '';

  return `${baseUrl}/${coordinates}${mode}${avoid}`;
};
