import React from 'react';

export const TourIcon = ({ circleColor }) => {
  return (
    <svg
      width="24px"
      height="14px"
      viewBox="0 0 26 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Group-34">
          <polygon
            id="+"
            fillOpacity="0.6"
            fill="#121212"
            fillRule="nonzero"
            transform="translate(22.228823, 6.861177) rotate(-315.000000) translate(-22.228823, -6.861177) "
            points="22.9279751 11.3611775 22.9279751 7.49677071 26.7415344 7.49677071 26.7415344 6.22558427 22.9279751 6.22558427 22.9279751 2.36117749 21.52967 2.36117749 21.52967 6.22558427 17.7161106 6.22558427 17.7161106 7.49677071 21.52967 7.49677071 21.52967 11.3611775"
          />
          <circle id="tour-icon" fill={circleColor} cx="7" cy="7" r="7" />
        </g>
      </g>
    </svg>
  );
};
