import { isEmpty, flatten } from 'lodash';

const getCellText = (data, cell) => {
  if (!cell || !cell.Relationships) return '';

  const el = cell.Relationships.map((relation) => {
    const elements = data.Blocks.filter((block) => relation.Ids.includes(block.Id));
    return elements.map((e) => (e.Text ? `${e.Text}` : undefined));
  });
  return el.join(' ');
};

const getTableCells = (data, table) => {
  const cells = table.Relationships.map((relation) => {
    const elements = data.Blocks.filter((block) => relation.Ids.includes(block.Id));
    const withText = elements.map((e) => ({ ...e, Text: getCellText(data, e) }));
    return withText;
  });

  return flatten(cells);
};

export const getTableDataFromOCR = (data) => {
  if (isEmpty(data) || !data.Blocks) return [];

  const tables = data.Blocks.filter((b) => b.BlockType === 'TABLE');
  return isEmpty(tables) ? [] : tables.map((t) => getTableCells(data, t));
};
