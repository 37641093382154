import { CLEAR_MAP_DATA, SET_MAP_DATA, SET_TOUR_PARAMETER } from 'actions';
import { calculateBBox } from 'utils/GeoJSONConverter';
import { STORAGE_IDS } from '../utils/localStorageHelpers';
import { setStateToMemory } from '../utils/MemoryHelpers';

const defaultState = null;
const stateID = STORAGE_IDS.mapData;

export default (state = defaultState, { type, payload = {} }) => {
  switch (type) {
    case SET_MAP_DATA: {
      const newState = payload || state;
      setStateToMemory(stateID, newState);
      return newState;
    }
    case CLEAR_MAP_DATA: {
      setStateToMemory(stateID, defaultState);
      return defaultState;
    }
    case SET_TOUR_PARAMETER: {
      const locValue = payload.location && payload.location.value;
      const rLocValue = payload.returnLocation && payload.returnLocation.value;
      if ((!locValue && !rLocValue) || !state) return state;

      const {
        geo: { features, ...geo },
        ...restState
      } = state;
      const newFeatures = features.map(
        ({
          properties: { isDepot, isReturnLocation, ...properties },
          geometry: { coordinates, ...geometry },
          ...feature
        }) => ({
          ...feature,
          properties: { ...properties, isDepot, isReturnLocation },
          geometry: {
            ...geometry,
            coordinates:
              isDepot && locValue
                ? [locValue.lng, locValue.lat]
                : isReturnLocation && rLocValue
                ? [rLocValue.lng, rLocValue.lat]
                : coordinates,
          },
        }),
      );

      const newState = {
        ...restState,
        geo: {
          ...geo,
          bbox: calculateBBox(newFeatures.map(({ geometry: { coordinates } }) => coordinates)),
          features: newFeatures,
        },
      };
      setStateToMemory(stateID, newState);
      return newState;
    }
    default:
      return state;
  }
};
