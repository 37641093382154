import { css, Global } from '@emotion/react';
import { colors, fontSize } from 'global/variables';
import createStore from 'store';
import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { getAsset } from 'utils/helpers';

import App from 'App';

import { unregister } from 'registerServiceWorker';

import plus from 'global/img/plus.svg';
import minus from 'global/img/minus.svg';
import delivery from 'global/img/delivery.svg';
import deliveryPickup from 'global/img/delivery-pickup.svg';
import deliveryHighPriorityPickup from 'global/img/delivery-high-priority-pickup.svg';
import deliveryHighPriority from 'global/img/delivery-high-priority.svg';
import deliveryEditing from 'global/img/delivery-ghost.svg';
import returnLocation from 'global/img/return-location-marker.svg';

import fontRegular from 'global/fonts/FiraGO-Regular.woff';
import fontRegularTwo from 'global/fonts/FiraGO-Regular.woff2';
import fontLight from 'global/fonts/FiraGO-Light.woff';
import fontLightTwo from 'global/fonts/FiraGO-Light.woff2';
import fontLightItalic from 'global/fonts/FiraGO-LightItalic.woff';
import fontLightItalicTwo from 'global/fonts/FiraGO-LightItalic.woff2';
import fontMedium from 'global/fonts/FiraGO-Medium.woff';
import fontMediumTwo from 'global/fonts/FiraGO-Medium.woff2';
import fontBold from 'global/fonts/FiraGO-Bold.woff';
import fontBoldTwo from 'global/fonts/FiraGO-Bold.woff2';
import fontPassword from 'global/fonts/password.woff';

const {
  thirdTextColor,
  primaryTextColor,
  secondaryTextColor,
  secondaryTextColorDarkBg,
  black,
  yellow,
  aqua,
} = colors;
const { big, small, medium, smaller } = fontSize;

const globalStyles = css`
  @-webkit-keyframes EntryAnimationNotificationIcon {
    0% {
      background-size: 1rem;
      opacity: 0;
    }
    100% {
      background-size: 1.4rem;
      opacity: 1;
    }
  }

  @-webkit-keyframes EntryAnimation {
    0% {
      height: 0;
      opacity: 0;
    }
    100% {
      height: 18px;
      opacity: 1;
    }
  }

  @-webkit-keyframes EntryAnimationHeader {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  @-webkit-keyframes Pulse {
    0% {
      box-shadow: 0px 0px 0px 2px rgb(50 59 71), 0px 0px 11px 4px rgba(43, 218, 205, 1);
    }
    50% {
      box-shadow: 0px 0px 0px 2px rgb(50 59 71), 0px 0px 11px 12px rgba(43, 218, 205, 1);
    }
    100% {
      box-shadow: 0px 0px 0px 2px rgb(50 59 71), 0px 0px 11px 4px rgba(43, 218, 205, 1);
    }
  }

  @font-face {
    font-family: 'password';
    font-style: normal;
    font-weight: 400;
    src: url(${getAsset(fontPassword, 'woff')}) format('woff');
  }

  @font-face {
    font-family: 'FiraGO';
    src: url(${getAsset(fontLightTwo, 'woff2')}) format('woff2'),
      url(${getAsset(fontLight, 'woff')}) format('woff');
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: 'FiraGO';
    src: url(${getAsset(fontRegularTwo, 'woff2')}) format('woff2'),
      url(${getAsset(fontRegular, 'woff')}) format('woff');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'FiraGO';
    src: url(${getAsset(fontMediumTwo, 'woff2')}) format('woff2'),
      url(${getAsset(fontMedium, 'woff')}) format('woff');
    font-weight: 500;
    font-style: normal;
  }

  @font-face {
    font-family: 'FiraGO';
    src: url(${getAsset(fontBoldTwo, 'woff2')}) format('woff2'),
      url(${getAsset(fontBold, 'woff')}) format('woff');
    font-weight: 700;
    font-style: normal;
  }

  @font-face {
    font-family: 'FiraGO';
    src: url(${getAsset(fontLightItalicTwo, 'woff2')}) format('woff2'),
      url(${getAsset(fontLightItalic, 'woff')}) format('woff');
    font-weight: 300;
    font-style: italic;
  }

  html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

  body,
  #root {
    position: relative;
    margin: 0;
    width: 100%;
    min-height: 100vh;
    padding: 0;
    top: 0;
    left: 0;
    bottom: 0;
    line-height: 1rem;
    font-family: 'FiraGO';
    font-weight: 400;
    text-rendering: geometricPrecision;
    overflow: hidden;
  }

  *,
  *:focus {
    outline: none;
  }

  h1 {
    font-size: ${big};
    line-height: ${big};
    font-family: 'FiraGO';
    font-weight: 700;
    margin: 0.5rem 0;
  }

  span {
    font-size: ${small};
  }

  p {
    color: ${secondaryTextColor};
  }

  strong {
    font-family: 'FiraGO';
    color: ${thirdTextColor};
    font-weight: 500;
  }

  .Toastify__toast-container {
    width: fit-content;
    top: auto;
  }

  .Toastify__toast {
    min-height: fit-content;
  }

  .leaflet-marker-icon-job.tour-icon.tour-unselected {
    z-index: 0 !important;
  }

  .file-drop > .file-drop-target.json-drop {
    outline: 0.2rem solid ${aqua};
    border-radius: 2pt;
    box-shadow: inset 0px 0px 0px 0.1rem ${aqua};
    transition: 0.3s;
  }

  .json-viewer-panel .file-drop-target {
    width: max-content;
  }

  .file-drop {
    .json-viewer-theme-custom {
      white-space: nowrap;
      min-width: 100%;
      padding-right: 2rem;
    }
  }

  .leaflet-container {
    position: relative;
    height: 100vh;
  }

  .leaflet-marker-icon-job {
    background-repeat: no-repeat;
    font-weight: bold;
    margin-top: -0.5rem;
    margin-left: -0.75rem;
    width: 1.5rem;
    height: 1.5rem;
  }

  .leaflet-marker-icon-job.depot-icon {
    width: 3rem;
    height: 3rem;
    margin-top: -2.5rem !important;
    background-size: 3rem 3rem;
  }

  .leaflet-marker-icon-job.return-location-icon {
    width: 3rem;
    height: 3rem;
    margin-top: -2.5rem !important;
    background-size: 3rem 3rem;
    background-image: url(${getAsset(returnLocation)});
  }

  .leaflet-marker-icon-job.plan-icon {
    background-image: url(${getAsset(delivery)});
  }

  .leaflet-marker-icon-job.plan-icon-pickup {
    background-image: url(${getAsset(deliveryPickup)});
  }

  .leaflet-marker-icon-job.plan-icon-high-priority {
    background-image: url(${getAsset(deliveryHighPriority)});
  }

  .leaflet-marker-icon-job.plan-icon-high-priority-pickup {
    background-image: url(${getAsset(deliveryHighPriorityPickup)});
  }

  .leaflet-marker-icon-job.plan-editing-icon {
    width: 3rem !important;
    height: 3rem !important;
    background-size: 3rem 3rem;
    background-image: url(${getAsset(deliveryEditing)});
    position: relative;
    left: -13px;
  }

  .leaflet-marker-icon-job.unassigned-icon {
    z-index: 100 !important;
  }

  .leaflet-popup {
    margin-bottom: 3rem;
    margin-left: 0.1rem;
    z-index: 10000;
  }

  .depot-icon.leaflet-popup,
  .custom-depot.leaflet-popup {
    margin-bottom: 3.5rem;
    margin-left: -0.4rem;
    text-align: center;
    font-weight: bold;
  }

  .custom-job.leaflet-popup {
    margin-bottom: 2.6rem;
  }

  .custom-job.tour-selected.leaflet-popup {
    margin-bottom: 3.2rem;
    margin-left: 0.5rem;
  }

  .custom-job.tour-unselected.leaflet-popup {
    text-align: center;
    margin-bottom: 1.6rem;
  }

  .leaflet-popup-content-wrapper {
    font-family: 'FiraGO';
    border-radius: 4px;
    background-color: #ffffff;
    backdrop-filter: blur(5px);
  }

  .leaflet-popup.location-markers-popup {
    & .leaflet-popup-content-wrapper {
      background-color: rgba(255, 255, 255, 0.5);
    }
  }

  .leaflet-popup-tip-container {
    width: 14px;
    height: 10px;
    margin-left: -9px;
  }

  .leaflet-popup-tip {
    width: 10px;
    height: 10px;
  }

  .leaflet-popup .labels {
    color: ${black};
    font-weight: bold;
    margin-bottom: 0.5rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .leaflet-popup .labels .high-priority {
    background-color: ${yellow};
    color: ${black};
    font-weight: bold;
    margin-bottom: 0.5rem;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .leaflet-popup table td:nth-of-type(1) {
    max-width: 70px;
  }

  .leaflet-popup table td:nth-of-type(2) {
    min-width: fit-content;
    max-width: 230px;
  }

  .leaflet-popup table td {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .leaflet-popup-content-wrapper {
    color: ${primaryTextColor};
  }

  .leaflet-popup-close-button {
    display: none;
  }

  // ZOOM CONTROLS

  .leaflet-top.leaflet-right {
    margin-top: 4rem;
    z-index: 500;
  }

  .leaflet-touch .leaflet-bar a {
    width: auto;
    height: auto;
    line-height: 30px;
  }

  .leaflet-touch .leaflet-bar {
    border: 0px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  }

  .leaflet-bar {
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  }

  .leaflet-right .leaflet-control {
    margin-right: 1rem;
    border-radius: 2px;
    overflow: hidden;
    margin-top: 0px;
  }

  .leaflet-bar a:first-of-type,
  .leaflet-bar a:first-of-type:hover {
    background-image: url(${getAsset(plus)});
    background-size: 14px 14px;
    border-radius: 0;
    border-bottom: none;
  }

  .leaflet-bar a:last-of-type,
  .leaflet-bar a:last-of-type:hover {
    background-image: url(${getAsset(minus)});
    background-size: 14px 14px;
    border-radius: 0;
    border-bottom: none;
  }

  .leaflet-bar a,
  .leaflet-bar a:hover {
    border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
    transition: 0.25s ease-in-out;
    width: auto;
    height: auto;
    background-color: rgba(255, 255, 255, 0.8);
  }

  .leaflet-bar a,
  .leaflet-bar a:hover,
  .leaflet-bar a.leaflet-disabled {
    color: transparent;
  }

  .leaflet-bar a.leaflet-disabled:hover {
    background-color: #f4f4f4;
  }

  .leaflet-control-zoom-in,
  .leaflet-control-zoom-out {
    padding: 5px 12px;
    background-color: rgba(255, 255, 255, 0.9);
    backdrop-filter: blur(5px);
  }

  .leaflet-control-zoom-in:hover,
  .leaflet-control-zoom-out:hover {
    padding: 5px 12px;
    background-color: rgba(255, 255, 255, 1);
    backdrop-filter: blur(5px);
  }

  .leaflet-control-scale.leaflet-control {
    margin-bottom: 80px;
    margin-right: 5px;
  }

  .leaflet-control-scale-line {
    font-size: 14px;
  }

  .marker-cluster-order,
  .marker-cluster-stop {
    z-index: 600 !important;
  }

  .marker-cluster {
    font-weight: 700 !important;
  }

  input[type='checkbox'] {
    height: 0;
    width: 0;
    visibility: hidden;
    display: none;
  }

  .custom-switcher {
    cursor: pointer;
    text-indent: -9999px;
    width: 42px;
    height: 24px;
    background: rgba(0, 0, 0, 0.35);
    display: block;
    border-radius: 100px;
    position: relative;
    display: inline-block;
    margin-right: 8px;
    transition: all 500ms cubic-bezier(0.365, 0.005, 0.285, 1.005);
  }

  .custom-switcher:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 2px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }

  input:checked + .custom-switcher {
    background: #00cbc3;
  }

  input:checked + .custom-switcher:after {
    left: calc(100% - 2px);
    transform: translateX(-100%);
  }

  input:disabled + .custom-switcher {
    cursor: default;
    opacity: 0.7;
  }

  .custom-switcher:active:after {
    width: 25px;
  }

  .label-custom-switcher {
    position: relative;
    top: 4px;
    font-size: ${medium};
  }

  .label-custom-switcher.inverted {
    color: ${secondaryTextColorDarkBg};
  }

  .label-custom-switcher.small {
    font-size: ${small};
  }

  .disabled-label {
    opacity: 50%;
  }

  .label-custom-switcher.clickable {
    cursor: pointer;
  }

  #error-browser {
    height: 100%;
    width: 100%;
    text-align: center;
    margin: 20% auto;
    padding: 1rem 3rem;
    line-height: 1.5rem;
  }

  @media screen and (max-width: 850px) {
    .label-custom-switcher.small {
      font-size: ${smaller};
    }

    .leaflet-control-zoom-in,
    .leaflet-control-zoom-out {
      position: fixed;
      top: 4rem;
      right: 1rem;
      z-index: 999999;
    }

    .leaflet-control-zoom-out {
      top: 6.4rem;
    }
  }
`;

render(
  <Provider store={createStore()}>
    <Global styles={globalStyles} />
    <App />
  </Provider>,
  document.getElementById('root'),
);

unregister();
