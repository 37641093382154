import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setUserParam } from '../../../actions';
import { StyledSelectInput } from '../../Wizard/Global/WizardStyled';

const DistanceSelector = ({ user }) => {
  const dispatch = useDispatch();
  const handleSetUserParam = useCallback(
    (distance) => dispatch(setUserParam({ distance })),
    [dispatch],
  );

  const onChange = useCallback(
    (e) => {
      handleSetUserParam(e.target.value);
    },
    [handleSetUserParam],
  );

  return (
    <StyledSelectInput contrast sizeL onChange={onChange} value={user.distance}>
      <option value="metric">Metric System</option>
      <option value="imperial">Imperial System</option>
    </StyledSelectInput>
  );
};

export default DistanceSelector;
