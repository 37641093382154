import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTerritoryParameter, getTerritoryPlanningAreas, setLoader } from 'actions';
import {
  StyledText,
  StyledSelectInput,
  StyledInfoEllipsis,
  StyledTextInput,
} from 'components/Wizard/Global/WizardStyled';
import Button from '../../Form/Button';
import withTranslation from '../../../hoc/withTranslation';
import {
  StyledContrastPanel,
  StyledContrastPanelContainer,
  StyledContrastPanelFooter,
  StyledContrastPanelHeader,
  StyledContrastPanelHeaderText,
  StyledCategory,
  StyledContrastPanelColumn,
  StyledContrastPanelColumnsContainer,
} from '../../Wizard/Global/WizardStyledContrastPanel';
import { getAtLocation } from '../../../utils/GeoCoder';
import { getBoundingCoordinates } from '../../../utils/MapHelpers';
import { getSafeValue } from '../../../utils/security';

const TerritoryPlanningContainer = ({
  oAuth,
  user,
  tourPlanner,
  onClose,
  translations: {
    territoryPlanning: {
      territoryPlanningTitle,
      territoryPlanningInformation,
      buttonCancelLabel,
      buttonSubmitLabel,
      administrativeBoundariesLabelTrans,
      administrativeBoundaries,
      territoryNameInputLabel,
      selectedAreaInputLabel,
    },
  },
}) => {
  const territories = useSelector(({ areas: stateAreas }) => stateAreas.territories);
  const dispatch = useDispatch();
  const handleSetTerritoryParameter = useCallback(
    (parameter) => dispatch(setTerritoryParameter(parameter)),
    [dispatch],
  );
  const handleGetTerritoryPlanningAreas = useCallback(
    (areaRequest) => dispatch(getTerritoryPlanningAreas(areaRequest)),
    [dispatch],
  );
  const handleSetLoader = useCallback((data) => dispatch(setLoader(data)), [dispatch]);

  useEffect(() => {
    if (!oAuth || !oAuth.accessToken) return;

    const loc = getAtLocation(user, tourPlanner);
    const bboxValue = getBoundingCoordinates(loc, 2000);

    handleSetTerritoryParameter({ bbox: bboxValue });
    const areaReq = { areaCategory: territories.areaCategory, bbox: bboxValue };
    handleSetLoader({ isLoading: true });
    handleGetTerritoryPlanningAreas({ ...areaReq, user, oAuth });
  }, [oAuth, territories.areaCategory]);

  useEffect(() => {
    handleSetLoader({ isLoading: false });
  }, [territories.areaDetails]);

  const onChangeAreaCategory = useCallback((e) => {
    handleSetTerritoryParameter({ areaCategory: e.target.value });
  });

  const handleOnSubmit = useCallback(() => {
    if (onClose) {
      onClose(true);
    }
  }, [onClose]);

  const handleOnCancelTerritoryPlanning = useCallback(() => {
    if (onClose) {
      onClose(false);
    }
  }, [onClose]);

  const height = 'calc(100% - 11.4rem)';

  const onFocus = (e) => {
    if (e.target instanceof HTMLInputElement) e.target.removeAttribute('readonly');
  };

  return (
    <StyledContrastPanelContainer>
      <StyledContrastPanel>
        <StyledContrastPanelHeader size="0">
          <StyledText bold contrast>
            {territoryPlanningTitle}
          </StyledText>
          <StyledContrastPanelHeaderText paddingBottom>
            {territoryPlanningInformation}
          </StyledContrastPanelHeaderText>
        </StyledContrastPanelHeader>
        <StyledContrastPanelColumnsContainer height={height}>
          <StyledContrastPanelColumn inputSize="M">
            <StyledInfoEllipsis>{administrativeBoundariesLabelTrans}</StyledInfoEllipsis>
            <StyledCategory>
              <StyledSelectInput
                contrast
                value={territories.areaCategory}
                onChange={onChangeAreaCategory}
              >
                {Object.keys(administrativeBoundaries).map((boundaryType) => (
                  <option key={boundaryType} value={boundaryType}>
                    {getSafeValue(administrativeBoundaries, boundaryType)}
                  </option>
                ))}
              </StyledSelectInput>
            </StyledCategory>
          </StyledContrastPanelColumn>
          <StyledContrastPanelColumn inputSize="zero">
            <StyledInfoEllipsis>{territoryNameInputLabel}</StyledInfoEllipsis>
            <StyledTextInput
              id="input-edit-order-name"
              placeholder="Zone A"
              autoComplete="off"
              theme="contrast"
              readOnly
              onFocus={onFocus}
              type="new-password"
            />
          </StyledContrastPanelColumn>
          <StyledContrastPanelColumn inputSize="zero">
            <StyledInfoEllipsis>{selectedAreaInputLabel}</StyledInfoEllipsis>
          </StyledContrastPanelColumn>
        </StyledContrastPanelColumnsContainer>
        <StyledContrastPanelFooter isDouble>
          <Button
            text={buttonCancelLabel}
            inverted
            contrast
            onClick={handleOnCancelTerritoryPlanning}
          />
          <div />
          <Button text={buttonSubmitLabel} onClick={handleOnSubmit} />
        </StyledContrastPanelFooter>
      </StyledContrastPanel>
    </StyledContrastPanelContainer>
  );
};

export default withTranslation(TerritoryPlanningContainer);
