import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import { last } from 'lodash';
import { useCookies } from 'react-cookie';
import Tour, { RequestStatus, StyledTour } from './Tour';
import { useAutoRefresh } from '../../../hooks/useAutoRefresh.hook';
import { checkForSolution, getSolution, setError, setSolutionParam } from '../../../actions';
import { hasOngoingAsyncRequest, isIterationSolution } from '../../../utils/SolutionHelpers';
import withTranslation from '../../../hoc/withTranslation';
import { isProdEnv } from '../../../utils/helpers';

const isProd = isProdEnv();

// TODO adjust for mobile
const StyledTourPanel = styled.div(({ isOpen, jsonOpen }) => ({
  display: isOpen ? 'flex' : 'none',
  position: 'fixed',
  flexDirection: 'column',
  gap: '0.25rem',
  right: jsonOpen ? '27rem' : '4rem',
  top: jsonOpen ? '4rem' : '3rem',
  backgroundColor: 'rgba(255,255,255,0.95)',
  zIndex: '999',
  boxShadow: '0px 10px 29px 0px rgba(0, 0, 0, 0.22)',
  borderRadius: '0.25rem',
  backdropFilter: 'blur(10px)',
  textRendering: 'geometricPrecision',
  borderRight: jsonOpen && '3px solid grey',
  overflow: 'hidden',
  maxHeight: '100%',
  transition: 'all 0.5s cubic-bezier(.72,0,0,.99)',
  padding: '0.25rem',
  '@media screen and (max-width: 850px)': {
    top: 0,
    bottom: '0',
    left: 0,
    height: '100%',
    width: '100%',
    borderRadius: '0',
    boxShadow: 'none',
  },
}));

const TourPanel = ({
  solution,
  user,
  oAuth,
  isOpen,
  translations: {
    solutionsStatusPanel: { noSolutionsToShow },
  },
}) => {
  const dispatch = useDispatch();
  const [refresh] = useAutoRefresh(5000);
  const handleSetSolutionParameter = useCallback(
    (data) => dispatch(setSolutionParam(data)),
    [dispatch],
  );
  const tourPlanner = useSelector(
    ({ tourPlanner: stateTourPlanner }) => stateTourPlanner.value[solution.show],
  );
  const [solutionsToShow, setSolutionsToShow] = useState(null);
  const [cookies] = useCookies(['apikey']);
  const ongoingRequests = hasOngoingAsyncRequest(solution.requests);

  useEffect(() => {
    const filteredSolutions = solution.requests.filter((request) => {
      const lastIteration = last(request.iterations);

      const response =
        (isIterationSolution(lastIteration) && lastIteration.response) ||
        lastIteration?.request?.index !== undefined;
      return response || request.status;
    });
    setSolutionsToShow(filteredSolutions);
  }, [solution]);

  useEffect(() => {
    if ((!isProd && !oAuth) || (isProd && !cookies)) return;
    solution.requests.forEach((request, index) => {
      if (
        request.statusHref &&
        (!request.status || request.status === 'pending' || request.status === 'inProgress')
      ) {
        checkForSolution({
          href: request.statusHref,
          oAuth,
          apiKey: isProd && cookies.apikey,
          dispatch,
          index,
          status: request.status,
          isActive: solution.show === index,
          keepOrders: tourPlanner.ordersMode !== 'json_import',
          isDemo: user.isLastOrdersAddedDemo || tourPlanner.ordersMode === 'demo',
        });
      }
    });
  }, [refresh]);

  useEffect(() => {
    solution.requests.forEach((req) => {
      const status = req.status;
      if (status === RequestStatus.PENDING && !req.asyncStartTime) {
        handleSetSolutionParameter({
          param: { asyncStartTime: Math.floor(Date.now() / 1000) },
          index: solution.show,
        });
      }
      if (status === RequestStatus.FAILURE) {
        dispatch(setError(req.status));
      }

      if (status === RequestStatus.IN_PROGRESS && !req.runtimeStart && !req.runtime) {
        handleSetSolutionParameter({
          param: { runtimeStart: Date.now() },
          index: solution.show,
        });
      }
      if (status === RequestStatus.SUCCESS && req.runtimeStart) {
        const runtimeFinishTime = Date.now();
        handleSetSolutionParameter({
          param: { runtime: runtimeFinishTime - req.runtimeStart, runtimeFinishTime },
          index: solution.show,
        });
      }
    });
  }, [solution.requests, solution.show, handleSetSolutionParameter]);

  useEffect(() => {
    if ((!isProd && !oAuth) || (isProd && !cookies)) return;
    solution.requests.forEach((request, index) => {
      if (request.solutionHref) {
        getSolution({
          href: request.solutionHref,
          oAuth,
          apiKey: isProd && cookies.apikey,
          index,
          dispatch,
          keepOrders: tourPlanner.ordersMode !== 'json_import',
        });
      }
    });
  }, [oAuth]);

  return (
    <StyledTourPanel data-test-id="JVFilename" isOpen={isOpen} jsonOpen={user.jsonPanelOpen}>
      {solutionsToShow && solutionsToShow.length > 0 ? (
        solutionsToShow.map((request, index) => (
          <Tour
            key={`solution-element-${index}`}
            request={request}
            isSelected={solution.show === index}
            index={index}
            tpaVersion={user.tpaVersion}
            apiKey={cookies.apikey}
            oAuth={oAuth}
            isRememberLastState={user.hasStateMemory}
            isProdEnv={isProd}
            hasOngoingRequests={ongoingRequests}
          />
        ))
      ) : (
        <StyledTour noHover>{noSolutionsToShow}</StyledTour>
      )}
    </StyledTourPanel>
  );
};

export default withTranslation(TourPanel);
