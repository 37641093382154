import React, { useCallback, useEffect } from 'react';
import FileSaver from 'file-saver';
import { useDispatch } from 'react-redux';
import { setError, setLoader } from '../../../actions';
import { getPDFFileName } from '../../../utils/SolutionExport';

const DownloadPDF = ({ blob, loading, error, tourID, onFinish, user }) => {
  const dispatch = useDispatch();
  const handleSetLoader = useCallback((data) => dispatch(setLoader(data)), [dispatch]);
  const handleSetError = useCallback((data) => dispatch(setError(data)), [dispatch]);
  const getFilename = useCallback((i) => getPDFFileName(user, i), [user]);

  useEffect(() => {
    const filename = getFilename(tourID);
    if (blob) FileSaver.saveAs(blob, filename);
  }, [blob, tourID, getFilename]);

  useEffect(() => {
    handleSetLoader({ isLoading: loading });
    if (!loading) onFinish();
  }, [loading, handleSetLoader, onFinish]);

  useEffect(() => {
    if (error) {
      handleSetError({ errorCode: 'contactTrans' });
      onFinish();
    }
  }, [error, handleSetError, onFinish]);

  return <></>;
};

export default DownloadPDF;
