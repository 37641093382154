import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import { colors } from '../../global/variables';

const { primaryColor, primaryBorderColor, primaryTextColor } = colors;

const StyledCheckbox = styled.div(({ highlight }) => ({
  cursor: 'pointer',
  border: `0.1rem solid ${primaryBorderColor}`,
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '0.7rem',
  height: '0.7rem',
  fontSize: highlight ? 'x-small' : 'large',
  color: highlight ? primaryColor : primaryTextColor,
}));

const TableCheckbox = ({ onSelect, onUnselect, total, selected, title }) => {
  const allSelected = selected === total;
  const partialSelected = selected > 0;
  const content = allSelected ? '✔' : partialSelected ? '-' : '';

  const handleOnClick = useCallback(() => {
    if (allSelected) onUnselect();
    else if (partialSelected) onSelect();
    else onSelect();
  }, [onSelect, onUnselect, allSelected, partialSelected]);

  return (
    <StyledCheckbox onClick={handleOnClick} highlight={allSelected} title={title}>
      {content}
    </StyledCheckbox>
  );
};

export default TableCheckbox;
