/* eslint-disable react/jsx-props-no-spreading */
import en from 'global/locales/en/main';
import ja from 'global/locales/ja/main';
import { isFunction, mapValues } from 'lodash';
import React from 'react';
import { useSelector } from 'react-redux';
import * as solutionHelpers from 'utils/formatter';
import * as costHelpers from 'utils/cost';
import { getSafeValue } from '../utils/security';

const translations = { en, ja };
const mapFunction = (fn, units) => (isFunction(fn) ? (value) => fn(value)(units) : fn);

const withTranslation = (Component) => (props) => {
  const user = useSelector(({ user: stateUser }) => stateUser);
  const language = user.language;
  const linkLang = user.language === 'el' ? 'gr-el' : `${user.language}-${user.language}`;

  const { units, ...currentTranslations } = getSafeValue(translations, language);
  const helpers = {
    ...mapValues(solutionHelpers, (fn) => mapFunction(fn, units)),
    ...mapValues(costHelpers, (fn) => mapFunction(fn, units)),
  };

  return (
    <Component
      helpers={helpers}
      translations={{ ...currentTranslations }}
      linkLang={linkLang}
      {...props}
    />
  );
};

export default withTranslation;
