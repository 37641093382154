import { concat, keys } from 'lodash';
import { getSafeValue } from '../security';

const defaultThreshold = 0.1;
export const OBJECTIVE_ACTIONS = {
  MINIMIZE: 'minimize',
  MAXIMIZE: 'maximize',
};
export const OBJECTIVE_FUNCTIONS_LIMIT_NXT = 8;
export const OBJECTIVE_FUNCTIONS_NXT = {
  minimizeUnassigned: { optional: true, order: 1, enabled: true, balance: false },
  minimizeTours: { optional: true, order: 2, enabled: true, balance: false },
  minimizeCost: { optional: false, order: 3, enabled: true, balance: false },
  minimizeDistance: { optional: true, order: 4, balance: false },
  minimizeDuration: { optional: true, order: 5, balance: false },
  maximizePriorityJobs: { optional: true, order: 6, balance: false },
  maximizeTerritoryJobs: { optional: true, order: 7, balance: false },
  maximizeTours: { optional: true, order: 8, balance: false },
  balanceMaxLoad: { optional: true, threshold: defaultThreshold, balance: true },
  balanceActivities: { optional: true, threshold: defaultThreshold, balance: true },
  balanceDistance: { optional: true, threshold: defaultThreshold, balance: true },
  balanceDuration: { optional: true, threshold: defaultThreshold, balance: true },
};
export const OBJECTIVE_FUNCTIONS_LIMIT_PRD = 6;
export const OBJECTIVE_FUNCTIONS_PRD = {
  minimizeUnassigned: { optional: true, order: 1, enabled: true, balance: false },
  optimizeTourCount: {
    optional: true,
    order: 2,
    enabled: true,
    balance: false,
    action: OBJECTIVE_ACTIONS.MINIMIZE,
  },
  minimizeCost: { optional: false, order: 3, enabled: true, balance: false },
  optimizeTaskOrder: { optional: true, order: 4, balance: false },
  minimizeDistance: { optional: true, order: 5, balance: false },
  minimizeDuration: { optional: true, order: 6, balance: false },
};

export const objectiveFunctionsToArray = (fn) => {
  const allKeys = keys(fn).map((key) => ({ ...getSafeValue(fn, key), key }));
  return allKeys.sort((a, b) => a.order - b.order);
};

export const getDefaultObjectiveFunctions = (isBeta) =>
  objectiveFunctionsToArray(isBeta ? OBJECTIVE_FUNCTIONS_NXT : OBJECTIVE_FUNCTIONS_PRD);

export const getObjectivesLimit = (isBeta) =>
  isBeta ? OBJECTIVE_FUNCTIONS_LIMIT_NXT : OBJECTIVE_FUNCTIONS_LIMIT_PRD;

const updateOptionalBalanceObjectives = (configuration, itemIndex) => {
  if (!getSafeValue(configuration, itemIndex).balance) return;

  configuration.forEach((item, index) => {
    if (item.balance && index !== itemIndex) {
      getSafeValue(configuration, index).optional = !getSafeValue(configuration, index).optional;
    }
  });
};

export const enableObjectiveFunction = (configuration, itemIndex, limitReached) => {
  if (!getSafeValue(configuration, itemIndex).optional) return configuration;
  if (!getSafeValue(configuration, itemIndex).enabled && limitReached) return configuration;

  const newConfig = [...configuration];
  getSafeValue(newConfig, itemIndex).enabled = !getSafeValue(newConfig, itemIndex).enabled;
  updateOptionalBalanceObjectives(newConfig, itemIndex);

  const enabledOptions = newConfig.filter((item) => item.enabled);
  const disabledOptions = newConfig.filter((item) => !item.enabled);
  const allOptions = concat(enabledOptions, disabledOptions);
  return allOptions.map((item, index) => ({ ...item, order: index + 1 }));
};

export const changeObjectivePriority = (configuration, itemKey, orderValue) => {
  const itemIndex = configuration.findIndex(({ key: i }) => i === itemKey);
  const updateConfiguration = [
    ...configuration.slice(0, itemIndex),
    ...configuration.slice(itemIndex + 1),
  ];
  const toAdd = getSafeValue(configuration, itemIndex);
  const reorderedConfiguration = [
    ...updateConfiguration.slice(0, orderValue - 1),
    toAdd,
    ...updateConfiguration.slice(orderValue - 1),
  ];
  return reorderedConfiguration.map((item, i) => ({ ...item, order: i + 1 }));
};
