/* eslint-disable max-classes-per-file */
import { sprintf } from 'utils/helpers';

export class CSVProcessingError extends Error {}

export class ValidationError extends CSVProcessingError {
  constructor(key, params, ...rest) {
    super(...rest);
    this.key = key;
    this.params = params;
  }

  getPayload = (translations) => ({
    errorMessage: sprintf(translations[this.key], this.params),
  });
}

export class NoJobsFoundError extends CSVProcessingError {
  getPayload = (translations) => ({
    type: 'warn',
    errorMessage: translations.noJobsFound,
  });
}
