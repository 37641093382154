import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from '@emotion/styled';
import { first, isEmpty, size } from 'lodash';
import {
  StyledLocationInput,
  StyledText,
  StyledWizardContent,
  StyledWizardH1,
} from '../Global/WizardStyled';
import { colors, fontSize } from '../../../global/variables';
import Section from '../../Form/Section';
import { getSafeValue } from '../../../utils/security';
import withTranslation from '../../../hoc/withTranslation';
import IdSearchDetails from './IdSearchDetails';

const { white, transparentGreyColor, black, secondaryBorderColor } = colors;

const { normal } = fontSize;

const StyledOption = styled.div(
  {
    lineHeight: '2rem',
    fontSite: normal,
    borderBottom: `0.5px solid ${secondaryBorderColor}`,
    padding: '0.4rem 0.8rem',
    cursor: 'pointer',
    color: black,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  ({ isHovered }) => ({
    backgroundColor: isHovered ? white : transparentGreyColor,
  }),
);

const StyledIdSearchInputContainer = styled.div({
  position: 'relative',
});

const StyledOptionsContainer = styled.div({
  position: 'absolute',
  top: '2.6rem',
  backgroundColor: white,
  width: '100%',
  zIndex: 200,
  boxShadow: '0px 0px 0px 2px rgba(34,205,200,0.4)',
});

function getOptions(val, activities) {
  const result = [];
  activities.forEach((activity) => {
    if (
      result.length < 5 &&
      activity.jobId.includes(val) &&
      !result.some((act) => act.jobId === activity.jobId)
    ) {
      result.push(activity);
    }
  });
  return result;
}

function getActivitiesFromTour(tour, result) {
  const typeId = tour.typeId;
  const vehicleId = tour.vehicleId;
  let sequenceNo = 0;
  tour.stops.forEach((stop) => {
    stop.activities.forEach((act) => {
      if (!(act.jobId === 'arrival') && !(act.jobId === 'departure')) {
        result = [
          ...result,
          { ...act, location: stop.location, time: stop.time, typeId, vehicleId, sequenceNo },
        ];
      }
      sequenceNo += 1;
    });
  });
  return result;
}

const IdSearchContainer = ({
  tourData: { tours = [], unassigned },
  selectedTours,
  searchSelectedTour,
  translations: {
    idSearchContainer: { inputPlaceholder, searchIDTitle, errorMessage },
  },
}) => {
  const [jobId, setJobId] = useState('');
  const [activities, setActivities] = useState([]);
  const [selectedActivities, setSelectedActivities] = useState([]);
  const [showResult, setShowResult] = useState(false);
  const [hoveredOption, setHoveredOption] = useState(null);
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const inputField = useRef(null);

  const searchForId = useCallback(
    (id) => {
      if (isEmpty(activities)) return;

      const found = activities.filter((act) => act.jobId === id);
      setSelectedActivities(found);

      if (isEmpty(found)) {
        setShowResult(false);
      } else {
        setShowResult(true);
        setOptions([]);
        setHoveredOption(null);
      }
    },
    [setOptions, setHoveredOption, setSelectedActivities, setShowResult, activities],
  );

  const onChange = useCallback(
    (e) => {
      const val = e && e.target && e.target.value;

      setJobId(val);
      searchForId(val);

      if (!val || val === '') {
        setOptions([]);
        return;
      }

      if (selectedOption && val.trim() !== selectedOption.jobId) {
        setSelectedOption(null);
      }

      setOptions(getOptions(val, activities));
    },
    [setJobId, setOptions, selectedOption, setSelectedOption, getOptions, activities, searchForId],
  );

  const onKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        const autoSelected = size(options) === 1 ? options[0] : null;
        const toReturn = hoveredOption || autoSelected || selectedOption;
        setJobId(toReturn.jobId);
        setSelectedOption(toReturn);
        searchForId(toReturn.jobId);
        setOptions([]);
        setHoveredOption(null);
        e.target.value = toReturn.jobId;
        e.target.select();
        return;
      }

      if (e.keyCode === 38 || e.keyCode === 40) {
        const index = hoveredOption
          ? options.findIndex((o) => o.jobId === hoveredOption.jobId)
          : -1;
        const newIndex = e.keyCode === 38 ? index - 1 : index + 1;
        const finalIndex = newIndex < 0 ? size(options) - 1 : newIndex;
        const last = finalIndex >= size(options) ? 0 : finalIndex;
        setHoveredOption(getSafeValue(options, last));
      }
    },
    [
      searchForId,
      options,
      hoveredOption,
      selectedOption,
      setSelectedOption,
      setOptions,
      setHoveredOption,
      setJobId,
    ],
  );

  const onOptionMouseEnter = useCallback(
    (option) => {
      setHoveredOption(option);
    },
    [setHoveredOption],
  );

  const onOptionMouseLeave = useCallback(() => {
    setHoveredOption(null);
  }, [setHoveredOption]);

  const onOptionClick = useCallback(
    (option) => {
      setJobId(option.jobId);
      setSelectedOption(option);
      searchForId(option.jobId);
      inputField.current.value = option.jobId;
      inputField.current.select();
      setOptions([]);
      setHoveredOption(null);
    },
    [setHoveredOption, setSelectedOption, setJobId, searchForId, setOptions, setHoveredOption],
  );

  useEffect(() => {
    let result = [];
    if (searchSelectedTour) {
      const tour = getSafeValue(tours, first(selectedTours));
      result = getActivitiesFromTour(tour, result);
    } else {
      tours.forEach((tour) => {
        result = getActivitiesFromTour(tour, result);
      });
      if (unassigned !== undefined) result = result.concat(unassigned);
    }
    setActivities(result);
  }, [tours, unassigned]);

  return (
    <StyledWizardContent>
      <StyledWizardH1>{searchIDTitle}</StyledWizardH1>
      <Section>
        <StyledIdSearchInputContainer>
          <StyledLocationInput
            autoFocus
            autoComplete="off"
            placeholder={inputPlaceholder}
            onChange={onChange}
            value={jobId}
            onFocus={onChange}
            onKeyDown={onKeyDown}
            ref={inputField}
          />
          {!isEmpty(activities) && (
            <StyledOptionsContainer>
              {options.map((option, index) => {
                return (
                  <StyledOption
                    key={`option-jobId-key-${index}`}
                    id={`option-jobId-id-${index}`}
                    onMouseEnter={() => onOptionMouseEnter(option)}
                    onMouseLeave={onOptionMouseLeave}
                    isHovered={!hoveredOption || hoveredOption.jobId !== option.jobId}
                    onClick={() => onOptionClick(option)}
                  >
                    {option.jobId}
                  </StyledOption>
                );
              })}
            </StyledOptionsContainer>
          )}
        </StyledIdSearchInputContainer>
      </Section>
      <Section>
        {showResult ? (
          <IdSearchDetails activities={selectedActivities} />
        ) : (
          <StyledText>{errorMessage}</StyledText>
        )}
      </Section>
    </StyledWizardContent>
  );
};

export default withTranslation(IdSearchContainer);
