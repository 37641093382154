import React, { useCallback } from 'react';
import styled from '@emotion/styled';
import withTranslation from 'hoc/withTranslation';
import { colors, fontSize } from 'global/variables';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import { StyledIcon } from './WizardStyled';
import { getAsset } from '../../../utils/helpers';
import checkIcon from '../../../global/img/checkmark.svg';
import { getSafeValue } from '../../../utils/security';

const { aqua, secondaryTextColor, black, primaryColor, disabledBackground } = colors;
const { smaller, mini } = fontSize;

const StyledContainer = styled.div({
  boxSizing: 'border-box',
  display: 'inline-block',
  textAlign: 'center',
});

const StyledCircle = styled.div(
  {
    width: '1rem',
    height: '1rem',
    padding: '0.2rem',
    borderRadius: '1rem',
    fontSize: smaller,
    fontWeight: '700',
    border: 'solid 1px transparent',
    boxShadow: `inset 0px 0px 0px 0px ${primaryColor}`,
    color: black,
    textAlign: 'center',
    margin: 'auto',
    userSelect: 'none',
    transition: 'all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005)',
    backgroundPosition: 'center !important',
    '@media screen and (max-width : 850px)': {
      fontSize: mini,
    },
  },
  ({ selected }) => ({
    backgroundColor: selected && aqua,
  }),
  ({ disabled }) => ({
    backgroundColor: disabled && disabledBackground,
    color: disabled && secondaryTextColor,
  }),
  ({ previous }) => ({
    border: previous && `solid 1px ${aqua}`,
    span: {
      color: previous && aqua,
    },
    ':hover': {
      boxShadow: previous && `inset 0px 0px 0px 1px ${primaryColor}`,
    },
  }),
  ({ clickable }) => ({
    cursor: clickable && 'pointer',
  }),
);

const StyledLabel = styled.div(
  {
    marginTop: '0.3rem',
    textTransform: 'uppercase',
    color: secondaryTextColor,
    fontSize: smaller,
  },
  ({ selected }) => ({
    fontWeight: selected ? '700' : '400',
  }),
);

export const CheckIconStyle = css`
  background-image: url(${getAsset(checkIcon)});
  margin: 0.2rem 0 0 0;
  background-size: 0.65rem 0.65rem !important;
  background-position: center !important;
  transition: all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005);
`;

const WizardHeaderStep = ({
  step,
  selectedStep,
  hasSolution,
  onChange,
  translations: {
    wizard: { headerLabels, jsonViewer },
  },
}) => {
  const solutionJson = useSelector((state) => state.solution.json);

  const onStepClick = useCallback(
    (isClickable) => {
      if (!isClickable) return;
      onChange(step);
    },
    [onChange, step],
  );

  const isDisabled = step > selectedStep || (solutionJson && step !== 3);
  const isSelected = step === selectedStep;
  const isPrevious = step < selectedStep && !isDisabled;
  const isClickable = !isSelected && (step < 3 || hasSolution) && !solutionJson;

  return (
    <StyledContainer>
      <StyledCircle
        id={`button-step-${step}`}
        disabled={isDisabled}
        selected={isSelected}
        title={solutionJson ? jsonViewer : undefined}
        previous={isPrevious}
        clickable={isClickable}
        onClick={() => onStepClick(isClickable)}
      >
        {!isPrevious && step}
        {isPrevious && <StyledIcon icon={CheckIconStyle} mini />}
      </StyledCircle>
      <StyledLabel selected={isSelected}>{getSafeValue(headerLabels, step - 1)}</StyledLabel>
    </StyledContainer>
  );
};

export default withTranslation(WizardHeaderStep);
