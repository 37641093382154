import React from 'react';
import styled from '@emotion/styled';
import { colors, fontSize } from '../../../global/variables';

export const StyledMainDetail = styled.div(({ light }) => ({
  lineHeight: '1rem',
  padding: '0.2rem',
  color: colors.secondaryTextColor,
  fontSize: fontSize.small,
  backgroundColor: colors.white,
  paddingBottom: light ? '0.1rem' : '0.5rem',
  fontWeight: light ? '500' : '700',
}));

const UnassignedNotices = ({ unassignedOrder, notices, noticesTrans }) => {
  if (!unassignedOrder || !notices) return null;

  return (
    <>
      {notices.map((noticeElem) => {
        const noticeArray = noticeElem?.notices;
        if (!noticeArray) return '';

        return noticeArray.map((notice, idx) => {
          const title = notice?.title;
          if (unassignedOrder.Address === noticeElem.jobId && title)
            return (
              <StyledMainDetail
                key={idx}
                light
              >{`${noticesTrans} (${idx}): ${title}`}</StyledMainDetail>
            );
          return '';
        });
      })}
    </>
  );
};

export default UnassignedNotices;
