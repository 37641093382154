import { isEmpty, keys } from 'lodash';
import { CSV_HEADERS } from './config';
import { getCSVHeaders } from './parser';

export function isOriginalField(fieldName) {
  const original = keys(CSV_HEADERS).filter((key) => CSV_HEADERS[key].columnName === fieldName);
  return !isEmpty(original) && CSV_HEADERS[original[0]].trans;
}

export function suggestField(fieldName, translations) {
  const isOriginal = isOriginalField(fieldName);
  if (isOriginal) return isOriginal;

  const labels = keys(translations).map((key) => translations[key].toLowerCase());
  const fn = fieldName.toLowerCase();
  const transFound = labels.filter(
    (label) => fn === label || fn.includes(label) || label.includes(fn),
  );

  if (isEmpty(transFound)) return null;
  const keysFound = keys(translations).filter((key) =>
    transFound.includes(translations[key].toLowerCase()),
  );
  return keysFound[0].replace('Trans', '');
}

export function getInitialFieldsConfig(headers, tourPlanner, user, translations) {
  const config = {};
  const heading = headers || [];
  const isUsingCookies = user && user.cookies && user.cookies.accept;
  const planner = isUsingCookies && tourPlanner ? tourPlanner.csvConfig : {};
  const includedHeaders = !isEmpty(heading) ? heading : keys(planner);
  includedHeaders.forEach((header) => {
    config[header] =
      planner[header] === undefined || planner[header] === null
        ? suggestField(header, translations)
        : planner[header] || '';
  });

  return config;
}

export function isLatLonConfigured(fieldsConfig) {
  if (!fieldsConfig) return false;

  const fieldsLat = keys(fieldsConfig).filter((f) => fieldsConfig[f] === 'latitude');
  const fieldsLng = keys(fieldsConfig).filter((f) => fieldsConfig[f] === 'longitude');
  return !isEmpty(fieldsLat) && !isEmpty(fieldsLng);
}

export function isAddressConfigured(fieldsConfig) {
  if (!fieldsConfig) return false;

  const fieldsAddress = keys(fieldsConfig).filter((f) => fieldsConfig[f] === 'address');
  return !isEmpty(fieldsAddress) || isLatLonConfigured(fieldsConfig);
}

export function isCSVUnderstood(file, tourPlanner, user, translations) {
  return getCSVHeaders(file).then((headers) => {
    const initial = getInitialFieldsConfig(headers, tourPlanner, user, translations);
    const hasAddress = isAddressConfigured(initial);
    const isUsingCookies = user && user.cookies && user.cookies.accept;
    if (!hasAddress) return false;

    if (!isUsingCookies || isEmpty(tourPlanner.csvConfig)) {
      const notUnderstood = keys(initial).filter((key) => isEmpty(initial[key]));
      return isEmpty(notUnderstood);
    }

    const newHeaders = headers.filter((header) => tourPlanner.csvConfig[header] === undefined);
    return isEmpty(newHeaders);
  });
}

const selectedCategories = ['name', 'id', 'phone', 'email', 'notes'];

export const buildSample = (record, categories) => {
  const sample = [];
  const usedCategories = [];
  if (record.Address) {
    sample.push({ category: 'address', value: record.Address });
    usedCategories.push('address');
  } else {
    sample.push({ category: 'location', value: `${record.Latitude}, ${record.Longitude}` });
    usedCategories.push('location', 'latitude', 'longitude');
  }

  categories.forEach((category) => {
    const header = CSV_HEADERS[category];
    const value = header && record[header.internalColumnName];
    if (value && !usedCategories.includes(category)) {
      sample.push({ category, value });
      usedCategories.push(category);
    }
  });

  selectedCategories.forEach((category) => {
    const header = CSV_HEADERS[category];
    const value = record[header.columnName];
    if (!usedCategories.includes(category)) {
      sample.push({ category, value });
      usedCategories.push(category);
    }
  });
  return sample;
};

export const isCategoryConfigured = (category, csvConfig) => {
  const found = keys(csvConfig).filter((key) => csvConfig[key] === category);
  return !isEmpty(found);
};
