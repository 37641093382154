import { GeoJSON } from 'react-leaflet';
import React, { useCallback } from 'react';
import { mapDataKey } from '../../../utils/map/MapCalculations';
import { featureFilterUnselectedMarker, filterDepot } from '../../../utils/map/MapFeatureFilters';

const UnselectedMarkers = ({
  mapData,
  user,
  display,
  routingRoutes,
  makeMapMarkers,
  displayUnselected,
  highlightUnassigned,
}) => {
  const depotLocation = filterDepot(mapData, true)
    .filter((f) => f.properties.stopLocation)
    .map((s) => s.properties.stopLocation);

  const filterMarkersUnselectedFeatures = useCallback(
    (feature) => {
      return featureFilterUnselectedMarker(feature, display, depotLocation);
    },
    [display, depotLocation],
  );

  return (
    displayUnselected &&
    user.mapSettings.showStops && (
      <GeoJSON
        key={`${mapDataKey(
          mapData,
          user,
          display,
          routingRoutes,
          highlightUnassigned,
        )}-marker-unselected`}
        data={filterDepot(mapData, false)}
        pointToLayer={makeMapMarkers}
        filter={filterMarkersUnselectedFeatures}
      />
    )
  );
};

export default UnselectedMarkers;
