import React from 'react';
import styled from '@emotion/styled';
import withTranslation from 'hoc/withTranslation';

const StyledGroup = styled.div(({ gridValue }) => ({
  display: 'grid',
  gridTemplateColumns: gridValue,
  justifyContent: 'space-between',
}));

const SummaryRow = ({ gridValue, children }) => {
  return <StyledGroup gridValue={gridValue}>{children}</StyledGroup>;
};

export default withTranslation(SummaryRow);
