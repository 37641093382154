import { isEmpty } from 'lodash';
import L from 'leaflet';
import {
  createOrderClusterMarkerMultipleSvg,
  createStopClusterMarkerDoubleSvg,
  createStopClusterMarkerMultipleSvg,
  createOrderClusterMarkerDoubleSvg,
} from '../../icons/marker';
import { isActivityToDisplay } from './MapFeatureFilters';
import { getTitle } from './MapMarkersUtils';

const getClusterMarkerSvg = (
  type,
  additionalClass,
  jobMarkerColor,
  displayInfo,
  jobType,
  highlight,
) => {
  let colorToUse;

  if (type === 'activity') {
    colorToUse = jobType === 'pickup' ? jobMarkerColor.pickup : jobMarkerColor.delivery;
    return createStopClusterMarkerDoubleSvg(
      colorToUse.inner,
      highlight ? 'yellow' : colorToUse.outer,
      14,
      20,
      displayInfo,
    );
  }
  if (type === 'stop') {
    colorToUse = jobType === 'pickup' ? jobMarkerColor.pickup : jobMarkerColor.delivery;

    if (additionalClass === 'double') {
      return createStopClusterMarkerDoubleSvg(
        colorToUse.inner,
        highlight ? 'yellow' : colorToUse.outer,
        40,
        46,
        displayInfo,
        colorToUse.text,
      );
    }
    return createStopClusterMarkerMultipleSvg(
      colorToUse.inner,
      highlight ? 'yellow' : colorToUse.outer,
      40,
      46,
      displayInfo,
      colorToUse.text,
    );
  }
  if (additionalClass === 'double') {
    return createOrderClusterMarkerDoubleSvg(40, 46, displayInfo);
  }
  return createOrderClusterMarkerMultipleSvg(40, 46, displayInfo);
};

const getActivityClusterPopupContent = (labels, translations) => {
  return `<b>${translations.wizard.orders.activityCount}: ${labels.length}</b>${getTitle(
    translations,
    labels,
  )}`;
};

export const createClusterCustomIcon = (cluster, highlight, map, translations) => {
  const childs = cluster.getAllChildMarkers();
  const labels = [];
  childs.forEach((c) => {
    if (c.feature.properties.labels) labels.push(c.feature.properties.labels[0]);
  });
  if (childs.every((child) => isActivityToDisplay(child.feature)) && childs.length <= 25) {
    cluster.on('mouseover', (c) => {
      L.popup()
        .setLatLng(c.latlng)
        .setContent(getActivityClusterPopupContent(labels, translations))
        .openOn(map);
    });
    cluster.on('mouseout', () => {
      map.closePopup();
    });
  }

  const tmpClusters = cluster.getAllChildMarkers();
  tmpClusters.sort((a, b) => {
    return a.feature.properties.jobOrder - b.feature.properties.jobOrder;
  });
  const childrenForRoute = tmpClusters.filter((c) => c.feature.properties.routeId !== undefined);
  const isSolution = !isEmpty(childrenForRoute);
  const isActivity = isActivityToDisplay(tmpClusters[0].feature);
  const featureProperties = tmpClusters[0].feature.properties;
  const hasUnassigned = featureProperties.reason && featureProperties.reason.length > 0;
  const highlighted =
    highlight.id &&
    tmpClusters.some(
      (tmp) =>
        highlight.tourId === tmp.feature.properties.routeId &&
        ((tmp.feature.properties.jobOrder && tmp.feature.properties.jobOrder === highlight.id) ||
          (tmp.feature.properties.stopIndex && tmp.feature.properties.stopIndex === highlight.id)),
    );

  const type = hasUnassigned
    ? 'unassigned'
    : isActivity
    ? 'activity'
    : isSolution
    ? 'stop'
    : 'order';
  const displayInfo = isActivity
    ? ''
    : isSolution
    ? featureProperties.jobOrder
    : cluster.getChildCount();

  const jobMarkerColor = featureProperties.jobMarkerColor;
  const jobType = featureProperties.types[0];
  const additionalClass = cluster.getChildCount() === 2 ? 'double' : 'multiple';
  const options = {
    html: L.Util.template(
      getClusterMarkerSvg(type, additionalClass, jobMarkerColor, displayInfo, jobType, highlighted),
    ),
    className: `marker-cluster marker-cluster-${type} marker-cluster-${additionalClass}`,
    iconAnchor: isActivity ? [7, 10] : [20, 23],
  };

  return new L.DivIcon(options);
};
