import { GeoJSON } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import React, { useCallback } from 'react';
import { mapDataKey } from '../../../utils/map/MapCalculations';
import {
  ACTIVITY_DEFAULT_CLUSTER_RADIUS,
  ACTIVITY_MIN_CLUSTER_RADIUS,
  CLUSTER_BREAKPOINT_MAP_ZOOM,
} from './MapMarkers';
import { filterActivities, isActivityToDisplay } from '../../../utils/map/MapFeatureFilters';

const ActivityMarkers = ({
  mapData,
  user,
  display,
  routingRoutes,
  highlightStop,
  makeMapMarkers,
  createClusterIcon,
}) => {
  const showStopClusters = user.mapSettings.showStopClusters;

  const filterActivityFeatures = (feature) => isActivityToDisplay(feature);
  const activities = useCallback(() => {
    return filterActivities(mapData, display);
  }, [mapData, display]);

  return (
    <MarkerClusterGroup
      key={`${mapDataKey(
        mapData,
        user,
        display,
        routingRoutes,
        highlightStop,
        showStopClusters,
      )}-activity-cluster`}
      iconCreateFunction={createClusterIcon}
      showCoverageOnHover={false}
      maxClusterRadius={(level) =>
        !showStopClusters
          ? 0
          : level > CLUSTER_BREAKPOINT_MAP_ZOOM
          ? ACTIVITY_MIN_CLUSTER_RADIUS
          : ACTIVITY_DEFAULT_CLUSTER_RADIUS
      }
    >
      <GeoJSON
        key={`${mapDataKey(mapData, user, display, routingRoutes, highlightStop)}-activity-marker`}
        data={activities()}
        pointToLayer={makeMapMarkers}
        filter={filterActivityFeatures}
      />
    </MarkerClusterGroup>
  );
};

export default ActivityMarkers;
