import { isEmpty, last, head } from 'lodash';
import { isMobileDevice } from './helpers';

const isMobile = isMobileDevice();
export const functionalities = [
  'Generic',
  'Fleet',
  'CSV',
  'ManualOrder',
  'Plan',
  'TourDetails',
  'SharePDF',
  'ShareCSV',
  'ShareEmail',
  'Map',
  'Customer',
];

export function hasDispatched(usageContext) {
  if (!usageContext) return null;
  const dispatched = isEmpty(usageContext.today.dispatchModes)
    ? usageContext.global.dispatchModes
    : usageContext.today.dispatchModes;
  return isEmpty(dispatched) ? null : last(dispatched);
}

export function hasAnsweredTo(usageContext, question) {
  if (!usageContext || !question) return false;
  return usageContext.feedback.answers.includes(question);
}

const closestTo = (usageContext, fn) => {
  const general = ['Generic', 'Map', 'Customer'];
  if (!hasAnsweredTo(usageContext, fn)) return fn;
  const filtered = general.filter((generalFn) => !hasAnsweredTo(usageContext, generalFn));
  if (!isEmpty(filtered)) return head(filtered);
  const unanswered = functionalities.filter((generalFn) => !hasAnsweredTo(usageContext, generalFn));
  return head(unanswered) || functionalities[0];
};

export function getInitialContext(usageContext) {
  if (!usageContext || !usageContext.currentStep) return functionalities[0];
  if (usageContext.currentStep === 1) return closestTo(usageContext, 'Fleet');
  if (usageContext.currentStep === 2) {
    if (usageContext.today.addOrdersModes.includes('manual'))
      return closestTo(usageContext, 'ManualOrder');
    if (usageContext.today.addOrdersModes.includes('import')) return closestTo(usageContext, 'CSV');
    const chosenMode = isMobile ? 'ManualOrder' : 'CSV';
    return closestTo(usageContext, chosenMode);
  }

  const dispatched = hasDispatched(usageContext);
  const hasDispatchedEmail = ['email', 'share'].includes(dispatched);
  if (
    !hasAnsweredTo(usageContext, 'ShareCSV') &&
    (hasDispatched(usageContext) === 'csv' || hasDispatched(usageContext) === 'csv-all')
  ) {
    return closestTo(usageContext, 'ShareCSV');
  }
  if (!hasAnsweredTo(usageContext, 'SharePDF') && hasDispatched(usageContext) === 'pdf') {
    return closestTo(usageContext, 'SharePDF');
  }
  if (!hasAnsweredTo(usageContext, 'ShareEmail') && hasDispatchedEmail) {
    return closestTo('ShareEmail');
  }
  if (usageContext.currentStep === 3) return closestTo(usageContext, 'Plan');
  if (usageContext.currentStep === 4) return closestTo(usageContext, 'Tour Details');
  return closestTo(usageContext);
}
