import React from 'react';
import styled from '@emotion/styled';
import { colors, fontSize } from 'global/variables';
import withTranslation from 'hoc/withTranslation';
import { cutString } from 'utils/helpers';
import { StyledInfoEllipsis } from '../Global/WizardStyled';

const { secondaryTextColor, secondaryMarkerColor, white } = colors;
const { small } = fontSize;

const StyledStopDetails = styled.div({
  lineHeight: '1rem',
  padding: '0.2rem',
  color: secondaryTextColor,
  fontSize: small,
  backgroundColor: white,
  paddingBottom: '0.5rem',
  animation: 'EntryAnimation .3s 1',
  animationTimingFunction: 'ease-out',
  fontWeight: '700',
});

const StyledStopDetailsSpan = styled.div({
  textAlign: 'left !important',
  width: '100%',
});

const StyledStopDetailsSpanTruncated = styled(StyledInfoEllipsis)({
  textAlign: 'left !important',
  maxWidth: '4rem',
});

const StyledStopDetailsSpanHeader = styled.div({
  paddingTop: '0.6rem',
  paddingBottom: '0',
  textTransform: 'uppercase',
  textAlign: 'left !important',
  width: '100%',
  color: secondaryMarkerColor,
  animation: 'EntryAnimationHeader .3s 1 !important',
  animationTimingFunction: 'ease-out',
  fontWeight: '400',
});

const StopDetail = ({ order, isDemandVisible }) => {
  const showDemand = isDemandVisible && order.Demand;
  return (
    <>
      <StyledStopDetails>
        <StyledStopDetailsSpan
          data-test-id="StopDetailName"
          title={order.Address ? order.Address : ''}
        >
          {cutString(order.Name || order.Activity || '--')}
        </StyledStopDetailsSpan>
      </StyledStopDetails>
      <StyledStopDetails title={showDemand ? order.Demand.join(', ') : undefined}>
        {showDemand && order.Demand[0]}
        {showDemand && order.Demand.length > 1 && '...'}
      </StyledStopDetails>
      <StyledStopDetails>
        <StyledStopDetailsSpanTruncated title={order.InternalID ? order.InternalID : ''}>
          {order.InternalID || order.ID || '-'}
        </StyledStopDetailsSpanTruncated>
      </StyledStopDetails>
      <StyledStopDetails />
      <StyledStopDetails />
    </>
  );
};

const StopDetails = withTranslation(
  ({
    orders,
    demandLabel,
    isDemandVisible,
    translations: {
      wizard: {
        toursDetails: { dropsByCustomer },
      },
    },
  }) => {
    return (
      <>
        <>
          <StyledStopDetails>
            <StyledStopDetailsSpanHeader>{dropsByCustomer}</StyledStopDetailsSpanHeader>
          </StyledStopDetails>
          <StyledStopDetails>
            <StyledStopDetailsSpanHeader>{demandLabel}</StyledStopDetailsSpanHeader>
          </StyledStopDetails>
          <StyledStopDetails>
            <StyledStopDetailsSpanHeader>ID</StyledStopDetailsSpanHeader>
          </StyledStopDetails>
          <StyledStopDetails />
          <StyledStopDetails />
        </>
        {orders &&
          orders.map((order, i) => (
            <StopDetail order={order} key={i} isDemandVisible={isDemandVisible} />
          ))}
      </>
    );
  },
);

export default withTranslation(StopDetails);
