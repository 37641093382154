import { GET_SOLUTION, SET_ERROR, CLEAR_SOLUTION, DELETE_SOLUTION, GET_OAUTH } from 'actions';

export default (state = null, action) => {
  switch (action.type) {
    case SET_ERROR:
      if (!action.payload) return null;
      if (typeof action.payload === 'string') {
        return { errorMessage: action.payload };
      }
      return action.payload;
    case CLEAR_SOLUTION:
      return null;
    case DELETE_SOLUTION:
      return null;
    case GET_SOLUTION:
      if (action.payload.error) {
        if (action.payload.errorData) {
          if (
            action.payload.errorData.error &&
            action.payload.errorData.error_description &&
            (action.payload.errorData.error === 'Unauthorized' ||
              action.payload.errorData.error === 'Forbidden')
          ) {
            return { errorMessage: action.payload.errorData.error_description, isErrorTPA: true };
          }
          if (action.payload.errorData.cause) {
            return { errorMessage: action.payload.errorData.cause, isErrorTPA: true };
          }
        }
        return { errorCode: 'contactTrans', isErrorTPA: true };
      }
      return null;
    case GET_OAUTH:
      if (action.error) {
        return {
          errorCode: 'fatalError',
          isFatal: true,
        };
      }
      return null;
    default:
      return state;
  }
};
