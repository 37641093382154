import React from 'react';
import withTranslation from 'hoc/withTranslation';
import styled from '@emotion/styled';
import { StyledText } from '../Global/WizardStyled';
import Label from '../../Form/Label';

const StyledGroup = styled.div(
  {
    flex: '1 1 0',
    marginRight: '1rem',
  },
  ({ isOverflow }) => ({
    overflow: isOverflow && 'hidden',
    textOverflow: isOverflow && 'ellipsis',
    whiteSpace: isOverflow && 'nowrap',
  }),
);

const DetailElement = ({ label, text, overflow, title, textTitle, diffDays, fontSize }) => {
  return (
    <StyledGroup isOverflow={overflow} title={title}>
      <Label>{label}</Label>
      <StyledText
        data-test-id="tourDetailsValue"
        diffDays={diffDays}
        fontSize={fontSize}
        title={textTitle}
      >
        {text}
      </StyledText>
    </StyledGroup>
  );
};

export default withTranslation(DetailElement);
