import FileSaver from 'file-saver';
import { isEmpty, keys } from 'lodash';
import { getFileDelimiter, getGenericFilename, prepareValueToExport } from './SolutionExport';
import { getSafeValue } from './security';

const fieldsToExclude = [
  'InternalID',
  'rowIndex',
  'isEditing',
  'ServiceTime',
  'ClusterID',
  'LatitudeAlt',
  'LongitudeAlt',
];

export function saveUnassignedToCSVFile(unassignedJobs, orders, user) {
  if (isEmpty(unassignedJobs) || isEmpty(orders)) return null;

  const headers = keys(orders[0]).filter((k) => !fieldsToExclude.includes(k));
  const delimiter = getFileDelimiter(user);
  const unassignedOrderIDS = unassignedJobs.map((job) => job.jobId);
  const unassignedOrders = orders.filter((order) => unassignedOrderIDS.includes(order.InternalID));

  const rows = unassignedOrders.map((order) => {
    const values = headers.map((h) => prepareValueToExport(getSafeValue(order, h)));
    const job = unassignedJobs.find((jobItem) => jobItem.jobId === order.InternalID);
    const reasons = job.reasons;
    values.push(`"${reasons.map((r) => r.description).join('. ')}"`);
    return `${values.join(delimiter)}\n`;
  });

  const csv = [`${headers.join(delimiter)}${delimiter}Reasons\n`, ...rows];
  const blob = new Blob(csv, { type: 'text/csv;charset=utf-8;' });
  const fileName = getGenericFilename(user, 'Unassigned Orders', '', '.csv');
  return FileSaver.saveAs(blob, fileName);
}

export function saveOrdersToCSVFile(orders, user, fileNameBase) {
  if (isEmpty(orders)) return null;

  const headers = keys(orders[0]).filter((k) => !fieldsToExclude.includes(k));
  const delimiter = getFileDelimiter(user);
  const rows = orders.map((order) => {
    const values = headers.map((h) => prepareValueToExport(getSafeValue(order, h)));
    return `${values.join(delimiter)}\n`;
  });

  const csv = [`${headers.join(delimiter)}\n`, ...rows];
  const blob = new Blob(csv, { type: 'text/csv;charset=utf-8;' });
  const fileName = getGenericFilename(user, fileNameBase, '', 'csv');
  return FileSaver.saveAs(blob, fileName);
}

export function saveNotLocatedOrdersToCSVFile(orders, user) {
  return saveOrdersToCSVFile(orders, user, 'Not Located Orders -');
}
