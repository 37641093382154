import React, { useCallback, useState, useEffect } from 'react';
import withTranslation from 'hoc/withTranslation';
import styled from '@emotion/styled';
import Checkbox from '../../Form/Checkbox';
import TerminationCriteriaList from './TerminationCriteriaList';

const StyledTerminationCriteriaContainer = styled.div({
  padding: '1rem',
});

const TerminationCriteria = ({
  terminationCriteria,
  onChange,
  translations: {
    solverConfigLabels: { terminationCriteriaLabels },
  },
}) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    setEnabled(terminationCriteria.enabled);
  }, [terminationCriteria]);

  const handleOnChangeTerminationCriteria = useCallback(
    (newTerminationCriteria) => {
      const toReturn = { enabled, values: newTerminationCriteria };
      onChange(toReturn);
    },
    [onChange, enabled],
  );

  const handleOnSwitchChange = useCallback(
    (val) => {
      setEnabled(val);
      if (onChange) onChange({ enabled: val });
    },
    [onChange],
  );

  return (
    <StyledTerminationCriteriaContainer data-testid="terminationCriteriaToggle">
      <Checkbox
        id="termination-criteria-enabler"
        label={terminationCriteriaLabels.switchLabel}
        value={enabled}
        contrast
        onChange={handleOnSwitchChange}
      />
      {enabled && (
        <TerminationCriteriaList
          terminationCriteria={terminationCriteria.values}
          onChange={handleOnChangeTerminationCriteria}
        />
      )}
    </StyledTerminationCriteriaContainer>
  );
};

export default withTranslation(TerminationCriteria);
