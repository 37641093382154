import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setUserParam } from '../../../actions';
import { StyledSelectInput } from '../../Wizard/Global/WizardStyled';

const CurrencySelector = ({ user }) => {
  const dispatch = useDispatch();
  const handleSetUserParam = useCallback(
    (currency) => dispatch(setUserParam({ currency })),
    [dispatch],
  );

  const onChange = useCallback(
    (e) => {
      handleSetUserParam(e.target.value);
    },
    [handleSetUserParam],
  );

  return (
    <StyledSelectInput contrast sizeL onChange={onChange} value={user.currency}>
      <option value="EUR">Euro</option>
      <option value="USD">US Dollar</option>
      <option value="JPY">Japanese Yen</option>
      <option value="GBP">Great Britain Pound</option>
    </StyledSelectInput>
  );
};

export default CurrencySelector;
