import React, { useEffect, useState } from 'react';
import QRCode from 'qrcode.react';

const styles = {
  'tour-dispatch': {
    width: 35,
    height: 35,
  },
  'qr-panel': {
    width: 320,
    height: 320,
    marginLeft: '1rem',
    marginTop: '1rem',
    border: '1rem solid white',
  },
};

const QRCodeBase64 = ({ url, id, onGenerateQRCode, qrStyle }) => {
  const [display, setDisplay] = useState(true);
  const QRPanel = qrStyle === 'qr-panel';

  useEffect(() => {
    const qrEl = document.getElementById(id);
    const qrCodeDataUri = !QRPanel && qrEl.toDataURL('image/jpg');
    if (onGenerateQRCode) onGenerateQRCode(id, qrCodeDataUri);
    if (qrStyle === 'tour-dispatch') setDisplay(false);
  }, [setDisplay, onGenerateQRCode, id, qrStyle, QRPanel]);

  return (
    display && (
      <QRCode value={url} id={id} style={styles[qrStyle]} renderAs={QRPanel ? 'svg' : 'canvas'} />
    )
  );
};

export default QRCodeBase64;
