import amplitude from 'amplitude-js';
import moment from 'moment';
import { isEmpty } from 'lodash';
import config from '../config';
import createHash from './hash256';
import { getUrlTest } from './urlHelpers';
import { getStorageValue, STORAGE_IDS } from './localStorageHelpers';
import { isMobileDevice } from './helpers';
import { setSafeValue } from './security';

let instanceGlobal;
const isMobile = isMobileDevice();

export const AMPLITUDE_EVENTS = {
  COMPANY_NAME_SET: 'company name set',
  DEPOT_SET: 'depot set',
  FLEET_CONFIG_CHANGE_FLEET_TYPE: 'fleet config change fleet type',
  FLEET_CONFIG_CHANGE_VEHICLE_NUMBER: 'fleet config change vehicle number',
  FLEET_CONFIG_CHANGE_CAPACITY: 'fleet config change capacity',
  FLEET_CONFIG_CHANGE_VEHICLE_COSTS: 'fleet config change vehicle costs',
  TIME_CONFIG_CHANGED: 'time config changed',
  ORDERS_ADD: 'orders add',
  ORDERS_ADD_NOT_LOCATED: 'orders add not located',
  ORDERS_CSV_CONFIG: 'orders csv config',
  ORDERS_DELETE_ALL: 'orders delete all',
  ORDERS_DELETE_SINGLE: 'orders delete single',
  TOUR_PLAN: 'tour plan',
  TOUR_OVERVIEW: 'tour overview',
  TOUR_VIEW: 'tour view',
  TOUR_DISPATCH: 'tour dispatch',
  TOUR_STOP_TO_WEGO: 'tour stop link to WeGo',
  TOUR_STOP_CLICK: 'tour stop click',
  TOUR_DOWNLOAD_UNASSIGNED: 'tour download unassigned',
  MAP_MARKER_ACTIVATE_TOUR: 'map marker activate tour',
  MAP_MARKER_ZOOM_IN: 'map marker zoom in',
  ERROR: 'error',
  USER_SET_LANGUAGE: 'user set language',
  CLICK_LINK: 'click link',
  PAGE_LOADED: 'page loaded',
  READ_MORE_LESS: 'read more less',
  FEEDBACK_ACTION: 'feedback action',
  TOP_BAR_CLICK: 'top bar click',
  SOLVER_CONFIG_ACTION: 'solver config action',
  DEVELOPER_CONSOLE_ACTION: 'developer console action',
};

export const FLEET_PROPERTIES = {
  FLEET_SIZE: 'fleetSize',
  VEHICLE_CAPACITY: 'vehicleCapacity',
  HAS_DEPOT: 'hasDepot',
  COMPANY_NAME: 'companyName',
  HAS_COMPANY_NAME: 'hasCompanyName',
  SHIFT_START: 'shiftStart',
  SHIFT_END: 'shiftEnd',
  ORDERS_MODE: 'ordersMode',
  HAS_CSV_CONFIG: 'hasCSVConfig',
  TYPE: 'type',
};

function initialize() {
  instanceGlobal = amplitude.getInstance();
  const agreement = getStorageValue(STORAGE_IDS.cookieAgreement);
  const isOptIn = agreement && agreement.siteImprovementApproved;

  instanceGlobal.init(config.amplitudeApiKey, undefined, {
    optOut: !isOptIn,
    trackingOptions: {
      ip_address: false,
    },
    platform: navigator && navigator.platform ? navigator.platform : 'Web',
    secureCookie: true,
  });

  instanceGlobal.setOptOut(!isOptIn); // reinforcing opt out, since config value is getting lost
  return instanceGlobal;
}

function getInstance() {
  if (getUrlTest()) return null;

  return instanceGlobal || initialize();
}

function setOptIn(optIn) {
  if (getUrlTest()) return;
  const instance = getInstance();
  instance.setOptOut(!optIn);
}

function log(event, eventProperties, forceLog) {
  if (getUrlTest()) return;
  const instance = getInstance();
  if (!forceLog) {
    instance.logEvent(event, eventProperties);
    return;
  }

  const agreement = getStorageValue(STORAGE_IDS.cookieAgreement);
  const isOptIn = agreement && agreement.siteImprovementApproved;

  if (!isOptIn) instanceGlobal.setOptOut(false);
  instance.logEvent(event, eventProperties);
  if (!isOptIn) instanceGlobal.setOptOut(true);
}

function setFleetConfiguration(data) {
  if (getUrlTest()) return;
  const properties = {};
  setSafeValue(properties, FLEET_PROPERTIES.FLEET_SIZE, data.vehicles[0].amount);
  setSafeValue(properties, FLEET_PROPERTIES.VEHICLE_CAPACITY, data.vehicles[0].capacity[0]);
  setSafeValue(properties, FLEET_PROPERTIES.SHIFT_START, data.time.value.start);
  setSafeValue(properties, FLEET_PROPERTIES.SHIFT_END, data.time.value.end);
  setSafeValue(properties, FLEET_PROPERTIES.HAS_DEPOT, !!(data.location && data.location.value));
  setSafeValue(properties, FLEET_PROPERTIES.COMPANY_NAME, data.companyName || '');
  setSafeValue(properties, FLEET_PROPERTIES.HAS_COMPANY_NAME, !isEmpty(data.companyName));
  setSafeValue(properties, FLEET_PROPERTIES.ORDERS_MODE, data.location.ordersMode);
  setSafeValue(properties, FLEET_PROPERTIES.TYPE, data.vehicleProfiles[0].fleetType);
  setSafeValue(properties, FLEET_PROPERTIES.HAS_CSV_CONFIG, !isEmpty(data.csvConfig));
  properties.isMobile = isMobile;

  const instance = getInstance();
  instance.setUserProperties(properties);
}

function setUserParams(user) {
  if (getUrlTest()) return;
  const usage = user.usage || {
    days: 1,
    loads: 1,
    toursPlanned: 0,
    toursDispatched: 0,
    ordersDispatched: 0,
    firstVisitDate: new Date(),
  };
  const properties = {
    selectedLanguage: user.language,
    isMobile,
    hasStateMemory: user.hasStateMemory,
    isDemo: user.isDemo,
    useManual: user.useManual,
    useImport: user.useImport,
    source: user.source,
    daysCount: usage.days,
    loadsCount: usage.loads,
    toursPlannedCount: usage.toursPlanned,
    toursDispatchedCount: usage.toursDispatched,
    ordersDispatchedCount: usage.ordersDispatched,
    firstVisitDate: moment(usage.firstVisitDate).format('YYYY-MM-DD'),
  };
  const instance = getInstance();
  instance.setUserProperties(properties);
}

function setUserId(userId) {
  if (getUrlTest()) return null;
  const instance = getInstance();
  return new Promise((resolve) => {
    if (userId === null) {
      instance.setUserId(userId);
      resolve();
    } else {
      createHash(userId).then((hash) => {
        instance.setUserId(hash);
        resolve();
      });
    }
  });
}

export const AmplitudeService = {
  log,
  setOptIn,
  setUserId,
  setFleetConfiguration,
  setUserParams,
};
