import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { useCookies } from 'react-cookie';
import config from 'config';
import Modal from '../../Presentation/Modal';
import { setUserParam } from '../../../actions';
import Button from '../../Form/Button';
import withTranslation from '../../../hoc/withTranslation';
import {
  StyledContrastPanel,
  StyledContrastPanelFooter,
  StyledContrastPanelHeader,
} from '../../Wizard/Global/WizardStyledContrastPanel';
import {
  StyledText,
  StyledLink,
  StyledTextLight,
  StyledSpace,
} from '../../Wizard/Global/WizardStyled';
import Section from '../../Form/Section';

const {
  documentationLinks: { documentationApiKeyURL, documentationTP },
} = config;

const StyledContainer = styled.div({
  padding: '1rem',
});

const ApiKeyPanel = ({ user, setIsMenuOpen, translations: { apiKeyModal } }) => {
  const dispatch = useDispatch();
  const [cookies] = useCookies(['apikey']);
  const handleSetUserParam = useCallback((data) => dispatch(setUserParam(data)), [dispatch]);

  const onClose = useCallback(() => {
    handleSetUserParam({ apiKeyDialogOpened: true });
  }, [handleSetUserParam]);

  const onOpenMenu = useCallback(() => {
    setIsMenuOpen(true);
    onClose();
  }, [onClose, setIsMenuOpen]);

  return (
    <>
      <Modal
        isDark
        isVisible={!user.apiKeyDialogOpened && (!cookies.apikey || cookies.apikey === '')}
        isFull
        fromTop
      >
        <StyledContrastPanel>
          <StyledContrastPanelHeader size="0">
            <StyledText bold contrast>
              API Key
            </StyledText>
          </StyledContrastPanelHeader>

          <StyledContainer>
            <Section>
              <StyledTextLight contrast>{apiKeyModal.enterApiKeyText}</StyledTextLight>
              <StyledSpace />
              <StyledTextLight contrast>{apiKeyModal.hintToApiKeyDocumentation}</StyledTextLight>
              <StyledSpace />
              <StyledLink
                contrast
                target="_blank"
                rel="noopener noreferrer"
                href={documentationApiKeyURL}
              >
                {apiKeyModal.linkToDocumentation}
              </StyledLink>
              <StyledSpace M />
              <StyledTextLight contrast>{apiKeyModal.hintEvaluationAgreement}</StyledTextLight>
              <StyledSpace />
              <StyledLink contrast target="_blank" rel="noopener noreferrer" href={documentationTP}>
                {apiKeyModal.contactUs}
              </StyledLink>
            </Section>
          </StyledContainer>

          <StyledContrastPanelFooter isDouble isFullWidth>
            <Button text={apiKeyModal.buttonLabelLater} onClick={onClose} inverted contrast />
            <div />
            <Button
              data-testid="goToSettingBtn"
              text={apiKeyModal.buttonLabelMenu}
              onClick={onOpenMenu}
            />
          </StyledContrastPanelFooter>
        </StyledContrastPanel>
      </Modal>
    </>
  );
};

export default withTranslation(ApiKeyPanel);
