import React from 'react';
import { getSafeValue } from '../../../utils/security';
import { StyledInfoEllipsis } from '../../Wizard/Global/WizardStyled';
import { StyledAction } from '../StyledComponents';
import Checkbox from '../../Form/Checkbox';
import { StyledContrastPanelColumn } from '../../Wizard/Global/WizardStyledContrastPanel';

const SingleActionSetting = ({ setting, mapSettings, mapSettingsPanel, handleOnSwitchChange }) => {
  return (
    <StyledContrastPanelColumn
      id={setting.value}
      key={setting.value}
      actionsCount={2}
      inputSize="XS"
      isEnabled={getSafeValue(mapSettings, setting.value)}
    >
      <StyledInfoEllipsis
        alt={getSafeValue(mapSettingsPanel, setting.value)}
        title={getSafeValue(mapSettingsPanel, setting.value)}
        hasMarginTop
      >
        {getSafeValue(mapSettingsPanel, setting.value)}
      </StyledInfoEllipsis>
      <StyledAction>
        <Checkbox
          id={`mapSettingsCheckbox-${setting.value}`}
          value={getSafeValue(mapSettings, setting.value)}
          contrast
          onChange={(value) => handleOnSwitchChange(setting.value, value)}
        />
      </StyledAction>
    </StyledContrastPanelColumn>
  );
};

export default SingleActionSetting;
