import { GeoJSON } from 'react-leaflet';
import React, { useCallback } from 'react';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { mapDataKey } from '../../../utils/map/MapCalculations';
import {
  featureFilterCluster,
  filterDepot,
  isActivityToDisplay,
} from '../../../utils/map/MapFeatureFilters';
import { getStyle } from '../../../utils/map/MapUtils';
import {
  CLUSTER_BREAKPOINT_MAP_ZOOM,
  DEFAULT_CLUSTER_RADIUS,
  MIN_CLUSTER_RADIUS,
} from './MapMarkers';

const JobMarkers = ({
  mapData,
  user,
  display,
  routingRoutes,
  highlightStop,
  makeMapMarkers,
  createClusterIcon,
  isSolution,
  routingData,
}) => {
  const filterMarkersClusterFeatures = useCallback(
    (feature) => {
      return featureFilterCluster(
        feature,
        display,
        routingData,
        user.mapSettings.showStraightRouteLines,
      );
    },
    [display, routingData, user.mapSettings.showStraightRouteLines],
  );
  const clusteredData = useCallback(() => {
    const start = filterDepot(mapData, false);
    return start.filter((f) => !isActivityToDisplay(f));
  }, [isSolution, mapData, display]);

  const Markers = () => (
    <GeoJSON
      key={mapDataKey(mapData, user, display, routingRoutes, highlightStop)}
      data={clusteredData()}
      pointToLayer={makeMapMarkers}
      style={getStyle}
      filter={filterMarkersClusterFeatures}
    />
  );

  return user.mapSettings.showJobClusters ? (
    <MarkerClusterGroup
      key={`${mapDataKey(mapData, user, display, routingRoutes)}-job-cluster`}
      iconCreateFunction={createClusterIcon}
      showCoverageOnHover={false}
      maxClusterRadius={(level) =>
        level > CLUSTER_BREAKPOINT_MAP_ZOOM ? MIN_CLUSTER_RADIUS : DEFAULT_CLUSTER_RADIUS
      }
    >
      <Markers />
    </MarkerClusterGroup>
  ) : (
    <Markers />
  );
};

export default JobMarkers;
