import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import config from 'config';
import { setUserParam } from '../../../actions';
import { StyledSelectInput } from '../../Wizard/Global/WizardStyled';
import withTranslation from '../../../hoc/withTranslation';

const {
  requestTypes: { async, sync, automatic },
} = config;

const RequestTypeSelector = ({ user, disabled, translations }) => {
  const {
    menu: { requestTypeAutomatic, requestTypeSync, requestTypeAsync, disabledRequestTypeInfo },
  } = translations;
  const dispatch = useDispatch();
  const handleSetUserParam = useCallback(
    (requestType) => dispatch(setUserParam({ requestType })),
    [dispatch],
  );

  const onChange = useCallback(
    (e) => {
      handleSetUserParam(e.target.value);
    },
    [handleSetUserParam],
  );

  return (
    <StyledSelectInput
      data-testid="tpRequestDropdown"
      contrast
      sizeL
      onChange={onChange}
      value={user.requestType}
      disabled={disabled}
      title={disabled ? disabledRequestTypeInfo : undefined}
    >
      <option value={automatic}>{requestTypeAutomatic}</option>
      <option value={sync}>{requestTypeSync}</option>
      <option value={async}>{requestTypeAsync}</option>
    </StyledSelectInput>
  );
};

export default withTranslation(RequestTypeSelector);
