import React from 'react';
import withTranslation from 'hoc/withTranslation';
import styled from '@emotion/styled';

const StyledGroup = styled.div(({ noMarginTop }) => ({
  display: 'grid',
  justifyContent: 'space-between',
  marginTop: noMarginTop || '1rem',
  gridTemplateColumns: '1.6fr 2fr 1.7fr 1.2fr',
  marginBottom: '1rem',
}));

const DetailRow = ({ noMarginTop, children }) => {
  return <StyledGroup noMarginTop={noMarginTop}>{children}</StyledGroup>;
};

export default withTranslation(DetailRow);
