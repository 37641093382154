import React from 'react';
import styled from '@emotion/styled';
import withTranslation from 'hoc/withTranslation';
import { fontSize } from 'global/variables';

const FooterContainer = styled.footer`
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  padding: 0.5rem 0.5rem;
  z-index: 800;
  text-decoration: none;
  color: rgba(0, 0, 0, 0.7);
  line-height: 1rem;

  & a,
  & span {
    padding: 0 0.4rem;
    color: inherit;
    font-family: 'FiraSansRegular', 'Lucida Sans Unicode', 'Lucida Grande', sans-serif;
    font-size: ${fontSize.smaller};
    font-weight: 400;
    text-decoration: none;
  }

  & a:hover {
    color: rgba(0, 0, 0, 1);
  }
`;

const Footer = ({ translations: { legal: legalTranslations }, linkLang }) => {
  return (
    <FooterContainer>
      <div>
        <a
          href="https://legal.here.com/terms/b2bserviceterms"
          target="_blank"
          rel="noopener noreferrer"
        >
          {legalTranslations.terms}
        </a>
        <a
          href={`https://legal.here.com/${linkLang}/privacy/policy`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {legalTranslations.privacyPolicy}
        </a>
        <span>{legalTranslations.copyright}</span>
      </div>
    </FooterContainer>
  );
};

export default withTranslation(Footer);
