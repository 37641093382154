import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { fontSize } from 'global/variables';
import withTranslation from 'hoc/withTranslation';
import { first, isEmpty, keys, size } from 'lodash';
import styled from '@emotion/styled';
import {
  getUnassignedLocations,
  getUnassignedNotices,
  getUnassignedSolutionOrders,
} from 'utils/SolutionHelpers';
import { UNASSIGNED_REASONS } from 'actions';
import UnassignedOrder from './UnassignedOrder';
import { sortOrdersBy } from '../../../utils/OrdersHelpers';
import { StyledIcon } from '../Global/WizardStyled';
import expandIcon from '../../../global/img/accordion-expand.svg';
import collapseIcon from '../../../global/img/accordion-collapse.svg';
import { getSafeValue, setSafeValue } from '../../../utils/security';
import Label from '../../Form/Label';

const StyledReasonContainer = styled.div({
  padding: '0.5rem 0',
  fontSize: fontSize.medium,
});

const StyledReasonTitle = styled.div(() => ({
  cursor: 'pointer',
  display: 'grid',
  gridTemplateColumns: '1fr 2rem',
  gridGap: '2rem',
  width: '100%',
}));

const StyledOrdersContainer = styled.div(({ displayDetails }) => ({
  padding: '0.5rem',
  height: displayDetails ? 'auto' : 0,
  animation: 'EntryAnimation .3s 1',
  animationTimingFunction: 'ease-out',
  overflow: 'hidden',
}));

const StyledContainer = styled.div(({ noTours }) => ({
  maxHeight: noTours ? 'calc(100vh - 28rem)' : 'calc(100vh - 38rem)',
  overflowY: 'auto',
}));

const UnassignedList = ({
  lastIterationTourData,
  orders,
  oAuth,
  tourPlanner,
  solution,
  translations: {
    wizard: {
      tours: { unassignedReasonsTrans, noticesTrans },
    },
  },
  solNotices,
  solUnassigned,
  noTours,
}) => {
  const [reasonsList, setReasonsList] = useState();
  const [notices, setNotices] = useState();
  const [codeInView, setCodeInView] = useState();
  const [orderInView, setOrderInView] = useState();
  const [unassigned, setUnassigned] = useState(lastIterationTourData?.unassigned);
  const allOrders = orders.length === 0 ? solution.requests[solution.show]?.tempOrders : orders;
  const unassignedOrders = UNASSIGNED_REASONS.flatMap(
    (unassignedReason) => reasonsList?.[unassignedReason]?.orders || null,
  ).filter((f) => f !== null);
  const unassignedLocations = useMemo(
    () =>
      getUnassignedLocations(unassigned, lastIterationTourData, unassignedOrders, solUnassigned),
    [unassigned, lastIterationTourData, unassignedOrders, solUnassigned],
  );
  const firstTour = lastIterationTourData?.tours[0];
  const vehicleStartLocation = lastIterationTourData?.fleet?.types?.[0].shifts[0].start.location;
  const origin =
    vehicleStartLocation ||
    (firstTour && (firstTour.stops[0].activities[0].location || firstTour.stops[0].location));

  useEffect(() => {
    setOrderInView();
    setCodeInView();
    if (isEmpty(unassigned)) {
      setReasonsList();
      return;
    }

    const grouped = {};
    unassigned.forEach((item) => {
      item.reasons.forEach(({ code, description }) => {
        if (!getSafeValue(grouped, code)) setSafeValue(grouped, code, { orders: [], description });
        const order = allOrders?.find((o) => o.InternalID === item.jobId);
        if (!order) return;
        getSafeValue(grouped, code).orders.push(order);
      });
    });
    Object.keys(grouped).forEach((key) => {
      grouped[key].orders = sortOrdersBy(grouped[key].orders, 'Address');
    });
    setReasonsList(grouped);
    if (Object.keys(grouped).length === 1) setCodeInView(Object.keys(grouped)[0]);
  }, [unassigned, allOrders]);

  useEffect(() => {
    setOrderInView();
    if (!codeInView) return;
    if (size(getSafeValue(reasonsList, codeInView).orders) === 1)
      setOrderInView(first(getSafeValue(reasonsList, codeInView).orders).InternalID);
  }, [codeInView]);

  const handleOnClickReason = useCallback(
    (reasonCode) => {
      setOrderInView();
      const code = reasonCode === codeInView ? null : reasonCode;
      setCodeInView(code);
    },
    [codeInView],
  );

  const handleOnClickOrder = useCallback(
    (order) => {
      const newOrder = orderInView === order.InternalID ? null : order.InternalID;
      setOrderInView(newOrder);
    },
    [orderInView],
  );

  useEffect(() => {
    if (unassignedLocations.length > 0 && unassignedOrders.length > 0 && !notices) {
      getUnassignedNotices(unassignedLocations, oAuth, tourPlanner, origin).then((response) =>
        setNotices(response),
      );
    }
  }, [oAuth, unassignedOrders]);

  useEffect(() => {
    if (solNotices && solUnassigned) {
      setNotices(solNotices);
      setUnassigned(solUnassigned);
    }
  }, [solNotices, solUnassigned]);

  if (!reasonsList) return null;
  return (
    <StyledContainer noTours={noTours}>
      <Label noTours={noTours}>{unassignedReasonsTrans}</Label>
      {keys(reasonsList).map((reasonCode) => {
        const reason = getSafeValue(reasonsList, reasonCode);
        if (solUnassigned) {
          reason.orders = getUnassignedSolutionOrders(solUnassigned, reasonCode);
        }

        return (
          <StyledReasonContainer key={reasonCode}>
            <StyledReasonTitle noTours={noTours} onClick={() => handleOnClickReason(reasonCode)}>
              <div>
                {reasonCode}: {reason.description} ({size(reason.orders)})
              </div>
              <StyledIcon iconUrl={codeInView === reasonCode ? collapseIcon : expandIcon} />
            </StyledReasonTitle>
            <StyledOrdersContainer displayDetails={codeInView === reasonCode}>
              {codeInView === reasonCode &&
                reason.orders.map((order) => (
                  <UnassignedOrder
                    notices={notices}
                    noticesTrans={noticesTrans}
                    key={order.InternalID}
                    unassignedOrder={order}
                    onClick={handleOnClickOrder}
                    showDetail={orderInView === order.InternalID}
                  />
                ))}
            </StyledOrdersContainer>
          </StyledReasonContainer>
        );
      })}
    </StyledContainer>
  );
};

export default withTranslation(UnassignedList);
