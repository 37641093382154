import React from 'react';
import styled from '@emotion/styled';
import withTranslation from 'hoc/withTranslation';
import { StyledText, StyledTitle } from './SummaryWithDownload';

const StyledDetailContainer = styled.div(({ noMarginBottom }) => ({
  marginRight: '1rem',
  marginTop: '-0.8rem',
  marginBottom: !noMarginBottom && '2rem',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const SummaryElement = ({ label, text, title, textTitle }) => {
  return (
    <StyledDetailContainer title={title}>
      <StyledTitle>{label}</StyledTitle>
      <StyledText data-test-id="tourOverviewDetails" title={textTitle}>
        {text}
      </StyledText>
    </StyledDetailContainer>
  );
};

export default withTranslation(SummaryElement);
