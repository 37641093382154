import React, { useCallback, useState, useEffect, useRef } from 'react';
import { isEmpty, size } from 'lodash';
import styled from '@emotion/styled';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { TIMEOUT_VALUES, clearTourParameter } from 'actions';
import { StyledLocationInput } from './WizardStyled';
import CurrentLocationButton from './CurrentLocationButton';
import { getAutoSuggestItems } from '../../../utils/GeoCoder';
import { colors, fontSize } from '../../../global/variables';
import { isMobileDevice } from '../../../utils/helpers';
import { getSafeValue } from '../../../utils/security';

const { normal } = fontSize;
const { white, transparentGreyColor, black, secondaryBorderColor } = colors;
const isMobile = isMobileDevice();
const autoSuggestLimit = isMobile ? 3 : 5;

const StyledInputContainer = styled.div({
  position: 'relative',
});

const StyledOptionsContainer = styled.div({
  position: 'absolute',
  top: '2.6rem',
  backgroundColor: white,
  width: '100%',
  zIndex: 200,
  boxShadow: '0px 0px 0px 2px rgba(34,205,200,0.4)',
});

const StyledOption = styled.div(
  {
    lineHeight: '2rem',
    fontSite: normal,
    borderBottom: `0.5px solid ${secondaryBorderColor}`,
    padding: '0.4rem 0.8rem',
    cursor: 'pointer',
    color: black,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  ({ isHovered }) => ({
    backgroundColor: isHovered ? white : transparentGreyColor,
  }),
);

const InputLocation = ({
  onLocationFound,
  value,
  placeholder,
  oAuth,
  id,
  icon,
  user,
  tourPlanner,
  hasCurrentLocationControl = false,
}) => {
  const dispatch = useDispatch();
  const solution = useSelector(({ solution: stateSolution }) => stateSolution);

  const [text, setText] = useState(value);
  const [options, setOptions] = useState([]);
  const [hoveredOption, setHoveredOption] = useState();
  const [selectedOption, setSelectedOption] = useState({ label: value });
  const [elementId, setElementId] = useState();
  const isMounted = useRef(true);

  const handleClearTourParameter = useCallback(
    (parameter) => dispatch(clearTourParameter(parameter, solution.show)),
    [dispatch, solution.show],
  );

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    const date = moment().format('MMDDyyyy');
    setElementId(`${id}-${date}`);
  }, [id]);

  const onOptionClick = useCallback(
    (option, skipName, type) => {
      if (!skipName) setText(option.label);
      onLocationFound(option, type);
      setSelectedOption(option);
      setOptions([]);
      setHoveredOption();
    },
    [
      setOptions,
      setHoveredOption,
      setText,
      onLocationFound,
      setSelectedOption,
      handleClearTourParameter,
    ],
  );

  const onChange = useCallback(
    (e) => {
      if (e.target instanceof HTMLInputElement) e.target.removeAttribute('readonly');

      const val = e && e.target && e.target.value;
      setText(val);

      if ((!oAuth || !val || val === '') && isEmpty(options?.label)) {
        setSelectedOption();
        setOptions([]);
        return;
      }

      if (selectedOption && val.trim() !== selectedOption.label) {
        setSelectedOption();
      }

      getAutoSuggestItems(val.trim(), oAuth, user, tourPlanner, autoSuggestLimit).then((res) => {
        setOptions(res);
      });
    },
    [
      setText,
      setOptions,
      oAuth,
      user,
      tourPlanner,
      setSelectedOption,
      selectedOption,
      onOptionClick,
      handleClearTourParameter,
      options,
    ],
  );

  const onBlur = useCallback(() => {
    if (!hoveredOption && !selectedOption) {
      setOptions([]);
      onLocationFound({ label: text, value: null });
    }

    setTimeout(() => {
      if (!isMounted.current) return;

      setHoveredOption();
    }, TIMEOUT_VALUES.LOW);
  }, [setHoveredOption, hoveredOption, onLocationFound, text, selectedOption]);

  const onKeyDown = useCallback(
    (e) => {
      if (e.keyCode === 13) {
        const autoSelected = size(options) === 1 ? options[0] : null;
        const toReturn = hoveredOption ||
          autoSelected ||
          selectedOption || { label: text, value: null };
        setText(toReturn.label);
        onLocationFound(toReturn);
        setSelectedOption(toReturn);
        setOptions([]);
        setHoveredOption();
        return;
      }

      if (e.keyCode === 38 || e.keyCode === 40) {
        const index = hoveredOption
          ? options.findIndex((o) => o.label === hoveredOption.label)
          : -1;
        const newIndex = e.keyCode === 38 ? index - 1 : index + 1;
        const finalIndex = newIndex < 0 ? size(options) - 1 : newIndex;
        const last = finalIndex >= size(options) ? 0 : finalIndex;
        setHoveredOption(getSafeValue(options, last));
        return;
      }

      if (e.keyCode === 27) {
        setOptions([]);
        setHoveredOption();
      }
    },
    [
      options,
      text,
      hoveredOption,
      setOptions,
      setHoveredOption,
      setText,
      onLocationFound,
      setSelectedOption,
      selectedOption,
    ],
  );

  useEffect(() => {
    setText(value);
  }, [value]);

  const onOptionMouseEnter = useCallback(
    (option) => {
      setHoveredOption(option);
    },
    [setHoveredOption],
  );

  const onOptionMouseLeave = useCallback(() => {
    setHoveredOption();
  }, [setHoveredOption]);

  const onCurrentLocationAddressFound = useCallback(
    (address) => {
      setText(address.title);
      onLocationFound({ label: address.title, value: address.position });
    },
    [setText, onLocationFound],
  );
  const currentLocationControl =
    hasCurrentLocationControl && user.location !== null ? (
      <CurrentLocationButton
        onAddressFound={onCurrentLocationAddressFound}
        user={user}
        oAuth={oAuth}
      />
    ) : null;

  return (
    <StyledInputContainer>
      <StyledLocationInput
        autoComplete="off"
        id={elementId}
        placeholder={placeholder}
        onChange={onChange}
        value={text}
        onFocus={onChange}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        icon={icon}
        readOnly
        type="new-password"
        spellCheck={false}
        hasControl={currentLocationControl !== null}
      />
      {currentLocationControl}
      {!isEmpty(options) && (
        <StyledOptionsContainer id={`${id}-options`}>
          {options.map((option, index) => (
            <StyledOption
              key={index}
              isHovered={!hoveredOption || hoveredOption.label !== option.label}
              onClick={() => onOptionClick(option)}
              onMouseEnter={() => onOptionMouseEnter(option)}
              onMouseLeave={onOptionMouseLeave}
              id={`${id}-option-${index}`}
            >
              {option.label}
            </StyledOption>
          ))}
        </StyledOptionsContainer>
      )}
    </StyledInputContainer>
  );
};

export default InputLocation;
