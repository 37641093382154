const MINUTES_IN_HOUR = 60;
const SECONDS_IN_MINUTES = 60;
const SECONDS_IN_HOUR = MINUTES_IN_HOUR * SECONDS_IN_MINUTES;
const KM_IN_MILE = 1.609;
const METERS_IN_KM = 1000;

export const costPerSecondToCostPerHour = (costPerSecond) => costPerSecond * SECONDS_IN_HOUR;
export const costPerHourToCostPerSecond = (costPerHour) => costPerHour / SECONDS_IN_HOUR;
export const costPerKmToCostPerMeter = (costPerKm) => costPerKm / METERS_IN_KM;
export const costPerMeterToCostPerKm = (costPerMeter) => costPerMeter * METERS_IN_KM;
export const costPerMileToCostPerMeter = (costPerMile) => costPerMile / KM_IN_MILE / METERS_IN_KM;
export const costPerMeterToCostPerMile = (costPerMeter) => costPerMeter * KM_IN_MILE * METERS_IN_KM;

export const convertCostsFromTourPlanner = ({
  time = 0,
  distance = 0,
  fixed = 0,
  isImperial = false,
}) => {
  const convertDistance = isImperial ? costPerMileToCostPerMeter : costPerKmToCostPerMeter;

  return {
    ...(time !== 0 && { time: costPerHourToCostPerSecond(time) }),
    ...(distance !== 0 && { distance: convertDistance(distance) }),
    ...(fixed !== 0 && { fixed }),
  };
};

export const convertCostsToTourPlanner = ({
  time = 0,
  distance = 0,
  fixed = 0,
  isImperial = false,
}) => {
  const convertDistance = isImperial ? costPerMeterToCostPerMile : costPerMeterToCostPerKm;

  return {
    ...(time !== 0 && { time: costPerSecondToCostPerHour(time) }),
    ...(distance !== 0 && { distance: convertDistance(distance) }),
    ...(fixed !== 0 && { fixed }),
  };
};
