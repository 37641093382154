import { isEmpty, keys, size, uniq } from 'lodash';
import { decode } from 'utils/flexpolyline';
import concaveman from 'concaveman';
import { calculateBBox } from '../GeoJSONConverter';
import { getPolygonFromBbox } from './TerritoriesHelpers';
import { getAllLocationsInTour } from '../SolutionHelpers';

export const AREA_TYPES = {
  AVOID_AREA: 'avoid_area',
  TERRITORY: 'territory',
  GROUP: 'group',
  TOUR_HULL_CONVEX: 'tour_hull_convex',
  TOUR_HULL_CONCAVE: 'tour_hull_concave',
};

export const AVOID_TYPES = {
  BOUNDING_BOX: 'boundingBox',
  POLYGON: 'polygon',
  ENCODED_POLYGON: 'encodedPolygon',
};

export const counterClockWise = (a, b, c) => {
  // cross product of two vectors indicate whether an angle is clockwise or counterclockwise
  return (b[0] - a[0]) * (c[1] - a[1]) - (b[1] - a[1]) * (c[0] - a[0]);
};

export const distanceSquared = (a, b) => {
  return (b[0] - a[0]) ** 2 + (b[1] - a[1]) ** 2;
};

export const sortPoints = (points, lowerMostPoint) => {
  return points.sort((a, b) => {
    // sort by angle from right to left
    if (a === lowerMostPoint) return -1;
    if (b === lowerMostPoint) return 1;

    const ccw = counterClockWise(lowerMostPoint, a, b);

    if (ccw === 0) {
      // If collinear, sort by distance from lowerMostPoint
      return distanceSquared(lowerMostPoint, a) < distanceSquared(lowerMostPoint, b) ? -1 : 1;
    }
    return ccw * -1;
  });
};

// get a convex hull with the Graham Scan algorithm
export const getConvexHull = (points) => {
  const uniquePoints = uniq(points);
  const convexHull = [];
  const lowerMostPoint = uniquePoints.reduce((prev, curr) => (prev[1] < curr[1] ? prev : curr));
  const sorted = sortPoints(uniquePoints, lowerMostPoint);

  convexHull.push(sorted[0], sorted[1]);

  for (let i = 2; i < sorted.length; i++) {
    const next = sorted[i];
    let top = convexHull.pop();

    while (
      convexHull[convexHull.length - 1] !== undefined &&
      counterClockWise(convexHull[convexHull.length - 1], top, next) <= 0
    ) {
      top = convexHull.pop();
    }

    convexHull.push(top);
    convexHull.push(next);
  }

  const p = convexHull.pop();
  if (counterClockWise(convexHull[convexHull.length - 1], p, lowerMostPoint) > 0)
    convexHull.push(p);

  return convexHull;
};

export const getConcaveHullFromPoints = (points, concavity) => {
  if (size(points) <= 2) return getPolygonFromBbox(calculateBBox(points, 10));

  return concaveman(points, concavity);
};

export const getPolygonFromPoints = (points) => {
  if (size(points) <= 2) return getPolygonFromBbox(calculateBBox(points, 10));

  return getConvexHull(points);
};

export const generateAvoidAreasFromFileProblem = (file, orders) => {
  if (!file || !file.fleet || !file.fleet.profiles || isEmpty(file.fleet.profiles)) return null;
  const avoidArray = [];

  const avoidAreas = file.fleet.profiles.map((f) => f.avoid);
  avoidAreas.forEach((a) => {
    const areas = a?.areas;
    if (areas) {
      areas.forEach((area, index) => {
        let polygon;
        let bbox;
        if (area.type === AVOID_TYPES.BOUNDING_BOX) {
          bbox = [area.east, area.south, area.west, area.north];
          polygon = getPolygonFromBbox(bbox);
        } else if (area.type === AVOID_TYPES.POLYGON) {
          polygon = area.outer.map(({ lng, lat }) => [lng, lat]);
          bbox = calculateBBox(polygon);
        } else if (area.type === AVOID_TYPES.ENCODED_POLYGON) {
          const polyline = decode(area.outer).polyline;
          polygon = polyline.map((coord) => coord.reverse());
          bbox = polygon.reduce(
            ([minX, minY, maxX, maxY], [x, y]) => [
              Math.min(minX, x),
              Math.min(minY, y),
              Math.max(maxX, x),
              Math.max(maxY, y),
            ],
            [180, 90, -180, -90],
          );
        }
        const name = `avoid_${area.type}_${index}`;
        if (polygon && bbox)
          avoidArray.push({ polygon, bbox, index, name, type: AREA_TYPES.AVOID_AREA, orders });
      });
    }
    return [];
  });
  return avoidArray;
};

export const separateOrdersByGroupAndTerritory = (orderList) => {
  const ordersByGroup = orderList.reduce((acc, order) => {
    if (order.Group) {
      if (!acc[order.Group]) {
        acc[order.Group] = [];
      }
      acc[order.Group].push(order);
    }
    return acc;
  }, {});

  const ordersByTerritory = orderList.reduce((acc, order) => {
    if (order.Territories) {
      order.Territories.forEach((territoryId) => {
        if (!acc[territoryId]) {
          acc[territoryId] = [];
        }
        acc[territoryId].push(order);
      });
    }
    return acc;
  }, {});

  return { ordersByGroup, ordersByTerritory };
};

export const generatePolygonsFromOrders = (orderList) => {
  if (isEmpty(orderList)) return {};

  const { ordersByTerritory, ordersByGroup } = separateOrdersByGroupAndTerritory(orderList);

  const territories = keys(ordersByTerritory).map((key, index) => {
    const coords = ordersByTerritory[key].map((order) => [order.Longitude, order.Latitude]);
    const polygon = getPolygonFromPoints(coords);
    const bbox = calculateBBox(coords, 10);

    return {
      orders: ordersByTerritory[key],
      name: key,
      type: AREA_TYPES.TERRITORY,
      index,
      polygon,
      bbox,
    };
  });

  const groupAreas = keys(ordersByGroup).map((key, index) => {
    return { orders: ordersByGroup[key], name: key, type: AREA_TYPES.GROUP, index };
  });

  return { territories, groupAreas };
};

export const generateAllTourConvexHull = (tourData) => {
  if (!tourData || !tourData.tours || isEmpty(tourData.tours)) return [];
  const tourCoordinates = tourData.tours.map((tour) => getAllLocationsInTour(tour));
  const tourHull = [];

  tourCoordinates.forEach((coordinates, index) =>
    tourHull.push({
      name: `${AREA_TYPES.TOUR_HULL_CONVEX}_${index}`,
      type: AREA_TYPES.TOUR_HULL_CONVEX,
      index,
      polygon: getPolygonFromPoints(coordinates),
      bbox: calculateBBox(coordinates, 10),
    }),
  );

  return tourHull;
};

export const generateAllTourConcaveHull = (tourData, concavity) => {
  if (!tourData || !tourData.tours || isEmpty(tourData.tours)) return [];
  const tourCoordinates = tourData.tours.map((tour) => getAllLocationsInTour(tour));
  const tourHull = [];

  tourCoordinates.forEach((coordinates, index) =>
    tourHull.push({
      name: `${AREA_TYPES.TOUR_HULL_CONCAVE}_${index}`,
      type: AREA_TYPES.TOUR_HULL_CONCAVE,
      index,
      polygon: getConcaveHullFromPoints(coordinates, concavity),
      bbox: calculateBBox(coordinates, 10),
    }),
  );

  return tourHull;
};
