import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setUserParam } from '../../../actions';
import { StyledSelectInput } from '../../Wizard/Global/WizardStyled';

const LanguageSelector = ({ user }) => {
  const dispatch = useDispatch();
  const handleSetUserParam = useCallback(
    (language) => dispatch(setUserParam({ language })),
    [dispatch],
  );

  const onChange = useCallback(
    (e) => {
      handleSetUserParam(e.target.value);
    },
    [handleSetUserParam],
  );

  return (
    <StyledSelectInput contrast sizeL onChange={onChange} value={user.language}>
      <option value="en">English</option>
      <option value="ja">日本</option>
    </StyledSelectInput>
  );
};

export default LanguageSelector;
