import React, { useCallback } from 'react';
import { GeoJSON } from 'react-leaflet';
import { mapDataKey } from 'utils/map/MapCalculations';
import { colors, tourColors } from '../../global/variables';
import { AREA_TYPES } from '../../utils/territories/TerritoriesFromJson';

const { transparentDarkerColor, areas, avoidArea } = colors;

const MapAreas = ({ areaPolygon, onClick, groupsToShow }) => {
  const showName = useCallback(
    (e) => {
      if (onClick) onClick(e.target.feature.properties);
    },
    [onClick],
  );

  const onHandleSelectArea = (feature, layer) => {
    layer.on({ click: showName });
  };

  const getPolygonStyle = (polygon) => {
    const { index, type } = polygon.properties;
    const isAvoidPolygon = type === AREA_TYPES.AVOID_AREA;
    const isGroupPolygon = type === AREA_TYPES.GROUP;
    const isTourHull =
      type === AREA_TYPES.TOUR_HULL_CONCAVE || type === AREA_TYPES.TOUR_HULL_CONVEX;

    const tourColor = tourColors[index % tourColors.length].delivery.inner;
    const fillColor = isAvoidPolygon ? avoidArea : isTourHull ? tourColor : areas[index];
    const dashArray = isGroupPolygon || isAvoidPolygon ? '4' : isTourHull ? '20' : undefined;
    const color = isAvoidPolygon ? avoidArea : isTourHull ? tourColor : transparentDarkerColor;

    return {
      opacity: 1,
      weight: 2,
      color,
      fillColor,
      dashArray,
    };
  };

  return areaPolygon.features
    .filter(
      ({ properties }) =>
        properties.type !== AREA_TYPES.GROUP || groupsToShow.includes(properties.name),
    )
    .map((feature, i) => {
      return (
        <GeoJSON
          key={`${mapDataKey(areaPolygon, i, groupsToShow, undefined)}-area`}
          data={feature}
          style={getPolygonStyle}
          onEachFeature={onHandleSelectArea}
        />
      );
    });
};
export default MapAreas;
