import { isEmpty, size } from 'lodash';
import { getSafeValue, setSafeValue } from '../security';

const orderCellsPerRow = (cells) => {
  const rows = {};
  cells.forEach((cell) => {
    const i = cell.RowIndex;
    let rowValue = getSafeValue(rows, i);
    if (!rowValue) {
      setSafeValue(rows, i, []);
      rowValue = getSafeValue(rows, i);
    }
    rowValue.push(cell);
  });
  return rows;
};

const isValidRow = (row) => {
  if (!row || isEmpty(row)) return false;
  const totalCells = size(row);
  const notEmpty = row.filter((c) => !isEmpty(c.Text));
  return size(notEmpty) >= totalCells / 2;
};

const getValidRows = (rows) => Object.keys(rows).filter((k) => isValidRow(getSafeValue(rows, k)));

export const getCSVFromTable = (cells) => {
  if (isEmpty(cells)) return '';

  const rows = orderCellsPerRow(cells);
  const validRows = getValidRows(rows);
  if (size(validRows) < 4) return '';

  const csvRows = [];
  const count = size(Object.keys(rows));

  for (let r = 1; r <= count; r++) {
    const row = getSafeValue(rows, r);
    const ordered = row.sort((a, b) => a.ColumnIndex - b.ColumnIndex);
    const texts = ordered.map((c) => `"${c.Text.replace(/"/g, "'")}"`);
    csvRows.push(texts.join(','));
  }
  return csvRows.join('\n\r');
};
