import React from 'react';
import withTranslation from '../../../hoc/withTranslation';
import { StyledDetailContainer, StyledDetailsText, StyledTitle } from './IdSearchDetails';

const IdSearchDetail = ({ title, text }) => {
  return (
    <StyledDetailContainer>
      <StyledTitle>{title}</StyledTitle>
      <StyledDetailsText>{text}</StyledDetailsText>
    </StyledDetailContainer>
  );
};

export default withTranslation(IdSearchDetail);
