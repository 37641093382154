import React, { useCallback, useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { setUserParam } from 'actions';
import { useDispatch } from 'react-redux';
import { colors } from 'global/variables';
import {
  StyledInfoWrapper,
  StyledText,
  StyledTextInput,
} from 'components/Wizard/Global/WizardStyled';
import { copyToClipboard } from 'utils/helpers';
import { ToastContainer, toast } from 'react-toastify';
import QRCodeBase64 from 'components/Wizard/TourDispatch/QRCodeBase64';
import Modal from '../../Presentation/Modal';
import withTranslation from '../../../hoc/withTranslation';
import {
  StyledContrastPanel,
  StyledContrastPanelHeader,
} from '../../Wizard/Global/WizardStyledContrastPanel';
import Section from '../../Form/Section';
import Button from '../../Form/Button';
import { StyledCloseButton } from '../Menu/Menu';

const { white } = colors;

const StyledContainer = styled.div({
  padding: '1rem',
});

const StyledPanel = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1rem 1fr',
});

const StyledORWrapper = styled(StyledInfoWrapper)({
  color: white,
  fontWeight: 400,
  lineHeight: '1.7',
});

const QRCodePanel = ({
  user,
  translations: {
    qrCodePanel: { qrCodeTitle, copyLink, openNewTab, qrCodeCreation },
  },
}) => {
  const dispatch = useDispatch();

  const navigationLink = user.userQRCode.navLink;
  const [navLink, setNavLink] = useState(navigationLink);
  const [userLink, setUserLink] = useState();

  const handleSetUserParam = useCallback((data) => dispatch(setUserParam(data)), [dispatch]);

  const togglePanel = useCallback(() => {
    handleSetUserParam({ userQRCode: { isPanelOpen: false } });
  }, [handleSetUserParam]);

  const onCopy = () => {
    copyToClipboard(navLink);
    toast('Copied route to clipboard!', {
      autoClose: 2000,
      hideProgressBar: true,
      closeButton: false,
    });
  };

  const onChange = (e) => {
    setUserLink(e.target.value);
  };

  const escFunction = useCallback(
    (e) => {
      if (e.keyCode === 27) {
        togglePanel();
      }
    },
    [togglePanel],
  );

  useEffect(() => {
    setNavLink(navigationLink);
  }, [navigationLink]);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);
    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  return (
    <>
      <Modal isDark isVisible={user.userQRCode.isPanelOpen} isFull fromTop>
        <StyledContrastPanel fullDisplay>
          <StyledCloseButton onClick={togglePanel} data-test-id="closeQRCodePanel" />
          <StyledContrastPanelHeader size="0">
            <StyledText bold contrast>
              {qrCodeTitle}
            </StyledText>
          </StyledContrastPanelHeader>
          <StyledContainer>
            {!navigationLink && user.userQRCode.isPanelOpen ? (
              <StyledORWrapper>{qrCodeCreation}</StyledORWrapper>
            ) : (
              <>
                <Section>
                  <StyledTextInput
                    placeholder=""
                    id="input-qr-code"
                    defaultValue={userLink ?? navLink}
                    onChange={onChange}
                    autoComplete="off"
                    theme="contrast"
                    inputMode="url"
                    type="url"
                    readOnly
                  />
                </Section>
                <Section>
                  <StyledPanel>
                    <Button
                      text={openNewTab}
                      onClick={() => window.open(userLink ?? navLink, '_blank')}
                    />
                    <div />
                    <Button text={copyLink} inverted contrast onClick={onCopy} />
                    <ToastContainer autoClose={2000} hideProgressBar closeButton={false} />
                  </StyledPanel>
                </Section>
                <Section>
                  {navigationLink && user.userQRCode.isPanelOpen && (
                    <QRCodeBase64 url={userLink ?? navLink} id="qr-panel" qrStyle="qr-panel" />
                  )}
                </Section>
              </>
            )}
          </StyledContainer>
        </StyledContrastPanel>
      </Modal>
    </>
  );
};

export default withTranslation(QRCodePanel);
