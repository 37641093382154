import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from '@emotion/styled';
import { setOrders, clearOrders, updateOrder, setSolutionJson } from 'actions';
import { colors, fontSize } from 'global/variables';
import withTranslation from 'hoc/withTranslation';
import { isEmpty, last, map, orderBy } from 'lodash';
import { css } from '@emotion/react';
import { useCookies } from 'react-cookie';
import {
  StyledInfoEllipsis,
  StyledInfoWrapper,
  StyledIcon,
  StyledText,
} from '../Global/WizardStyled';
import { getAsset, isProdEnv } from '../../../utils/helpers';
import deleteIcon from '../../../global/img/delete.svg';
import deleteIconDisabled from '../../../global/img/deleteDisabled.svg';
import priorityHighIcon from '../../../global/img/priority-high-black.svg';
import priorityNormalIcon from '../../../global/img/priority-normal-black.svg';
import orderActivityDelivery from '../../../global/img/order-activity-delivery.svg';
import orderActivityPickup from '../../../global/img/order-activity-pickup.svg';
import { AmplitudeService, AMPLITUDE_EVENTS } from '../../../utils/amplitude';
import { getCSVConfigLastDemandLabel } from '../../../utils/csv/customConfig';
import { ORDER_ACTIVITIES, ORDER_PRIORITY } from '../../../utils/csv/config';
import { getSafeValue } from '../../../utils/security';

const {
  primaryBorderColor,
  secondaryBorderColor,
  transparentGreyColor,
  transparentYellowColor,
  black,
  primaryTextColor,
  secondaryTextColor,
} = colors;
const { medium, small } = fontSize;

const StyledGridRow = styled.div`
  display: grid;
  grid-template-columns: 1rem 2rem 0.6fr 1.2fr 0.7fr 2rem;

  div:nth-last-of-type(-n + 6) {
    border-bottom: none;
  }
  div {
    text-align: left;
    vertical-align: text-bottom;
  }
  div:nth-last-of-type(6n + 2) {
    text-align: center;
  }
`;

const StyledHeader = styled.div`
  display: grid;
  grid-template-columns: 1rem 2rem 0.7fr 1.2fr 0.5fr 2rem;

  margin-top: 0;
  border-bottom: 1px solid ${primaryBorderColor};

  & > div {
    text-transform: uppercase;
    font-size: ${small};
    position: relative;
    top: -2px;
    opacity: 0.75;
  }
`;

const StyledOrder = styled.div(({ isHighPriority }) => ({
  lineHeight: '3rem',
  padding: '0.5rem 0.4rem 0.5rem 0',
  borderBottom: `1px solid ${secondaryBorderColor}`,
  color: secondaryTextColor,
  fontSize: medium,
  fontWeight: '700',
  backgroundColor: isHighPriority ? transparentYellowColor : transparentGreyColor,
}));

const StyledOrderName = styled.div({
  color: black,
  lineHeight: '1rem',
  padding: '0.5rem 0',
  div: {
    textAlign: 'left !important',
    width: '6rem',
    display: 'block',
    color: secondaryTextColor,
  },
  'div:last-of-type': {
    color: primaryTextColor,
    fontWeight: 400,
  },
});

const StyledOrderAddress = styled(StyledInfoWrapper)({
  width: '7rem',
  color: secondaryTextColor,
  fontWeight: 400,
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
});

const StyledRemoveIconStyle = (disabled) => css`
  background-image: url(${getAsset(disabled ? deleteIconDisabled : deleteIcon)});
  cursor: pointer;
  background-size: 1.2rem;
  width: 1.4rem;
  height: 1.4rem;
  position: relative;
  top: 3px;
  opacity: 0.8;
  transition: all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005);
  pointer-events: ${disabled && 'none'};

  &:hover {
    opacity: ${!disabled && 1};
  }
`;

const StyledPriorityHighIcon = css`
  background-image: url(${getAsset(priorityHighIcon)});
  background-position: top;
  opacity: 0.3;
  transition: all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005);
  cursor: pointer;
  margin: 0 0 0.2rem 0.6rem;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledPriorityNormalIcon = css`
  background-image: url(${getAsset(priorityNormalIcon)});
  background-position: bottom;
  opacity: 0.3;
  transition: all 250ms cubic-bezier(0.365, 0.005, 0.285, 1.005);
  cursor: pointer;
  margin: 0 0 0.2rem 0.6rem;

  &:hover {
    opacity: 0.8;
  }
`;

const StyledErrorWrap = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  alignItems: 'center',
  marginTop: '2rem',
  zIndex: 4000,
});

const StyledOrderContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;

  & > div:first-of-type {
    flex-grow: 1;
    text-align: right;
  }
`;

const StyledTextLight = styled.strong`
  font-weight: 300;
  margin: 0 1rem 0.25rem 1rem;
  color: ${(props) => (props.contrast ? white : secondaryTextColor)};
  font-size: ${medium};
  height: 1.75rem;
`;

const Order = withTranslation(
  ({
    order,
    index,
    onOrderRemove,
    allowRemove,
    translations,
    hasJsonOrders,
    solIndex,
    handleSetSolutionJson,
  }) => {
    const dispatch = useDispatch();
    const user = useSelector((state) => state.user);
    const { orderPriorityLabels, orderActivityLabels } = translations.wizard.orders;
    const isPriorityOrder = order.Priority === ORDER_PRIORITY.HIGH;
    const priorityIcon = isPriorityOrder ? StyledPriorityHighIcon : StyledPriorityNormalIcon;
    const priorityLabel = isPriorityOrder ? orderPriorityLabels.high : orderPriorityLabels.normal;
    const priorityText = `${orderPriorityLabels.title}: ${priorityLabel}`;
    const activityIcon =
      order.Activity === ORDER_ACTIVITIES.PICKUP ? orderActivityPickup : orderActivityDelivery;
    const activityLabel =
      getSafeValue(orderActivityLabels, order.Activity) || orderActivityLabels.delivery;
    const handleUpdateOrder = useCallback((o, idx) => dispatch(updateOrder(o, idx)), [dispatch]);
    const internalID = order.InternalID;
    const lastImport = user.lastImport;
    const csvOrders = lastImport?.filename?.endsWith('.csv') && !isEmpty(lastImport.delimiter);
    const deletableOrder =
      internalID?.startsWith('manual-') || internalID?.startsWith('demo-') || csvOrders;

    const handleChangeOrderPriority = useCallback(() => {
      const newPriority = order.Priority === ORDER_PRIORITY.HIGH ? undefined : ORDER_PRIORITY.HIGH;
      const newOrder = { ...order, Priority: newPriority };
      handleUpdateOrder(newOrder, solIndex);
      handleSetSolutionJson({ jsonTriggerVRP: true });
    }, [order, solIndex, handleSetSolutionJson, handleUpdateOrder]);

    return (
      <>
        <StyledOrder isHighPriority={isPriorityOrder} />
        <StyledOrder isHighPriority={isPriorityOrder}>
          {(allowRemove || deletableOrder) && (
            <StyledIcon
              id={`button-remove-order-${index}`}
              icon={StyledRemoveIconStyle(hasJsonOrders && !deletableOrder)}
              onClick={() => onOrderRemove(order)}
            />
          )}
        </StyledOrder>
        <StyledOrder id={`label-order-name-${index}`} isHighPriority={isPriorityOrder}>
          <StyledOrderName>
            <StyledInfoEllipsis title={order.Name}>{order.Name}</StyledInfoEllipsis>
            <StyledInfoEllipsis data-test-id="OrderID" title={order.ID}>
              {order.ID}
            </StyledInfoEllipsis>
          </StyledOrderName>
        </StyledOrder>
        <StyledOrder id={`label-order-address-${index}`} isHighPriority={isPriorityOrder}>
          <StyledOrderAddress data-test-id="OrderAddress" title={order.Address}>
            {order.Address}
          </StyledOrderAddress>
        </StyledOrder>
        <StyledOrder id={`label-order-demand-${index}`} isHighPriority={isPriorityOrder}>
          <StyledOrderContent>
            <div title={order.Demand && order.Demand.join(',')}>
              {order.Demand && order.Demand[0]}
              {order.Demand && order.Demand.length > 1 && '...'}
            </div>
            <StyledIcon iconUrl={activityIcon} title={activityLabel} />
            <StyledIcon
              id={`button-order-change-priority-${index}`}
              icon={priorityIcon}
              title={priorityText}
              mini
              onClick={hasJsonOrders && !deletableOrder ? undefined : handleChangeOrderPriority}
            />
          </StyledOrderContent>
        </StyledOrder>
        <StyledOrder isHighPriority={isPriorityOrder} />
      </>
    );
  },
);

const Orders = ({
  orders,
  allowRemove,
  tourPlanner,
  hasJsonOrders,
  translations: {
    wizard: {
      orders: { ordersHeaders },
    },
    error: { orderPanelError },
    tourPlanner: { jsonApiKey },
  },
}) => {
  const [cookies] = useCookies(['apikey']);

  const dispatch = useDispatch();
  const handleSetOrders = useCallback((o, o1, o2) => dispatch(setOrders(o, o1, o2)), [dispatch]);

  const handleSetSolutionJson = useCallback(
    (param) => dispatch(setSolutionJson(param)),
    [dispatch],
  );
  const handleClearOrders = useCallback((data) => dispatch(clearOrders(data)), [dispatch]);
  const demandLabel = getCSVConfigLastDemandLabel(tourPlanner, ordersHeaders[2]);
  const solution = useSelector(({ solution: stateSolution }) => stateSolution);
  const hasNoApiKey = isProdEnv() && (!cookies.apikey || cookies.apikey === '');
  const sortedOrders = orderBy(orders, ['TimeCreated'], ['desc']);

  const tableHeader = useMemo(
    () => (
      <StyledHeader>
        {map(['', '', ...ordersHeaders, ''], (header, index) => (
          <StyledInfoEllipsis key={index} title={index === 4 ? demandLabel : header}>
            {index === 4 ? demandLabel : header}
          </StyledInfoEllipsis>
        ))}
      </StyledHeader>
    ),
    [ordersHeaders, demandLabel],
  );

  const onOrderRemove = useCallback(
    (order) => {
      handleClearOrders({ index: solution.show });
      const newOrders = orders.filter((o) => o !== order);
      handleSetOrders(newOrders, false, solution.show);
      handleSetSolutionJson({ jsonTriggerVRP: true });
      AmplitudeService.log(AMPLITUDE_EVENTS.ORDERS_DELETE_SINGLE);
    },
    [orders, handleClearOrders, handleSetOrders, solution.show, handleSetSolutionJson],
  );

  return (
    <>
      {tableHeader}
      {isEmpty(orders) ? (
        <StyledErrorWrap>
          <StyledText>{hasNoApiKey ? jsonApiKey : orderPanelError}</StyledText>
          <StyledTextLight>{last(solution.requests).error}</StyledTextLight>
        </StyledErrorWrap>
      ) : (
        <div>
          {sortedOrders.map((order, index) => (
            <StyledGridRow key={index}>
              <Order
                index={index}
                key={`order-${index}`}
                order={order}
                onOrderRemove={onOrderRemove}
                allowRemove={allowRemove}
                hasJsonOrders={hasJsonOrders}
                solIndex={solution.show}
                handleSetSolutionJson={handleSetSolutionJson}
              />
            </StyledGridRow>
          ))}
        </div>
      )}
    </>
  );
};

export default withTranslation(Orders);
