import fleetTypeIconBicycle from '../global/img/vehicle-type-bycicle.svg';
import fleetTypeIconCar from '../global/img/vehicle-type-car.svg';
import fleetTypeIconPedestrian from '../global/img/vehicle-type-pedestrian.svg';
import fleetTypeIconScooter from '../global/img/vehicle-type-scooter.svg';
import fleetTypeIconTruck from '../global/img/vehicle-type-truck.svg';
import fleetTypeIconBus from '../global/img/vehicle-type-bus.svg';
import fleetTypeIconPrivateBus from '../global/img/vehicle-type-privatebus.svg';

import { getSafeValue } from './security';

const fleetTypeIcons = {
  bicycle: fleetTypeIconBicycle,
  car: fleetTypeIconCar,
  pedestrian: fleetTypeIconPedestrian,
  scooter: fleetTypeIconScooter,
  truck: fleetTypeIconTruck,
  bus: fleetTypeIconBus,
  privateBus: fleetTypeIconPrivateBus,
};

export const getFleetTypeIcon = (type) => getSafeValue(fleetTypeIcons, type);

export const getVehicleProfile = (vehicleId, vehicles, vehicleProfiles) => {
  // TODO: Get vehicle profile from Solution file when the property has been added

  if (!vehicles || !vehicleProfiles) return { type: 'car' };
  const vehicle = vehicles.find((v) => v.id === vehicleId);
  if (!vehicle) return undefined;
  return vehicleProfiles.find((profile) => profile.name === vehicle.profile);
};
