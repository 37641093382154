import React, { useCallback, useState } from 'react';
import { GeoJSON, useMapEvents } from 'react-leaflet';
import { size } from 'lodash';
import { useSelector } from 'react-redux';
import { mapDataKey } from '../../utils/map/MapCalculations';
import {
  featureFilter,
  featureFilterUnselectedTourLine,
  filterOriginalLocation,
  isPedestrianRoute,
} from '../../utils/map/MapFeatureFilters';
import { onEachFeature } from '../../utils/map/MapTourLineUtils';
import {
  getStyle,
  getStyleOriginalLocationLine,
  getStyleUnselected,
} from '../../utils/map/MapUtils';
import { AMPLITUDE_EVENTS, AmplitudeService } from '../../utils/amplitude';
import MapAnimatedTour from './MapAnimatedTour';
import { getSafeValue } from '../../utils/security';

const MapTourLines = ({
  mapData,
  user,
  display,
  routingRoutes,
  routingData,
  activateTour,
  tourData,
  originalLocation,
}) => {
  const solution = useSelector(({ solution: stateSolution }) => stateSolution);
  const tourPlanner = useSelector(
    ({ tourPlanner: stateTourPlanner }) => stateTourPlanner.value[solution.show],
  );
  const {
    vehicleProfiles: [{ fleetType }],
  } = tourPlanner || {};

  const [zoomLevel, setZoomLevel] = useState(5);
  const polylineCount = display.routeIds.reduce((total, routeId) => {
    return total + (routingData?.[routeId]?.polylineCount || 0);
  }, 0);
  const showAnimation = user.mapSettings.showTourDirectionAnimation;

  const mapEvents = useMapEvents({
    zoom: () => {
      setZoomLevel(mapEvents.getZoom());
    },
  });

  const onTourLineClick = useCallback(
    (feature) => {
      const routeId = feature.properties.routeId;
      if (routeId === undefined) return;
      const tour = getSafeValue(tourData.tours, routeId);
      activateTour(routeId, tour);
      AmplitudeService.log(AMPLITUDE_EVENTS.MAP_MARKER_ACTIVATE_TOUR);
    },
    [display, tourData, mapData, user, routingRoutes],
  );

  const filterLineFeatures = useCallback(
    (feature) => {
      return featureFilter(feature, display, routingData) && !isPedestrianRoute(feature);
    },
    [display, routingData],
  );

  const filterLineFeaturesUnselected = useCallback(
    (feature) => {
      return featureFilterUnselectedTourLine(feature, display);
    },
    [display],
  );

  const filterLineOriginalLocation = useCallback(
    (feature) => {
      return filterOriginalLocation(feature, display);
    },
    [display],
  );

  const filterLinePedestrian = useCallback(
    (feature) => {
      return featureFilter(feature, display, routingData) && isPedestrianRoute(feature);
    },
    [display, routingData],
  );

  const onEachFeatureCb = useCallback(
    (feature, layer) => {
      onEachFeature(feature, layer, mapData, display, tourData, onTourLineClick);
    },
    [mapData, display, tourData, onTourLineClick],
  );

  const makeRouting = useCallback(
    (feature) => {
      return getStyle(feature, user.mapSettings.gradientRoutes, fleetType);
    },
    [user.mapSettings.gradientRoutes, fleetType],
  );

  const totalTours = tourData && tourData.tours && tourData.tours.length;
  const displayUnselected = tourData && routingRoutes && totalTours <= 100;

  return (
    <>
      {displayUnselected && (
        <GeoJSON
          key={`${mapDataKey(mapData, user, display, routingRoutes)}-routing-unselected`}
          data={routingRoutes}
          style={getStyleUnselected}
          onEachFeature={onEachFeatureCb}
          filter={filterLineFeaturesUnselected}
        />
      )}
      <GeoJSON
        key={`${mapDataKey(mapData, user, display, routingRoutes)}-routing`}
        data={routingRoutes}
        style={makeRouting}
        onEachFeature={onEachFeatureCb}
        filter={filterLineFeatures}
      />
      <GeoJSON
        key={`${mapDataKey(mapData, user, display, routingRoutes)}-pedestrian-routing`}
        data={routingRoutes}
        style={makeRouting}
        onEachFeature={onEachFeatureCb}
        filter={filterLinePedestrian}
      />
      {zoomLevel > 16 && (
        <GeoJSON
          key={`${mapDataKey(mapData, user, display, routingRoutes)}-original-location-line`}
          data={originalLocation}
          style={getStyleOriginalLocationLine}
          filter={filterLineOriginalLocation}
        />
      )}
      {!display.showUnassigned &&
        showAnimation &&
        size(display.routeIds) > 0 &&
        routingRoutes &&
        zoomLevel > 10 &&
        polylineCount <= 100000 && <MapAnimatedTour tour={routingRoutes} display={display} />}
    </>
  );
};

export default MapTourLines;
