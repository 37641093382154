import React, { useEffect, useState, useCallback, useRef } from 'react';
import styled from '@emotion/styled';
import { useDispatch, useSelector } from 'react-redux';
import {
  submitProblem,
  setProblem,
  setMapData,
  clearMapData,
  increaseUserUsage,
  recordUsageEvent,
  USAGE_EVENTS,
  getRoutingTourInfo,
  setSolutionJson,
  setLoader,
  selectUnassigned,
  setShowProblem,
  TIMEOUT_VALUES,
  setTourParameter,
  selectTourById,
} from 'actions';
import { last, isEmpty } from 'lodash';
import { generateVRPPayload, convertParsedCSVToVRPJobs } from 'utils/VRPConverter';
import { convertProblemToGeoJSON } from 'utils/GeoJSONConverter';
import { useCookies } from 'react-cookie';
import { getLastValidIteration } from 'utils/SolutionHelpers';
import { clearAll } from 'utils/apiFilesUploadHelpers';
import { defaultValue } from 'reducers/tourPlanner';
import WizardHeader from './Global/WizardHeader';
import WizardFooter from './Global/WizardFooter';
import Step1Container from './Step1/Step1Container';
import Step3Container from './Step3/Step3Container';
import Step4Container from './Step4/Step4Container';
import { AmplitudeService, AMPLITUDE_EVENTS } from '../../utils/amplitude';
import Notifications from './Global/Notifications';
import { getClusters } from '../../utils/csv/clusters';
import { tourToRoutingRequest } from '../../utils/RoutingConverter';
import { isSolutionMonoTour } from '../../utils/MemoryHelpers';
import { getSafeValue } from '../../utils/security';
import IdSearchContainer from './Step5/IdSearchContainer';
import { canDownloadCSV, isProdEnv } from '../../utils/helpers';
import { colors } from '../../global/variables';
import Step2Container from './Step2/Step2Container';
import { getInitialStep } from '../../utils/WizardHelpers';

const isProd = isProdEnv();

const StyledContainer = styled.div`
  height: 100%;
  padding: 0;
  overflow: hidden;
  box-sizing: border-box;
`;

const StyledWizardContent = styled.div`
  position: relative;
  height: ${(props) => (!props.noHeader ? 'calc(100% - 9rem)' : 'calc(100% - 4.5rem)')};
  box-sizing: border-box;
  overflow-y: ${(props) => (props.scrollable ? 'auto' : 'hidden')};
  overflow-x: hidden;
  z-index: 200;
`;

const { secondaryColor, primaryColorHovered } = colors;

const bgImage = `linear-gradient(
    to right,
    ${secondaryColor} 0%,
    ${primaryColorHovered} 100%
  )`;

export const StyledTourDispatcherContainer = styled.div(({ searchOnly, step4 }) => ({
  position: 'absolute',
  top: step4 ? '0.5rem' : '1.2rem',
  right: step4 ? '0.5rem' : '1.2rem',
  backgroundImage: bgImage,
  borderRadius: '0.2rem',
  width: searchOnly ? '2.5rem' : canDownloadCSV() ? '15rem' : '12.5rem',
}));

export const StyledActions = styled.div(({ searchOnly }) => ({
  height: searchOnly ? '2rem' : '2.7rem',
  margin: 'auto',
  div: {
    display: 'inline-block',
  },
}));

const STEPS_IN_HEADER = 3;

const Wizard = ({ user, oAuth, tourData, tourPlanner, display, mapData }) => {
  const dispatch = useDispatch();
  const solution = useSelector(({ solution: stateSolution }) => stateSolution);
  const territories = useSelector(({ areas: stateAreas }) => stateAreas[solution.show].territories);
  const orders = useSelector((state) => state.orders[solution.show]);
  const fullTourPlanner = useSelector(({ tourPlanner: stateTourPlanner }) => stateTourPlanner);

  const ordersNotLocated = useSelector((state) => state.ordersNotLocated);
  const errorState = useSelector((state) => state.error);
  const uploadedFile = useSelector((state) => state.uploadedFile);
  const handleSubmitProblem = useCallback(
    (problem) => dispatch(submitProblem(problem)),
    [dispatch],
  );
  const handleSetLoader = useCallback((data) => dispatch(setLoader(data)), [dispatch]);
  const handleIncreaseUsage = useCallback((data) => dispatch(increaseUserUsage(data)), [dispatch]);
  const handleSetProblem = useCallback((problem) => dispatch(setProblem(problem)), [dispatch]);
  const handleSetTourParameter = useCallback(
    (parameter) =>
      dispatch(setTourParameter({ ...parameter, index: solution.show, userChange: false })),
    [dispatch, solution.show],
  );
  const handleSetSolutionJson = useCallback(
    (param) => dispatch(setSolutionJson(param)),
    [dispatch],
  );
  const handleSetMapData = useCallback(
    (mapDataLocal) => dispatch(setMapData(mapDataLocal)),
    [dispatch],
  );
  const handleClearMapData = useCallback(() => dispatch(clearMapData()), [dispatch]);
  const handleRecordUsage = useCallback((data) => dispatch(recordUsageEvent(data)), [dispatch]);
  const handleSelectTourById = useCallback((data) => dispatch(selectTourById(data)), [dispatch]);
  const handleGetRoutingTourInfo = useCallback(
    (index, tour) =>
      dispatch(
        getRoutingTourInfo({
          oAuth,
          routingRequest: tourToRoutingRequest(tour, tourPlanner, orders),
          tourId: index,
          solutionId: solution.show,
        }),
      ),
    [dispatch, oAuth, tourPlanner, solution.show, orders],
  );
  const handleSelectUnassigned = useCallback(
    (value) => dispatch(selectUnassigned(value)),
    [dispatch],
  );
  const handleSetShowProblem = useCallback((value) => dispatch(setShowProblem(value)), [dispatch]);
  const wizardContentRef = useRef(null);
  const { routeIds, sharedRouteIds } = display;

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [step, setStep] = useState(getInitialStep(solution));
  const [isPanelVisible, setIsPanelVisible] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(false);
  const [backToStep, setBackToStep] = useState(3);
  const [searchSelectedTour, setSearchSelectedTour] = useState(false);
  const [shouldUpdateValues, setShouldUpdateValues] = useState(false);
  const [step5, setStep5] = useState();
  const [cookies] = useCookies(['apikey']);
  const notices = solution.requests[solution.show]?.notices;
  const unassigned = solution.requests[solution.show]?.unassigned;
  const unassignedSol = tourData?.tours.length === 0 && notices && unassigned;

  const handleSetStep = useCallback(
    (toStep, setLoading = true) => {
      setStep(toStep);
      if (setLoading) handleSetLoader({ isLoading: false });
      handleRecordUsage({ event: USAGE_EVENTS.WIZARD_SET_STEP, step: toStep });
      wizardContentRef.current.scrollTo(0, 0);
    },
    [setStep, handleRecordUsage, handleSetLoader],
  );

  const triggerVRPRequest = useCallback(
    (allOrders, config, triggerVrp) => {
      const lastIteration = last(solution.requests[solution.show].iterations);
      if (
        !triggerVrp &&
        ((config.fileType === 'json' &&
          allOrders.length > 0 &&
          solution &&
          !triggerVrp &&
          !isEmpty(solution.requests[solution.show].iterations)) ||
          getLastValidIteration(last(solution.requests)))
      ) {
        return lastIteration.request;
      }

      const fileName = solution.requests[solution.show].name;
      const wizardDepot = !isEmpty(config.location.value) ? config.location.value : null;
      const wizardReturnLocation = !isEmpty(config.returnLocation)
        ? config.returnLocation.value
        : null;
      const returnLocationAddress = !isEmpty(config.returnLocation)
        ? config.returnLocation.label
        : null;
      const rows = convertParsedCSVToVRPJobs(allOrders, false, territories.areaDetails);
      const vrpPayload = generateVRPPayload(
        rows,
        wizardDepot,
        '',
        config,
        allOrders,
        territories,
        user,
      );
      const depotAddress =
        (config.location.value && config.location.value.lat && config.location.label) ||
        (allOrders && allOrders[0] && allOrders[0].Address);
      const toSet = {
        territories: territories.areaDetails,
        tourPlanner: config,
        orders: allOrders,
        json: vrpPayload,
        uploaded: true,
      };

      if (triggerVrp) {
        toSet.name = fileName;
        toSet.index = solution.show;
      }

      const clusters = getClusters(allOrders, config);
      if (clusters) {
        const rowsCluster = convertParsedCSVToVRPJobs(clusters, true, territories.areaDetails);
        toSet.clusters = clusters;
        toSet.index = solution.show;
        toSet.json = generateVRPPayload(
          rowsCluster,
          wizardDepot,
          '',
          config,
          allOrders,
          territories,
          user,
        );
      }

      handleSetProblem(toSet);
      const geo = convertProblemToGeoJSON(
        vrpPayload,
        wizardDepot,
        wizardReturnLocation,
        allOrders,
        depotAddress,
        returnLocationAddress,
        config.editedOrder,
        territories.areaDetails,
      );
      if (geo) {
        handleSetMapData({ geo });
      } else {
        handleClearMapData();
      }
      handleSetSolutionJson({ jsonTriggerVRP: false });
      return toSet;
    },
    [
      handleSetMapData,
      handleClearMapData,
      handleSetProblem,
      solution,
      territories,
      handleSetSolutionJson,
      user,
    ],
  );

  useEffect(() => {
    const allIterations =
      solution && solution.requests[0].iterations ? solution.requests[0].iterations : null;
    const lastIteration = allIterations ? last(allIterations) : null;

    if (lastIteration && !lastIteration.isProcessing) {
      setIsSubmitting(false);
    }
  }, [solution]);

  const wizardSubmitProblem = useCallback(
    (vrpReq, timeout = TIMEOUT_VALUES.MEDIUM) => {
      if (!vrpReq) return;
      if (!orders || !orders.length) return;

      const apiKey = cookies.apikey;
      setIsSubmitting(true);
      setTimeout(() => {
        handleSubmitProblem({
          ...vrpReq,
          user,
          oAuth,
          apiKey,
          keepOrders: tourPlanner.ordersMode !== 'json_import',
        });
      }, timeout);
    },
    [
      setIsSubmitting,
      handleSubmitProblem,
      user,
      orders,
      oAuth,
      cookies.apikey,
      tourPlanner.ordersMode,
    ],
  );

  const onStepChange = useCallback(
    (toStep, isStep5) => {
      let finalStep = toStep;
      setStep5(isStep5);

      if (toStep < step) {
        handleSetSolutionJson({
          json: false,
          jsonUserChange: false,
          jsonPaste: false,
        });
      }
      handleRecordUsage({ event: USAGE_EVENTS.WIZARD_SET_STEP, step: toStep });

      if (step === 2) {
        if (toStep === 3 || toStep === 4) {
          const hasSolution = !!tourData;
          if (!hasSolution) {
            const config = { ...tourPlanner };
            delete config.editedOrder;
            const vrpReq = triggerVRPRequest(orders, config);
            wizardSubmitProblem(vrpReq);
            handleIncreaseUsage({ toursPlanned: 1 });
            AmplitudeService.log(AMPLITUDE_EVENTS.TOUR_PLAN, {
              isDemo: user.isLastOrdersAddedDemo,
              orderNumber: orders.length,
            });
            return;
          }
        }
      } else if (step === 3) {
        if (toStep < step) {
          clearAll(dispatch, false, false, solution.show, tourPlanner.uploadedLocations);
        }
      } else if (step === 4) {
        if (toStep < step) {
          const isMonoSolution = isSolutionMonoTour(tourData);
          if (isMonoSolution) {
            clearAll(dispatch, false, false, solution.show, tourPlanner.uploadedLocations);
          }
          finalStep = isMonoSolution ? 2 : 3;
        }
      }
      if ([1, 2].includes(finalStep) && [3, 4].includes(step)) setShouldUpdateValues(true);
      if (finalStep === 3 && step === 4) handleSelectTourById({ selectedTour: undefined });
      handleSetStep(finalStep);
    },
    [
      user,
      step,
      wizardSubmitProblem,
      handleSetStep,
      orders,
      tourPlanner,
      tourData,
      triggerVRPRequest,
      handleIncreaseUsage,
      dispatch,
      handleSetSolutionJson,
      handleRecordUsage,
      solution.show,
    ],
  );

  const onClickTour = useCallback(
    ({ previous }) => {
      const current = display.selectedTour;
      if (current === undefined) return;
      const next = previous
        ? current <= 0
          ? 0
          : current - 1
        : current >= tourData.tours.length - 1
        ? 0
        : current + 1;

      handleSelectTourById({ selectedTour: next });
      handleSelectTourById({ selectOne: next });
      handleGetRoutingTourInfo(next, getSafeValue(tourData.tours, next));
    },
    [display.selectedTour, tourData, handleSelectTourById, handleGetRoutingTourInfo],
  );

  const onPanelToggle = useCallback(
    (isVisible) => {
      setIsPanelVisible(isVisible);
    },
    [setIsPanelVisible],
  );

  const onSearchId = useCallback(() => handleSetStep(5), []);

  const request = getLastValidIteration(solution.requests[solution.show])?.request;

  useEffect(() => {
    const triggerJsonVRP = solution.jsonTriggerVRP;
    if ((!errorState && !tourData && !solution.json) || triggerJsonVRP) {
      triggerVRPRequest(orders, tourPlanner, triggerJsonVRP);
    }
  }, [
    orders,
    tourPlanner,
    tourData,
    errorState,
    territories,
    solution.json,
    solution.jsonTriggerVRP,
    handleSetSolutionJson,
  ]);

  useEffect(() => {
    if (
      step5 ||
      !tourData ||
      !tourData.tours ||
      !last(solution.requests[solution.show].iterations).response
    )
      return;
    const toStep = isSolutionMonoTour(tourData) ? 4 : 3;
    handleSetStep(toStep);
  }, [tourData, solution.requests, solution.show, step5]);

  useEffect(() => {
    if (step === 3 || step === 4) {
      setBackToStep(step);
      setSearchSelectedTour(step === 4);
    }
  }, [step]);

  useEffect(() => {
    if (display.selectedTour !== undefined && step === 3) handleSetStep(4);
  }, [display.selectedTour, step, handleSetStep, oAuth]);

  const onTourChange = useCallback(() => handleSetStep(4), [handleSetStep]);
  const showHeader = step <= STEPS_IN_HEADER;
  const hasSolution = !!tourData;
  const showStep3 = (step === 3 && request && hasSolution) || (unassignedSol && tourData);

  useEffect(() => {
    if (step !== 1 && step !== 2 && !hasSolution) {
      handleSetStep(2);
    }
  }, [hasSolution, step, handleSetStep]);

  useEffect(() => {
    if (solution.json) {
      handleSelectUnassigned(false);
      handleSetShowProblem(true);
      handleSetStep(2, false);
    }
  }, [solution.json, handleSetStep, handleSelectUnassigned, handleSetShowProblem]);

  useEffect(() => {
    if ((step === 3 || step === 4) && solution.jsonPaste) {
      onStepChange(2);
    }
  }, [step, solution.jsonPaste, solution.show, onStepChange]);

  useEffect(() => {
    if (errorState) {
      setShouldUpdateValues(true);
    }
  }, [errorState]);

  const hasNoApiKey = isProd && (!cookies.apikey || cookies.apikey === '');

  useEffect(() => {
    if (tourPlanner.userChange === false && shouldUpdateValues) {
      const vehicles = fullTourPlanner.temp?.vehicles ?? defaultValue.vehicles;
      const vehicleProfiles = fullTourPlanner.temp?.vehicleProfiles ?? defaultValue.vehicleProfiles;
      const location = fullTourPlanner.temp?.location ?? defaultValue.location;
      const returnLocation = fullTourPlanner.temp?.returnLocation ?? defaultValue.returnLocation;
      const returnLocationMode =
        fullTourPlanner.temp?.returnLocationMode ?? defaultValue.returnLocationMode;
      handleSetTourParameter({
        vehicles,
        vehicleProfiles,
        location,
        returnLocation,
        returnLocationMode,
      });
      setShouldUpdateValues(false);
    }
  }, [fullTourPlanner.temp, handleSetTourParameter, tourPlanner.userChange, shouldUpdateValues]);

  return (
    <StyledContainer>
      <Notifications user={user} />
      {showHeader && (
        <WizardHeader
          step={step}
          onChange={onStepChange}
          hasSolution={hasSolution}
          isPanelVisible={isPanelVisible}
        />
      )}
      <StyledWizardContent
        noHeader={!showHeader}
        ref={wizardContentRef}
        scrollable={showHeader || step === 5}
      >
        {step === 1 && (
          <Step1Container
            oAuth={oAuth}
            tourPlanner={tourPlanner}
            user={user}
            onTerritoryPlanningPanelToggle={onPanelToggle}
            mapData={mapData}
            solution={solution}
          />
        )}
        {step === 2 && (
          <Step2Container
            orders={orders}
            ordersNotLocated={ordersNotLocated}
            errorState={errorState}
            tourPlanner={tourPlanner}
            oAuth={oAuth}
            user={user}
            solution={solution}
            uploadedFile={uploadedFile}
            onConfigToggle={onPanelToggle}
            setIsDemoMode={(value) => setIsDemoMode(value)}
          />
        )}
        {showStep3 && (
          <Step3Container
            user={user}
            oAuth={oAuth}
            tourData={tourData}
            solution={solution}
            onTourChange={onTourChange}
            selectedTours={routeIds}
            sharedTours={sharedRouteIds}
            orders={orders}
            tourPlanner={tourPlanner}
            display={display}
            onSearchId={onSearchId}
            selectTour={handleSelectTourById}
          />
        )}
        {step === 4 && request && (
          <Step4Container
            tourData={tourData}
            solution={solution}
            selectedTours={routeIds}
            tourPlanner={tourPlanner}
            user={user}
            orders={orders}
            onSearchId={onSearchId}
            oAuth={oAuth}
          />
        )}
        {step === 5 && (
          <IdSearchContainer
            tourData={tourData}
            selectedTours={routeIds}
            searchSelectedTour={searchSelectedTour}
          />
        )}
      </StyledWizardContent>
      <WizardFooter
        step={step}
        onClick={onStepChange}
        onClickTour={onClickTour}
        isSubmitting={isSubmitting}
        orders={orders}
        tourData={tourData}
        oAuth={oAuth}
        tourPlanner={tourPlanner}
        disabled={isPanelVisible}
        hasNoApiKey={hasNoApiKey}
        isDemoMode={isDemoMode}
        backToStep={backToStep}
        triggerVRPRequest={triggerVRPRequest}
        currentSelectedTour={display.selectedTour}
        isJsonViewerMode={solution.json && step === 2}
        solution={solution}
        user={user}
      />
    </StyledContainer>
  );
};

export default Wizard;
