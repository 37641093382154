import React from 'react';
import { getSafeValue } from '../../../utils/security';
import { StyledInfoEllipsis } from '../../Wizard/Global/WizardStyled';
import { StyledAction } from '../StyledComponents';
import Checkbox from '../../Form/Checkbox';
import { StyledContrastPanelColumn } from '../../Wizard/Global/WizardStyledContrastPanel';
import NumericInput from '../../Wizard/Global/NumericInput';
import informationWhiteIcon from '../../../global/img/info-white.svg';

const MultipleActionSetting = ({
  mapSettingsPanel,
  mapSettings,
  setting,
  handleOnSwitchChange,
  handleOnChangeInput,
}) => {
  const mapSetting = getSafeValue(mapSettings, setting.value);
  const enabled = !!mapSetting?.enabled;
  const translation = getSafeValue(mapSettingsPanel, setting.value);

  return (
    <StyledContrastPanelColumn
      id={setting.value}
      key={setting.value}
      actionsCount={2.5}
      hasAction
      inputSize="M"
      isEnabled={enabled}
      gap
    >
      <StyledInfoEllipsis alt={translation} title={translation} hasMarginTop>
        {translation}
      </StyledInfoEllipsis>
      <StyledAction>
        <NumericInput
          theme="contrast"
          stepValue="0.001"
          initialValue={mapSetting?.value ?? 2}
          minValue={0}
          maxValue={100}
          onChange={(value) => handleOnChangeInput(setting.value, value)}
          disabled={!enabled}
          title={getSafeValue(mapSettingsPanel, `${setting.value}Info`)}
          icon={informationWhiteIcon}
        />
      </StyledAction>
      <StyledAction>
        <Checkbox
          id={`mapSettingsCheckbox-${setting.value}`}
          value={enabled}
          contrast
          onChange={(value) => handleOnSwitchChange(setting.value, value)}
        />
      </StyledAction>
    </StyledContrastPanelColumn>
  );
};
export default MultipleActionSetting;
