import { GeoJSON } from 'react-leaflet';
import React, { useCallback } from 'react';
import { size } from 'lodash';
import { mapDataKey } from '../../../utils/map/MapCalculations';
import {
  featureFilter,
  filterDepot,
  isActivityToDisplay,
  isRouteToDisplay,
} from '../../../utils/map/MapFeatureFilters';

const StartEndLocations = ({
  mapData,
  user,
  display,
  routingRoutes,
  highlightStop,
  makeMapMarkers,
  isSolution,
  routingData,
}) => {
  const startEndLocations = useCallback(() => {
    const start = filterDepot(mapData, true);
    const toSend = isSolution
      ? start.filter((f) => !isActivityToDisplay(f) && isRouteToDisplay(f, display))
      : start.filter((f) => !isActivityToDisplay(f));
    if (size(toSend) <= 1) return toSend;

    if (
      toSend[0].geometry.coordinates[0] === toSend[1].geometry.coordinates[0] &&
      toSend[0].geometry.coordinates[1] === toSend[1].geometry.coordinates[1]
    )
      return [toSend[0]];

    return toSend;
  }, [isSolution, mapData, display]);

  const filterMarkersFeatures = useCallback(
    (feature) => {
      return featureFilter(feature, display, routingData);
    },
    [display, routingData],
  );

  return (
    <GeoJSON
      key={mapDataKey(mapData, user, display, routingRoutes, highlightStop)}
      data={startEndLocations()} // each tour has own depot point. Filter out with the same location
      pointToLayer={makeMapMarkers}
      filter={filterMarkersFeatures}
    />
  );
};

export default StartEndLocations;
