export default function getUserLocation() {
  return new Promise((resolve, reject) => {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log('Location found', position);
          resolve(position);
        },
        (error) => {
          console.log('Location NOT found', error);
          reject(error);
        },
        { enableHighAccuracy: true, maximumAge: 0, timeout: 10000 },
      );
      return;
    }
    // eslint-disable-next-line prefer-promise-reject-errors
    reject('Location is not shared');
  });
}
