import { SET_LOADER, SET_ERROR } from 'actions';

const initialState = { isLoading: false, message: '' };

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ERROR:
      return initialState;
    case SET_LOADER:
      return {
        isLoading: action.payload.isLoading,
        message: action.payload.message || 'defaultMessage',
      };
    default:
      return state;
  }
};
