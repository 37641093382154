const KILOMETER_TO_MILE_MULTIPLIER = 1.609;
const KILOMETER_TO_METER_MULTIPLIER = 1000;
const SECOND_HOUR_MULTIPLIER = 60 * 60;

export const metersToMiles = (value) => {
  return value / KILOMETER_TO_METER_MULTIPLIER / KILOMETER_TO_MILE_MULTIPLIER;
};

export const metersToKilometers = (value) => {
  return value / KILOMETER_TO_METER_MULTIPLIER;
};

export const kilometersToMeters = (value) => {
  return value * KILOMETER_TO_METER_MULTIPLIER;
};

export const hoursToSeconds = (value) => {
  return value * SECOND_HOUR_MULTIPLIER;
};

export const secondsToHours = (value) => {
  return value / SECOND_HOUR_MULTIPLIER;
};
